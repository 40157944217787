import React from 'react';
import PropTypes from 'prop-types';
import { CustomizedTooltip } from './style';

function SMFTooltip({
  message,
  children,
  maxWidth = '18.75rem',
  placement = 'bottom-start',
  textSize = '0.75rem',
  isBorderEnabled = false,
  forceRenderTooltip = false,
  ...other
}) {
  if (!message && !forceRenderTooltip) {
    return children;
  }
  return (
    <CustomizedTooltip
      title={message}
      {...other}
      placement={placement}
      maxWidth={maxWidth}
      textSize={textSize}
      isBorderEnabled={isBorderEnabled}
    >
      <span>{children}</span>
    </CustomizedTooltip>
  );
}
SMFTooltip.propTypes = {
  message: PropTypes.node,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  placement: PropTypes.string,
  hasSpacing: PropTypes.bool,
  textSize: PropTypes.string,
  isBorderEnabled: PropTypes.bool,
  forceRenderTooltip: PropTypes.bool,
};
export default SMFTooltip;
