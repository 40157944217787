import SMFButton from '../../../../components/GeneralButton';
import { FUNCTIONAL_AREA_ID } from '../../../../constants';
import {
  toggleAdditionalSettingsModal,
  useTriggerSettingsContext,
} from '../../../../context/triggerSettingsContext';
import PropTypes from 'prop-types';

const AdditionalSettingsButton = ({
  trigger,
  isEditing,
  isAddlSettingsActive = true,
}) => {
  const { triggerSettingsDispatch } = useTriggerSettingsContext();
  const {
    triggerId,
    notificationTriggerId,
    functionalAreaId,
    functionalAreaName,
    addlSettingsActive,
    assetCriticalityThreshold,
    assetRiskCategoryThreshold,
    tempMuteValue,
    tempMuteUoM,
    isCustom,
  } = trigger;

  const clickHandler = () => {
    const socData = {
      triggerId,
      notificationTriggerId,
      functionalAreaId,
      functionalAreaName,
      addlSettingsActive,
      assetCriticalityThreshold: assetCriticalityThreshold
        ? assetCriticalityThreshold.toString()
        : null,
      assetRiskCategoryThreshold,
    };
    const fhData = {
      triggerId,
      notificationTriggerId,
      functionalAreaId,
      functionalAreaName,
      addlSettingsActive,
      tempMuteValue: tempMuteValue || '',
      tempMuteUoM: tempMuteUoM || '',
    };
    const oeeData = {
      triggerId,
      notificationTriggerId,
      functionalAreaId,
      functionalAreaName,
      addlSettingsActive,
      tempMuteValue: tempMuteValue || '',
      tempMuteUoM: tempMuteUoM || '',
    };
    if (functionalAreaId === FUNCTIONAL_AREA_ID.FH) {
      toggleAdditionalSettingsModal(triggerSettingsDispatch, fhData, true);
    } else if (functionalAreaId === FUNCTIONAL_AREA_ID.OEE) {
      toggleAdditionalSettingsModal(triggerSettingsDispatch, oeeData, true);
    } else if (functionalAreaId === FUNCTIONAL_AREA_ID.SOC) {
      toggleAdditionalSettingsModal(triggerSettingsDispatch, socData, true);
    }
  };
  return (
    <SMFButton
      sx={{ minWidth: '3.5rem' }}
      type="secondary"
      onClick={clickHandler}
      disabled={
        !isEditing ||
        !isAddlSettingsActive ||
        functionalAreaId === FUNCTIONAL_AREA_ID.EC ||
        isCustom
      }
      text="..."
      data-testid="addl-settings-button"
    />
  );
};

AdditionalSettingsButton.propTypes = {
  trigger: PropTypes.objectOf(
    PropTypes.shape({
      triggerId: PropTypes.number,
      functionalAreaId: PropTypes.number,
      notificationTriggerId: PropTypes.string,
      functionalAreaName: PropTypes.string,
      assetCriticalityThreshold: PropTypes.number,
      assetRiskCategoryThreshold: PropTypes.number,
      tempMuteValue: PropTypes.number,
      tempMuteUoM: PropTypes.string,
      addlSettingsActive: PropTypes.bool,
    })
  ),
  isEditing: PropTypes.bool,
  isAddlSettingsActive: PropTypes.bool,
};

export default AdditionalSettingsButton;
