import MESSAGE_STRINGS from '../../../../../constants/en-us';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  SettingsContainer,
  StyledHeading,
  GridContainer,
  Instruction,
} from './style';
import ConfigSlider from '../../../../../components/ConfigSlider';
import {
  API_SOC_CONSTANTS,
  RISK_THRESHOLD_CONSTANTS,
  SOC_ADDL_SETTINGS_CONSTANTS,
  DISABLED_PARAMETER_RISK_THRESHOLDS,
} from '../../../../../constants';
import {
  updateTempAdditionalSettings,
  useTriggerSettingsContext,
} from '../../../../../context/triggerSettingsContext';
import { getDefaultValueForSOC } from '../../../helper';

const criticalityMarks = [...Array(8).keys()].map((i) => {
  return {
    key: i + 1,
    value: `${i + 1}`,
    label: `${i + 1}`,
  };
});

const riskCategoryMarks = Object.keys(MESSAGE_STRINGS.SOC.RISK_CATEGORY).map(
  (category, index) => {
    return {
      key: index + 1,
      value: RISK_THRESHOLD_CONSTANTS[category],
      label: MESSAGE_STRINGS.SOC.RISK_CATEGORY[category],
    };
  }
);

const SOCAdditionalSettings = ({
  type,
  triggerId,
  addlSettingsActive = true,
  assetCriticalityThreshold,
  assetRiskCategoryThreshold,
}) => {
  const { triggerSettingsDispatch } = useTriggerSettingsContext();
  const [showCriticality, setShowCriticality] = useState(true);
  const [triggerSpecificLabels, setTriggerSpecificLabels] = useState({});
  const [disabledRiskCategories, setDisabledRiskCategories] = useState([]);

  useEffect(() => {
    if (type) {
      setShowCriticality(type !== API_SOC_CONSTANTS.PARAMETER);
      setTriggerSpecificLabels(MESSAGE_STRINGS.SOC[type]);
      setDisabledRiskCategories(
        type === API_SOC_CONSTANTS.PARAMETER
          ? DISABLED_PARAMETER_RISK_THRESHOLDS
          : []
      );
    }
  }, [type]);

  const handleCriticalitySelection = (selected) => {
    updateTempAdditionalSettings(triggerSettingsDispatch, {
      triggerId,
      addlSettingsActive,
      assetCriticalityThreshold: selected.value,
    });
  };

  const handleRiskThresholdSelection = (selected) => {
    updateTempAdditionalSettings(triggerSettingsDispatch, {
      triggerId,
      addlSettingsActive,
      assetRiskCategoryThreshold: selected.value,
    });
  };

  const generateTooltipBasedOnSelection = ({ selectedMark }) => {
    const { key } = selectedMark;
    const disabledRiskCategories = riskCategoryMarks
      .filter((mark) => mark.key < key)
      .map((mark) => mark.label);

    const disabledCategoryCount = disabledRiskCategories?.length;
    if (!disabledCategoryCount)
      return MESSAGE_STRINGS.SOC.RISK_CATEGORY_TOOLTIP_ALL_ENABLED;

    const categoryString =
      disabledCategoryCount > 1
        ? `${disabledRiskCategories
            .slice(0, disabledCategoryCount - 1)
            .join(', ')} and ${
            disabledRiskCategories[disabledCategoryCount - 1]
          } ${MESSAGE_STRINGS.SOC.RISK_CATEGORIES_STRING}`
        : `${disabledRiskCategories[0]} ${MESSAGE_STRINGS.SOC.RISK_CATEGORY_STRING}`;

    return `${MESSAGE_STRINGS.SOC.RISK_CATEGORY_TOOLTIP_PART_1} ${categoryString} ${MESSAGE_STRINGS.SOC.RISK_CATEGORY_TOOLTIP_PART_2}`;
  };

  return (
    <>
      {showCriticality && (
        <SettingsContainer>
          <StyledHeading>{`${triggerSpecificLabels.NAME} ${MESSAGE_STRINGS.SOC.CRITICALITY_THRESHOLD}`}</StyledHeading>
          <GridContainer container spacing={4}>
            <Grid item xs={5}>
              <Instruction>
                {triggerSpecificLabels.CRITICALITY_DESCRIPTION}
              </Instruction>
            </Grid>
            <Grid item xs={7}>
              <ConfigSlider
                defaultValue={
                  assetCriticalityThreshold ||
                  getDefaultValueForSOC(
                    SOC_ADDL_SETTINGS_CONSTANTS.CRITICALITY,
                    `SOC_${type}`
                  )
                }
                scaleDescription={
                  triggerSpecificLabels.CRITICALITY_SCALE_DESCRIPTION
                }
                marks={criticalityMarks}
                markSelectionCallback={handleCriticalitySelection}
                generateTooltip={({ currentMark }) =>
                  MESSAGE_STRINGS.SOC.CRITICALITY[currentMark.key]
                }
              />
            </Grid>
          </GridContainer>
        </SettingsContainer>
      )}
      <SettingsContainer>
        <StyledHeading>{`${triggerSpecificLabels.NAME} ${MESSAGE_STRINGS.SOC.RISK_CATEGORY_THRESHOLD}`}</StyledHeading>
        <GridContainer container spacing={4}>
          <Grid item xs={5}>
            <Instruction>
              {triggerSpecificLabels.RISK_CATEGORY_DESCRIPTION}
            </Instruction>
          </Grid>
          <Grid item xs={7}>
            <ConfigSlider
              defaultValue={
                assetRiskCategoryThreshold ||
                getDefaultValueForSOC(
                  SOC_ADDL_SETTINGS_CONSTANTS.RISK_CATEGORY,
                  `SOC_${type}`
                )
              }
              scaleDescription={
                triggerSpecificLabels.RISK_CATEGORY_SCALE_DESCRIPTION
              }
              marks={riskCategoryMarks}
              markSelectionCallback={handleRiskThresholdSelection}
              generateTooltip={generateTooltipBasedOnSelection}
              disabledMarks={disabledRiskCategories}
            />
          </Grid>
        </GridContainer>
      </SettingsContainer>
    </>
  );
};

export default SOCAdditionalSettings;
