import { Box, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

const CustomTreeItem = styled(TreeItem)(({ isBold }) => {
  return {
    '& .MuiTreeItem-content > .MuiTreeItem-iconContainer': {
      paddingLeft: '0.4rem',
    },
    '& .MuiTreeItem-root': {
      marginBottom: '0em ',
    },
    '& .MuiTreeItem-content': {
      padding: '0.625rem 0rem',
      cursor: 'default',
    },
    '& .MuiTreeItem-label:hover': {
      background: 'transparent',
      cursor: 'pointer',
    },
    '& .MuiTreeItem-content.Mui-focused': {
      backgroundColor: 'transparent',
    },
    '& .MuiTreeItem-content > .MuiTreeItem-label': {
      fontWeight: isBold ? 700 : 400,
    },
    fontSize: '0.3rem',
  };
});

const CustomizedRadio = styled(Radio)(({ theme }) => ({
  marginRight: '0.125rem',
  color: theme.palette.text.primary,
  '&.Mui-checked': {
    color: theme.palette.text.primary,
    '& .MuiSvgIcon-root:last-child': {
      path: {
        fill: theme.palette.text.saveGreen,
      },
    },
  },
}));

const LabelContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1rem',
}));

export { CustomTreeItem, CustomizedRadio, LabelContainer };
