import { useQueries } from '@tanstack/react-query';
import { QUERY_CONSTANTS } from '../../constants';
import {
  getNotificationTriggersList,
  getActiveTriggersForPlant,
  getOperationalAreas,
} from '../../utils/apiHelper';

const createCombinedData = (savedTriggers, allTriggers) => {
  return (
    allTriggers &&
    allTriggers.map((trigger) => {
      const saved =
        savedTriggers &&
        savedTriggers.find((saved) => saved.triggerId === trigger.triggerId);
      return saved
        ? {
            isEnabled: trigger.isEnabled,
            notificationTriggerId: trigger.notificationTriggerId,
            isEdited: false,
            isNew: false,
            ...saved,
          }
        : {
            isActive: false,
            isEdited: false,
            isNew: false,
            ...trigger,
          };
    })
  );
};

export const useTriggers = (plantId, setActiveTriggersData) => {
  const [savedTriggers, allTriggers, operationalAreas] = useQueries({
    queries: [
      {
        queryKey: [QUERY_CONSTANTS.ACTIVE_NOTIFICATION_TRIGGERS, plantId],
        queryFn: () => getActiveTriggersForPlant(plantId),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!plantId,
        onSuccess: (data) => {
          setActiveTriggersData(data);
        },
      },
      {
        queryKey: [QUERY_CONSTANTS.ALL_NOTIFICATION_TRIGGERS, plantId],
        queryFn: () => getNotificationTriggersList(plantId),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!plantId,
      },
      {
        queryKey: [QUERY_CONSTANTS.GET_OPERATIONAL_AREAS],
        queryFn: () => getOperationalAreas(),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: !!plantId,
      },
    ],
  });

  const allTriggerCombinedList = createCombinedData(
    savedTriggers?.data,
    allTriggers?.data
  );

  return {
    isLoading:
      savedTriggers?.isLoading ||
      allTriggers?.isLoading ||
      operationalAreas?.isLoading,
    isFetching:
      savedTriggers?.isFetching ||
      allTriggers?.isFetching ||
      operationalAreas?.isFetching,
    isRefetching:
      savedTriggers?.isRefetching ||
      allTriggers.isRefetching ||
      operationalAreas?.isRefetching,
    isError:
      savedTriggers?.isError ||
      allTriggers?.isError ||
      operationalAreas?.isError,
    isSuccess:
      savedTriggers?.isSuccess &&
      allTriggers?.isSuccess &&
      operationalAreas?.isSuccess,
    data: allTriggerCombinedList || [],
    operationalAreaData: operationalAreas?.data || [],
    error: [
      savedTriggers?.error,
      allTriggers?.error,
      operationalAreas?.error,
    ].join(','),
    refetchSavedTriggers: savedTriggers?.refetch,
    refetchAllTriggers: allTriggers?.refetch,
    refetchOperationalAreas: operationalAreas?.refetch,
  };
};
