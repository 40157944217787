/* eslint-disable no-console */
import { MenuItem, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import ConfirmIcon from '../../assets/img/confirmIcon.svg';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import { GeneralDialog } from '../../components/GeneralDialog';
import ModalBody from '../../components/GeneralDialog/GeneralDialogBody';
import Loader from '../../components/Loader';
import ModalActions from '../../components/ModalActions';
import RadioInput from '../../components/RadioInput';
import Accordion from '../../components/SMFAccordion';
import SMFSelect from '../../components/SMFSelect';
import SMFTooltip from '../../components/SMFTooltip';
import {
  DEMO_NOTIFICATION_CODE_TYPES,
  ERROR_CODE_LIST,
  HIERARCHY_LEVEL,
  QUALITY_DEFECT_CODE_LIST,
  QUALITY_DEFECT_PART_LIST,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import MESSAGE_STRINGS from '../../constants/en-us';
import { useToastContext } from '../../context/toastContext';
import {
  getEquipmentHierarchy,
  mockECNotification,
} from '../../utils/apiHelper';
import {
  findEntityNameById,
  getConfirmationDialogueText,
  getErrorAndDisabledStatus,
  getNodeIds,
} from '../../utils/helpers';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import TreeContainer from './TreeContainer';
import { DelayIn, DelayInValue } from './constants';
import * as S from './style';
import { DataTestIds } from './test-utils/constants';

const DemoNotification = ({ activeTriggersData }) => {
  const erroCodeList = ERROR_CODE_LIST.sort((a, b) => a - b);
  const { rxjsState } = useRxjsState();
  const plantId = rxjsState?.plantId;
  const { toastDispatch } = useToastContext();
  const [disable, setDisable] = useState(true);
  const [errorCodeInput, setErrorCodeInput] = useState('');
  const [delayInTime, setDelayInTime] = useState('');
  const [delayInUnit, setDelayInUnit] = useState('seconds');
  const [showConfirmation, setConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showQualityDefectError, setShowQualityDefectError] = useState(false);
  const [enableDemoNotification, setEnableDemoNotification] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [hierarchyData, setHierarchyData] = useState({});
  const [entityHierarchy, setEntityHierarchy] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityNumber, setEntityNumber] = useState('');
  const [qualityDefectCodeInput, setQualityDefectCodeInput] = useState('');
  const [qualityDefectPartIdInput, setQualityDefectPartIdInput] = useState('');
  const [qualityDefectDelayInTime, setQualityDefectDelayInTime] = useState('');
  const [qualityDefectDelayInUnit, setQualityDefectDelayInUnit] =
    useState('seconds');
  const [selectedNode, setSelectedNode] = useState(null);
  const [expandedNodeIds, setExpandedNodeIds] = useState([]);
  const [isTreeViewCollapsed, setIsTreeViewCollapsed] = useState(false);
  const [cellName, setCellName] = useState('');

  useEffect(() => {
    if (activeTriggersData) {
      setLoader(false);
      if (activeTriggersData.length > 0) {
        const activeECTrigger = activeTriggersData.find(
          (trigger) => trigger.functionalAreaId == 4 && trigger.isActive
        );
        if (activeECTrigger) {
          setEnableDemoNotification(true);
        }
      }
    }
  }, [activeTriggersData]);

  useEffect(() => {
    const errorCodeErrorStatus = getErrorAndDisabledStatus(
      errorCodeInput,
      delayInTime,
      delayInUnit
    );
    setShowError(errorCodeErrorStatus.showError);
    const qualityDefectErrorStatus = getErrorAndDisabledStatus(
      qualityDefectCodeInput,
      qualityDefectDelayInTime,
      qualityDefectDelayInUnit,
      qualityDefectPartIdInput
    );
    setShowQualityDefectError(qualityDefectErrorStatus.showError);

    setDisable(
      (errorCodeErrorStatus.disable && qualityDefectErrorStatus.disable) ||
        entityHierarchy === ''
    );
  }, [
    errorCodeInput,
    delayInTime,
    delayInUnit,
    qualityDefectCodeInput,
    qualityDefectDelayInTime,
    qualityDefectDelayInUnit,
    entityHierarchy,
    qualityDefectPartIdInput,
  ]);

  const { mutate: mockECNotificationAPI } = useMutation(
    ['mock-ec-notification'],
    async (payload) => await mockECNotification(payload),
    {
      onSuccess: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE_EC_MOCK_NOTIFICATION,
          },
        });
      },
    }
  );

  const { isLoading: isHierarchyDataLoading } = useQuery({
    queryKey: ['get-equipment-hierarchy'],
    queryFn: async () => {
      const hierarchyRes = await getEquipmentHierarchy({
        plantId,
        level: HIERARCHY_LEVEL.ASSET,
      });
      if (hierarchyRes?.data) {
        setHierarchyData({ entityChildren: [hierarchyRes?.data] });
        setExpandedNodeIds(getNodeIds(hierarchyRes?.data, []));
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: plantId !== undefined,
  });
  const handleSubmit = () => {
    setConfirmation(true);
  };

  const handleConfirm = () => {
    const faultCodeList = [];
    errorCodeInput &&
      delayInTime &&
      faultCodeList.push({
        delay: `${delayInTime} ${delayInUnit}`,
        faultCode: errorCodeInput,
      });
    qualityDefectCodeInput &&
      qualityDefectDelayInTime &&
      qualityDefectPartIdInput &&
      faultCodeList.push({
        delay: `${qualityDefectDelayInTime} ${qualityDefectDelayInUnit}`,
        qualityDefectCode: qualityDefectCodeInput,
        partID: qualityDefectPartIdInput,
      });
    mockECNotificationAPI({
      assetHierarchy: entityHierarchy,
      assetName: entityName,
      factoryID: Number(plantId),
      cellName,
      faultCodeList,
    });
    setConfirmation(false);
    setErrorCodeInput('');
    setQualityDefectCodeInput('');
    setQualityDefectPartIdInput('');
    setDelayInTime('');
    setQualityDefectDelayInTime('');
    setDelayInUnit('seconds');
    setQualityDefectDelayInUnit('seconds');
  };

  const handleCancel = () => {
    setConfirmation(false);
  };
  const handleHierarchy = (entity) => {
    const parentEntityId = entity?.parentEntityId;
    setCellName(findEntityNameById(hierarchyData, parentEntityId));
    setEntityHierarchy(entity?.entityHierarchy);
    setEntityName(entity?.entityName);
    setEntityNumber(entity?.entityNumber);
  };

  const handleRadioChange = (event) => {
    const val = event.target.value;
    setSelectedNode(val);
    setIsTreeViewCollapsed(true);
  };
  return (
    <Accordion>
      <Accordion.AccordionSummary data-testid={DataTestIds.DEMO_NOTIFICATION}>
        <Typography data-testid={DataTestIds.DEMO_NOTIFICATION_LABEL}>
          {MESSAGE_STRINGS.DEMO_NOTIFICATION}
        </Typography>
        <SMFTooltip
          message={MESSAGE_STRINGS.DEMO_NOTIFICATION_TOOLTIP_MESSAGE}
          placement="top-end"
          data-testid="demo-notification-message-tooltip"
          maxWidth="18.75rem"
        >
          <S.InfoIcon height={16} width={16} />
        </SMFTooltip>
      </Accordion.AccordionSummary>
      {isLoading && <Loader sx={{ minHeight: '5rem' }} />}
      {!isLoading && !enableDemoNotification && (
        <Accordion.AccordionDetails>
          <S.DemoNotificationInputRow
            data-testid={DataTestIds.DISABLE_DEMO_CONTAINER}
          >
            <S.Description data-testid={DataTestIds.DISABLE_DEMO_LABEL}>
              {MESSAGE_STRINGS.DISABLE_DEMO_MESSAGE}
            </S.Description>
          </S.DemoNotificationInputRow>
        </Accordion.AccordionDetails>
      )}
      {!isLoading && enableDemoNotification && (
        <Accordion.AccordionDetails>
          <Accordion
            expanded={!isTreeViewCollapsed}
            onChange={(_, expanded) => {
              setIsTreeViewCollapsed(!expanded);
            }}
          >
            <Accordion.AccordionSummary
              data-testid={DataTestIds.ENTITY_HIERARCHY_ACCORDION_HEADER}
            >
              <S.Description>{MESSAGE_STRINGS.SELECT_EQUIPMENT}</S.Description>
            </Accordion.AccordionSummary>
            <Accordion.AccordionSummary
              expandIcon=" "
              data-testid={DataTestIds.ENTITY_HIERARCHY_ACCORDION_SUB_HEADER}
            >
              <S.Description>
                <S.StyledBox>{MESSAGE_STRINGS.EQUIPMENT_HIERARCHY}</S.StyledBox>
              </S.Description>
            </Accordion.AccordionSummary>
            {isHierarchyDataLoading ? (
              <Loader />
            ) : (
              <TreeContainer
                selectedNode={selectedNode}
                handleRadioChange={handleRadioChange}
                handleHierarchy={handleHierarchy}
                nodeIds={expandedNodeIds}
                expandedNodeIds={expandedNodeIds}
                hierarchyData={hierarchyData}
                entityHierarchy={entityHierarchy}
                entityName={entityName}
                setExpandedNodeIds={setExpandedNodeIds}
              />
            )}
          </Accordion>
          {isTreeViewCollapsed && entityHierarchy && (
            <S.DemoNotificationInputRow>
              <S.StyledBox>
                <RadioInput
                  data-testid="demo-notification-selected-hierarchy"
                  value={`${entityNumber} - ${entityName}`}
                  isChecked
                  label={`${entityNumber} - ${entityName}`}
                />
              </S.StyledBox>
            </S.DemoNotificationInputRow>
          )}
          <S.DemoNotificationHeadingRow
            data-testid={DataTestIds.ERROR_CODE_DEMO_CONTAINER}
          >
            <S.DescriptionBold data-testid={DataTestIds.ERROR_CODE_DEMO_LABEL}>
              {MESSAGE_STRINGS.ERROR_CODE_DEMO}
            </S.DescriptionBold>
          </S.DemoNotificationHeadingRow>

          <S.DemoNotificationSubHeadingRow
            data-testid={DataTestIds.DEMO_NOTIFICATION_HEADER}
          >
            <S.ErrorCodeContainer>
              <S.Description data-testid={DataTestIds.ERROR_CODE_LABEL}>
                {MESSAGE_STRINGS.ERROR_CODE}
              </S.Description>
            </S.ErrorCodeContainer>
            <S.NotificationTimingContainer>
              <S.Description
                data-testid={DataTestIds.NOTIFICATION_TIMEFRAME_LABEL}
              >
                {MESSAGE_STRINGS.NOTIFICATION_TIMEFRAME}
              </S.Description>
            </S.NotificationTimingContainer>
          </S.DemoNotificationSubHeadingRow>
          <S.DemoNotificationInputRow
            isErrorState={showError}
            data-testid={DataTestIds.ERROR_CODE_CONFIG}
          >
            <S.ErrorCodeContainer>
              <S.StyledSearchInput
                data-testid={DataTestIds.ERROR_CODE_INPUT}
                label="erroCode-autocomplete"
                freeSolo={true}
                value={errorCodeInput}
                type="alphanumeric"
                onChange={(event, value) => {
                  if (!value) {
                    setErrorCodeInput('');
                  } else {
                    setErrorCodeInput(value);
                  }
                }}
                inputValue={errorCodeInput}
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setErrorCodeInput('');
                  } else {
                    setErrorCodeInput(newInputValue);
                  }
                }}
                options={erroCodeList.map((errorCode) => errorCode)}
                placeholder={MESSAGE_STRINGS.ERROR_CODE_PLACEHOLDER}
              />
            </S.ErrorCodeContainer>
            <S.ConfigurationContainer
              data-testid={DataTestIds.DELAY_CONFIG_CONTAINER}
            >
              <S.LabelConfigContainer>
                <S.DisplayIn data-testid={DataTestIds.DISPLAY_IN_LABEL}>
                  {MESSAGE_STRINGS.DISPLAY_IN}
                </S.DisplayIn>
                <S.ColumnFlexContainer>
                  <S.FlexContainer>
                    <S.DisplayInInput
                      isErrorState={showError}
                      data-testid={DataTestIds.DISPLAY_IN_INPUT}
                      register={() => {}}
                      name="displayInDurationInput"
                      value={delayInTime}
                      type="number"
                      onKeyPress={(e) => {
                        const restrictedInput = ['.', '+', '-', 'e', 'E'];
                        if (
                          restrictedInput.includes(e.key) ||
                          (e.target.value < 1 && e.key === '0')
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setDelayInTime(e.target.value);
                      }}
                    />
                    <SMFSelect
                      data-testid={DataTestIds.DELAY_IN_DROPDOWN}
                      name="displayInDuration"
                      value={delayInUnit}
                      onChange={({ target: { value } }) => {
                        setDelayInUnit(value);
                      }}
                    >
                      <MenuItem
                        value={DelayInValue.SECONDS}
                        data-testid={DataTestIds.DELAY_IN_SECONDS}
                      >
                        {DelayIn.SECONDS}
                      </MenuItem>
                      <MenuItem
                        value={DelayInValue.MINUTES}
                        data-testid={DataTestIds.DELAY_IN_MINUTES}
                      >
                        {DelayIn.MINUTES}
                      </MenuItem>
                    </SMFSelect>
                  </S.FlexContainer>
                  <S.FlexContainer>
                    {showError && (
                      <S.ErrorContainer
                        data-testid={DataTestIds.DURATION_ERROR}
                      >
                        <ErrorMessage
                          text={MESSAGE_STRINGS.DURATION_ERROR_MESSAGE}
                        />
                      </S.ErrorContainer>
                    )}
                  </S.FlexContainer>
                </S.ColumnFlexContainer>
              </S.LabelConfigContainer>
            </S.ConfigurationContainer>
          </S.DemoNotificationInputRow>

          <S.DemoNotificationHeadingRow
            data-testid={DataTestIds.QUALITY_DEFECT_CODE_DEMO_CONTAINER}
          >
            <S.DescriptionBold
              data-testid={DataTestIds.QUALITY_DEFECT_CODE_DEMO_LABEL}
            >
              {MESSAGE_STRINGS.QUALITY_DEFECT_DEMO}
            </S.DescriptionBold>
          </S.DemoNotificationHeadingRow>

          <S.DemoNotificationSubHeadingRow
            data-testid={DataTestIds.DEMO_NOTIFICATION_HEADER_QUALITY}
          >
            <S.ErrorCodeContainer>
              <S.Description
                data-testid={DataTestIds.QUALITY_DEFECT_CODE_LABEL}
              >
                {MESSAGE_STRINGS.QUALITY_DEFECT_CODE}
              </S.Description>
            </S.ErrorCodeContainer>
            <S.NotificationTimingContainer>
              <S.Description data-testid={DataTestIds.PART_ID_LABEL_QUALITY}>
                {MESSAGE_STRINGS.PART_ID}
              </S.Description>
            </S.NotificationTimingContainer>
            <S.NotificationTimingContainer>
              <S.Description
                data-testid={DataTestIds.NOTIFICATION_TIMEFRAME_LABEL_QUALITY}
              >
                {MESSAGE_STRINGS.NOTIFICATION_TIMEFRAME}
              </S.Description>
            </S.NotificationTimingContainer>
          </S.DemoNotificationSubHeadingRow>
          <S.DemoNotificationInputRow
            isErrorState={showError}
            data-testid={DataTestIds.QUALITY_DEFECT_CODE_CONFIG}
          >
            <S.ErrorCodeContainer>
              <S.StyledSearchInput
                data-testid={DataTestIds.QUALITY_DEFECT_CODE_INPUT}
                label="qualityDefectCode-autocomplete"
                freeSolo={true}
                value={qualityDefectCodeInput}
                type="alphanumeric"
                onChange={(event, value) => {
                  if (!value) {
                    setQualityDefectCodeInput('');
                  } else {
                    setQualityDefectCodeInput(value);
                  }
                }}
                inputValue={qualityDefectCodeInput}
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setQualityDefectCodeInput('');
                  } else {
                    setQualityDefectCodeInput(newInputValue);
                  }
                }}
                options={QUALITY_DEFECT_CODE_LIST.map((errorCode) => errorCode)}
                placeholder={MESSAGE_STRINGS.QUALITY_DEFECT_CODE_PLACEHOLDER}
              />
            </S.ErrorCodeContainer>
            <S.ErrorCodeContainer>
              <S.StyledSearchInput
                data-testid={DataTestIds.QUALITY_DEFECT_PART_INPUT}
                label="qualityDefectPart-autocomplete"
                freeSolo={true}
                value={qualityDefectPartIdInput}
                type="numeric"
                onChange={(event, value) => {
                  if (!value) {
                    setQualityDefectPartIdInput('');
                  } else {
                    setQualityDefectPartIdInput(value);
                  }
                }}
                inputValue={qualityDefectPartIdInput}
                onInputChange={(event, newInputValue) => {
                  if (!newInputValue) {
                    setQualityDefectPartIdInput('');
                  } else {
                    setQualityDefectPartIdInput(newInputValue);
                  }
                }}
                options={QUALITY_DEFECT_PART_LIST.map((errorCode) => errorCode)}
                placeholder={MESSAGE_STRINGS.QUALITY_DEFECT_PART_PLACEHOLDER}
              />
            </S.ErrorCodeContainer>

            <S.ConfigurationContainer
              data-testid={DataTestIds.DELAY_CONFIG_CONTAINER}
            >
              <S.LabelConfigContainer>
                <S.DisplayIn data-testid={DataTestIds.QUALITY_DISPLAY_IN_LABEL}>
                  {MESSAGE_STRINGS.DISPLAY_IN}
                </S.DisplayIn>
                <S.ColumnFlexContainer>
                  <S.FlexContainer>
                    <S.DisplayInInput
                      isErrorState={showQualityDefectError}
                      data-testid={DataTestIds.QUALITY_DISPLAY_IN_INPUT}
                      register={() => {}}
                      name="qualityDefectDisplayInDurationInput"
                      value={qualityDefectDelayInTime}
                      type="number"
                      onKeyPress={(e) => {
                        const restrictedInput = ['.', '+', '-', 'e', 'E'];
                        if (
                          restrictedInput.includes(e.key) ||
                          (e.target.value < 1 && e.key === '0')
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setQualityDefectDelayInTime(e.target.value);
                      }}
                    />
                    <SMFSelect
                      data-testid={DataTestIds.QUALITY_DELAY_IN_DROPDOWN}
                      name="qualityDefectDisplayInDuration"
                      value={qualityDefectDelayInUnit}
                      onChange={({ target: { value } }) => {
                        setQualityDefectDelayInUnit(value);
                      }}
                    >
                      <MenuItem
                        value={DelayInValue.SECONDS}
                        data-testid={DataTestIds.DELAY_IN_SECONDS}
                      >
                        {DelayIn.SECONDS}
                      </MenuItem>
                      <MenuItem
                        value={DelayInValue.MINUTES}
                        data-testid={DataTestIds.DELAY_IN_MINUTES}
                      >
                        {DelayIn.MINUTES}
                      </MenuItem>
                    </SMFSelect>
                  </S.FlexContainer>
                  <S.FlexContainer>
                    {showQualityDefectError && (
                      <S.ErrorContainer
                        data-testid={DataTestIds.DURATION_ERROR}
                      >
                        <ErrorMessage
                          text={MESSAGE_STRINGS.DURATION_ERROR_MESSAGE}
                        />
                      </S.ErrorContainer>
                    )}
                  </S.FlexContainer>
                </S.ColumnFlexContainer>
              </S.LabelConfigContainer>
            </S.ConfigurationContainer>
          </S.DemoNotificationInputRow>

          <S.SendButtonContainer
            data-testid={DataTestIds.SEND_BUTTON_CONTAINER}
          >
            <Button
              type="submit"
              disabled={disable || showError || showQualityDefectError}
              data-testid={DataTestIds.SEND_BUTTON}
              onClick={handleSubmit}
            >
              {MESSAGE_STRINGS.SEND}
            </Button>
          </S.SendButtonContainer>
        </Accordion.AccordionDetails>
      )}
      {showConfirmation && (
        <GeneralDialog
          open={showConfirmation}
          fullWidth={false}
          maxWidth="40rem"
          data-testid={DataTestIds.CONFIRMATION_DIALOG}
        >
          <ModalBody
            minWidth="36rem"
            maxWidth="36rem"
            minHeight="14rem"
            dialogTitle={
              <S.StyledModalHeader>
                <ConfirmIcon height={32} width={33} />
                <S.StyleModalHeaderContent
                  data-testid={DataTestIds.CONFIRMATION_HEADER}
                >
                  {MESSAGE_STRINGS.CONFIRM}!
                </S.StyleModalHeaderContent>
              </S.StyledModalHeader>
            }
            dialogContent={
              <S.ModalContent
                data-testid={DataTestIds.CONFIRMATION_MODAL_CONTENT}
              >
                <S.ModalContentSpanPB>
                  {entityHierarchy && (
                    <>
                      {`${MESSAGE_STRINGS.DEMO_CONFIRMATION_MESSAGE_HIERARCHY}`}
                      <b>{` ${entityNumber} - ${entityName}`}</b>
                    </>
                  )}{' '}
                </S.ModalContentSpanPB>
                {getConfirmationDialogueText(
                  errorCodeInput,
                  delayInTime,
                  delayInUnit
                )}
                {getConfirmationDialogueText(
                  qualityDefectCodeInput,
                  qualityDefectDelayInTime,
                  qualityDefectDelayInUnit,
                  qualityDefectPartIdInput,
                  DEMO_NOTIFICATION_CODE_TYPES.QUALITY_DEFECT
                )}

                <S.ModalContentSpan
                  data-testid={DataTestIds.CONFIRMATION_MODAL_SECONDARY_TEXT}
                >
                  {MESSAGE_STRINGS.CONTINUE_MESSAGE}
                </S.ModalContentSpan>
              </S.ModalContent>
            }
            actions={
              <ModalActions
                confirmText={MESSAGE_STRINGS.YES}
                cancelText={MESSAGE_STRINGS.NO}
                handleSave={handleConfirm}
                handleClose={handleCancel}
              />
            }
          ></ModalBody>
        </GeneralDialog>
      )}
    </Accordion>
  );
};

export default DemoNotification;
