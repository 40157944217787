import {
  CUSTOM_NOTIFICATION_METRICS,
  CUSTOM_NOTIFICATION_TYPES,
  DEMO_NOTIFICATION_CODE_TYPES,
  HIERARCHY_LEVEL,
} from '../constants';
import MESSAGE_STRINGS from '../constants/en-us';
import { DelayInValue } from '../containers/DemoNotification/constants';
import { DataTestIds } from '../containers/DemoNotification/test-utils/constants';

export const getErrorAndDisabledStatus = (val, time, unit, partId) => {
  const errorStatus = {
    disable: false,
    showError: false,
  };

  if (val && time && Number(time) !== 0 && unit) {
    const isMinutes = unit === DelayInValue.MINUTES;
    const isSeconds = unit === DelayInValue.SECONDS;
    const isLessThanTwoHours =
      (isMinutes && Number(time) <= 120) || (isSeconds && Number(time) <= 7200);

    errorStatus.disable = !isLessThanTwoHours;
    errorStatus.showError = !isLessThanTwoHours;
  } else {
    const isMinutes = unit === DelayInValue.MINUTES;
    const isSeconds = unit === DelayInValue.SECONDS;
    const isLessThanTwoHours =
      (isMinutes && Number(time) <= 120) || (isSeconds && Number(time) <= 7200);

    errorStatus.showError = time ? !isLessThanTwoHours : false;
    errorStatus.disable = true;
  }

  if (partId === '') {
    errorStatus.disable = true;
  }

  return errorStatus;
};

export const getNodeIds = (hierarchy, result = []) => {
  if (hierarchy.entityType === HIERARCHY_LEVEL.PLANT)
    result.push(`${hierarchy.entityId}`);
  if (hierarchy?.entityChildren) {
    hierarchy?.entityChildren.forEach((child) => {
      getNodeIds(child, result);
    });
  }
  return result;
};

export const getConfirmationDialogueText = (
  errorCodeInput,
  delayInTime,
  delayInUnit,
  partId,
  notificationType
) =>
  errorCodeInput !== '' &&
  errorCodeInput !== undefined &&
  delayInTime &&
  (notificationType !== DEMO_NOTIFICATION_CODE_TYPES.QUALITY_DEFECT ||
    partId) ? (
    <div data-testid={DataTestIds.CONFIRMATION_MODAL_PRIMARY_TEXT}>
      {errorCodeInput &&
        (partId === undefined
          ? MESSAGE_STRINGS.DEMO_CONFIRMATION_MESSAGE_PART_A
          : MESSAGE_STRINGS.QUALITY_DEFECT_DEMO_CONFIRMATION_MESSAGE_PART_A)}{' '}
      <b>{errorCodeInput} </b>{' '}
      {partId && (
        <>
          {`${MESSAGE_STRINGS.QUALITY_DEFECT_DEMO_CONFIRMATION_MESSAGE_PART_ID}`}
          <b>{` ${partId}`}</b>
        </>
      )}{' '}
      {partId === undefined
        ? MESSAGE_STRINGS.DEMO_CONFIRMATION_MESSAGE_PART_B
        : MESSAGE_STRINGS.QUALITY_DEFECT_MESSAGE_PART_B}{' '}
      <b>
        {delayInTime} {delayInUnit}
      </b>
      {'.'}
    </div>
  ) : (
    ''
  );

export function findEntityNameById(entity, entityId) {
  if (entity.entityId === entityId) {
    return entity.entityName;
  }

  if (entity.entityChildren) {
    for (const child of entity.entityChildren) {
      const result = findEntityNameById(child, entityId);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export const getOptionsListByMetricAndModule = (
  metricsSettings,
  metricName,
  moduleId
) => {
  let options = [];
  metricsSettings?.[metricName]?.[moduleId]?.forEach((metric) => {
    options.push(metric?.name);
  });
  return options;
};

export const getNotificationCategoryDetailsToRender = (
  metaData,
  notificationType,
  moduleId
) => {
  let detailsToRender = {};
  if (notificationType === CUSTOM_NOTIFICATION_TYPES.MODULE) {
    const alertList = getOptionsListByMetricAndModule(
      metaData?.[notificationType],
      CUSTOM_NOTIFICATION_METRICS.ALERT,
      moduleId
    );
    const triggerList = getOptionsListByMetricAndModule(
      metaData?.[notificationType],
      CUSTOM_NOTIFICATION_METRICS.TRIGGER,
      moduleId
    );
    detailsToRender.alert = alertList;
    detailsToRender.trigger = triggerList;
  }
  return detailsToRender;
};

export const getDisplayMessageFromTriggerName = (
  triggerName,
  metaData,
  notificationType,
  moduleName
) => {
  if (notificationType === CUSTOM_NOTIFICATION_TYPES.MODULE) {
    const module = metaData[notificationType][
      CUSTOM_NOTIFICATION_METRICS.MODULE
    ].find((module) => module.name === moduleName);
    const trigger = metaData[notificationType][
      CUSTOM_NOTIFICATION_METRICS.TRIGGER
    ]?.[module?.id]?.find((trigger) => trigger.name === triggerName);
    return trigger?.displayText;
  }
  return '';
};

export const isModuleMetricValid = (metricData, displayedMessage) => {
  return (
    metricData.module &&
    metricData.alert &&
    metricData.trigger &&
    displayedMessage
  );
};
