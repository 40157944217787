import { MessageContainer, MessageTitle } from './style.js';
import GeneralButton from '../../../components/GeneralButton';
import MESSAGE_STRINGS from '../../../constants/en-us';
import PropTypes from 'prop-types';

export default function EmptyActiveTriggers({ addTriggersHandler }) {
  return (
    <MessageContainer>
      <MessageTitle>{MESSAGE_STRINGS.EMPTY_ACTIVE_NOTIFICATIONS}</MessageTitle>
      <GeneralButton
        type="primary"
        onClick={addTriggersHandler}
        text={MESSAGE_STRINGS.ADD_NOTIFICATIONS}
        data-testid="empty-add-active-triggers"
      />
    </MessageContainer>
  );
}

EmptyActiveTriggers.propTypes = {
  addTriggersHandler: PropTypes.func,
};
