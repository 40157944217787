import { Box, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/system';

const SliderContainer = styled(Box)(() => ({
  width: '95%',
  position: 'relative',
  marginTop: '1rem',
}));

const StyledSlider = styled(Box)(({ theme }) => ({
  width: '99%',
  height: '0.375rem',
  backgroundColor: theme.palette.background.default,
  position: 'absolute',
}));

const UnselectedSlider = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selectedMark' && prop !== 'totalMarks',
})(({ theme, selectedMark, totalMarks }) => ({
  width: `calc(${((selectedMark - 1) * 100) / (totalMarks - 1)}%)`,
  height: '0.375rem',
  backgroundColor: theme.palette.text.lightWhiteGrey,
  position: 'absolute',
}));

const SelectionPoints = styled(Box)(() => ({
  width: 'calc(100%)',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  transform: 'translateY(-16px)',
  '& .MuiRadio-root': {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  '& .MuiFormControlLabel-label': {
    position: 'absolute',
    top: '-1rem',
    fontSize: '0.75rem',
    '&.Mui-disabled': {
      color: theme.customColors.white,
    },
  },
}));

const DescriptionContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '1rem',
}));

const Description = styled(Typography)(() => ({
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
}));

export {
  SliderContainer,
  StyledSlider,
  UnselectedSlider,
  SelectionPoints,
  StyledFormControlLabel,
  DescriptionContainer,
  Description,
};
