import React, { useState, useEffect } from 'react';
import { GeneralDialog } from '../../../../components/GeneralDialog';
import ModalBody from '../../../../components/GeneralDialog/GeneralDialogBody';
import MESSAGE_STRINGS from '../../../../constants/en-us';
import ModalActions from '../../../../components/ModalActions';
import {
  updateTempMuteAdditionalSettingsForTrigger,
  updateAdditionalSettingsForTrigger,
  toggleAdditionalSettingsModal,
  useTriggerSettingsContext,
} from '../../../../context/triggerSettingsContext';
import TriggerSpecificAdditionalSettings from './TriggerSpecificAdditionalSettings';
import {
  StyledModalHeader,
  StyledDivider,
  StyledModalHeaderAdditionalSettings,
} from './style';
import { TOAST_REDUCER_CONSTANTS } from '../../../../constants';
import { useToastContext } from '../../../../context/toastContext';

const AdditionalSettingsModal = ({ open, data }) => {
  const {
    triggerSettingsState: { editedTriggersList },
  } = useTriggerSettingsContext();

  useEffect(() => {
    const TriggersListForTriggerID = editedTriggersList.find(
      (x) => x.triggerId === data.triggerId
    );

    !!TriggersListForTriggerID?.tempMuteValue &&
      setTimeRangeValue(TriggersListForTriggerID?.tempMuteValue);
    !!TriggersListForTriggerID?.tempMuteUoM &&
      setTimeRangeUnit(TriggersListForTriggerID?.tempMuteUoM);
  }, [data?.triggerId, open]);

  const { triggerSettingsDispatch } = useTriggerSettingsContext();
  const { toastDispatch } = useToastContext();
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const disableSaveBtn = (value) => {
    setIsSaveDisabled(value);
  };

  const [timeRangeValue, setTimeRangeValue] = useState('');
  const [timeRangeUnit, setTimeRangeUnit] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleUpdate = () => {
    if (data?.functionalAreaName === 'Overall Equipment Effectiveness') {
      updateTempMuteAdditionalSettingsForTrigger(
        triggerSettingsDispatch,
        data.triggerId,
        timeRangeValue,
        timeRangeUnit
      );
    } else {
      updateAdditionalSettingsForTrigger(
        triggerSettingsDispatch,
        data.triggerId
      );
    }

    toggleAdditionalSettingsModal(triggerSettingsDispatch, null, false);
    toastDispatch({
      type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
      payload: {
        message:
          MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE_ADDITIONAL_SETTINGS_MODAL,
      },
    });
  };

  const handleCancel = () => {
    toggleAdditionalSettingsModal(triggerSettingsDispatch, null, false);
  };

  return (
    <GeneralDialog open={open} fullWidth={false}>
      <ModalBody
        minWidth={
          data?.functionalAreaName === 'Overall Equipment Effectiveness'
            ? '54.25rem'
            : '65rem'
        }
        maxWidth="70rem"
        minHeight="15.75rem"
        dialogTitle={
          data?.functionalAreaName === 'Overall Equipment Effectiveness' ? (
            <StyledModalHeaderAdditionalSettings>
              {MESSAGE_STRINGS.ADDITIONAL_NOTIFICATION_SETTINGS}
            </StyledModalHeaderAdditionalSettings>
          ) : (
            <StyledModalHeader>
              {MESSAGE_STRINGS.ADDITIONAL_NOTIFICATION_SETTINGS}
            </StyledModalHeader>
          )
        }
        breakerLine={
          data?.functionalAreaName === 'Overall Equipment Effectiveness' && (
            <StyledDivider />
          )
        }
        dialogContent={
          <TriggerSpecificAdditionalSettings
            trigger={data}
            additionalTempMuteData={{
              timeRangeValue,
              setTimeRangeValue,
              timeRangeUnit,
              setTimeRangeUnit,
              errorMsg,
              setErrorMsg,
            }}
            disableSaveBtn={disableSaveBtn}
          />
        }
        actions={
          <ModalActions
            confirmText={MESSAGE_STRINGS.UPDATE}
            cancelText={MESSAGE_STRINGS.CANCEL}
            handleSave={handleUpdate}
            handleClose={handleCancel}
            isSaveDisabled={isSaveDisabled || !!errorMsg}
          />
        }
      ></ModalBody>
    </GeneralDialog>
  );
};

export default AdditionalSettingsModal;
