import { Box, Typography, styled } from '@mui/material';

const StyledDialogContentBox = styled(Box)({
  margin: '0.75rem 1.5rem 0.75rem 0rem',
});

const StyledDialogContentTypography = styled(Typography)({
  fontWeight: '700',
  marginTop: '1.5rem',
});

export { StyledDialogContentBox, StyledDialogContentTypography };
