import PropTypes from 'prop-types';
import { GeneralDialog } from '../../../components/GeneralDialog';
import ModalBody from '../../../components/GeneralDialog/GeneralDialogBody';
import MESSAGE_STRINGS from '../../../constants/en-us';
import Actions from '../../../components/ModalActions';
import DeleteCustomNotificationModalContent from '../DeleteCustomNotificationModalContent';
import { useMutation } from '@tanstack/react-query';
import { useRxjsState } from '../../../utils/hooks/useRxjsState';
import ConfirmIcon from '../../../assets/img/confirmIcon.svg';
import { QUERY_CONSTANTS, TOAST_REDUCER_CONSTANTS } from '../../../constants';
import { deleteCustomNotification } from '../../../utils/apiHelper';
import { useToastContext } from '../../../context/toastContext';
import { StyledIconBox } from './style';

export const DeleteCustomNotificationModal = ({
  open,
  closeModal,
  triggerId,
  refetchAllTriggers,
  refetchSavedTriggers,
}) => {
  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();

  const handleDeleteConfirm = () => {
    deleteNotification();
  };

  /* 
    Delete custom notification
  */

  const {
    mutate: deleteNotification,
    isLoading: isCustomNotificationIsBeingDeleted,
  } = useMutation(
    [QUERY_CONSTANTS.DELETE_CUSTOM_NOTIFICATION],
    async () => {
      const queryParams = {
        plantId: rxjsState.plantId,
        triggerId: triggerId,
      };
      return deleteCustomNotification(queryParams);
    },
    {
      onSuccess: () => {
        refetchAllTriggers();
        refetchSavedTriggers();
        closeModal();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE_MODAL,
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_ERROR_MESSAGE,
          },
        });
      },
    }
  );

  return (
    <GeneralDialog
      open={open}
      fullWidth={false}
      sx={{
        '& .dialog-title': { fontWeight: '700' },
      }}
    >
      <ModalBody
        dialogTitle={MESSAGE_STRINGS.DELETE_CUSTOM_NOTIFICATION_DIALOG_TITLE}
        isLargeTitle={true}
        customIcon={
          <StyledIconBox>
            <ConfirmIcon height="2.5rem" width="2.5rem" />
          </StyledIconBox>
        }
        dialogContent={
          <DeleteCustomNotificationModalContent
            isCustomNotificationIsBeingDeleted={
              isCustomNotificationIsBeingDeleted
            }
          />
        }
        minWidth="30rem"
        maxWidth="30rem"
        actions={
          <Actions
            confirmText={MESSAGE_STRINGS.YES}
            cancelText={MESSAGE_STRINGS.NO}
            handleSave={handleDeleteConfirm}
            handleClose={closeModal}
          />
        }
      ></ModalBody>
    </GeneralDialog>
  );
};

DeleteCustomNotificationModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  triggerId: PropTypes.string,
  refetchAllTriggers: PropTypes.func,
  refetchSavedTriggers: PropTypes.func,
};
