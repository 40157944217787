import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const BasicTableError = ({ errorMsg = 'There was an error' }) => (
  <Box position="relative">
    <Box
      p={4}
      color="red"
      position="absolute"
      left="70%"
      bgcolor="black"
      top="10rem"
    >
      {errorMsg}
    </Box>
  </Box>
);

BasicTableError.propTypes = {
  errorMsg: PropTypes.string,
};

BasicTableError.defaultProps = {
  errorMsg: 'There was an error',
};

export default BasicTableError;
