/* eslint-disable no-console */
import { FormControl, RadioGroup, styled } from '@mui/material';
import RenderTreeData from '../../components/RenderTreeData/index';
import * as S from './style';
import { Box } from '@mui/system';
import { memo } from 'react';
import { PropTypes } from 'prop-types';

const TreeContainer = ({
  selectedNode,
  handleRadioChange,
  handleHierarchy,
  nodeIds,
  selectedNodeIds,
  hierarchyData,
  entityHierarchy,
  entityName,
  setExpandedNodeIds,
}) => {
  const StyledTree = styled(Box)(() => ({
    minWidth: '35%',
    overflowY: 'auto',
  }));

  return (
    <S.StyledBoxWithScroll>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="tree-nodes"
          name="tree-nodes"
          value={selectedNode}
          onChange={handleRadioChange}
        >
          <StyledTree data-testid="hierarchy-tree">
            {hierarchyData?.entityChildren?.map((childNode) => {
              return (
                <RenderTreeData
                  entityHierarchy={entityHierarchy}
                  entityName={entityName}
                  data={childNode}
                  key={`${childNode?.entityId}`}
                  handleHierarchy={handleHierarchy}
                  nodeIds={nodeIds}
                  selectedNodeIds={selectedNodeIds}
                  setExpandedNodeIds={setExpandedNodeIds}
                />
              );
            })}
          </StyledTree>
        </RadioGroup>
      </FormControl>
    </S.StyledBoxWithScroll>
  );
};

TreeContainer.propTypes = {
  selectedNode: PropTypes.string,
  handleRadioChange: PropTypes.func,
  handleHierarchy: PropTypes.func,
  nodeIds: PropTypes.instanceOf(Array),
  selectedNodeIds: PropTypes.instanceOf(Array),
  hierarchyData: PropTypes.shape({
    entityChildren: PropTypes.instanceOf(Array),
  }),
  entityHierarchy: PropTypes.string,
  entityName: PropTypes.string,
  setExpandedNodeIds: PropTypes.func,
};

const CachedTreeContainer = memo(TreeContainer);

export default CachedTreeContainer;
