import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import InfoIconComponent from '../../assets/img/infoIcon.svg';
import SMFAccordion from '../../components/SMFAccordion';

const ActionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0 0.5rem',
  borderTop: `.063rem solid ${theme.customColors.wrapperGrey}`,
}));

const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
}));

const FilterContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const FilterName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})(({ theme, isDisabled = false }) => ({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.188rem',
  color: isDisabled
    ? theme.customColors.darkGrey
    : theme.palette.text.lightUnitGrey,
}));

const SaveButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  padding: '0 0.5rem',
  borderTop: `.063rem solid ${theme.customColors.wrapperGrey}`,
}));

const InfoIcon = styled(InfoIconComponent)(() => ({
  marginLeft: '0.56rem',
  marginTop: '0.45rem',
}));

const NotificationConfigAccordionTitle = styled(SMFAccordion.AccordionSummary)(
  () => ({
    '&.Mui-disabled': {
      pointerEvents: 'all',
      '.MuiAccordionSummary-content': {
        filter: 'unset',
        '.MuiTypography-root': {
          filter:
            'brightness(0) saturate(100%) invert(35%) sepia(3%) saturate(630%) hue-rotate(175deg) brightness(96%) contrast(98%)',
        },
      },
    },
  })
);

export {
  ActionsBox,
  ButtonsContainer,
  FilterContainer,
  FilterName,
  SaveButtonContainer,
  InfoIcon,
  NotificationConfigAccordionTitle,
};
