import { Box } from '@mui/material';
import React from 'react';
import { ToolTipContainer, TooltipTypography } from './style';
import MESSAGE_STRINGS from '../../constants/en-us';
import { TEST_IDS } from '../../constants';

const NotificationTypeCustomToolTip = () => {
  return (
    <ToolTipContainer
      data-testid={TEST_IDS.NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP_MSG}
    >
      <Box>
        <TooltipTypography>
          {MESSAGE_STRINGS.NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP_MESSAGE.HEADING}
        </TooltipTypography>
      </Box>
      <Box>
        <TooltipTypography isBold component="span">
          {
            MESSAGE_STRINGS.NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP_MESSAGE
              .DATA_CONDITION
          }
        </TooltipTypography>
        <TooltipTypography component="span">
          {
            MESSAGE_STRINGS.NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP_MESSAGE
              .DATA_CONDITION_DESC
          }
        </TooltipTypography>
      </Box>
      <Box>
        <TooltipTypography isBold component="span">
          {MESSAGE_STRINGS.NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP_MESSAGE.MODULE}
        </TooltipTypography>
        <TooltipTypography component="span">
          {
            MESSAGE_STRINGS.NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP_MESSAGE
              .MODULE_DESC
          }
        </TooltipTypography>
      </Box>
    </ToolTipContainer>
  );
};
export default NotificationTypeCustomToolTip;
