import * as S from './style';

/**
 * @author Dima Borodin
 *
 * @param name Required - string: The field name, should match the name that is defined in the validation schema
 * and the defaultValues objects.
 * @param register Required - () => object: The form's register function reference
 *
 * @returns A text input component that connects to React-hook-form
 */

const Input = ({
  name,
  label,
  error = '',
  register,
  isRequired = false,
  placeholder = '',
  spellCheck = false,
  autoComplete = false,
  width,
  height,
  errorTextWidth,
  className,
  padding,
  errorBox = true,
  ...rest
}) => {
  const isErrorState = !!error;

  return (
    <S.ComponentContainer
      data-testid={`input-container--${name}`}
      className={className}
    >
      {label && (
        <S.Label htmlFor={name} aria-label={label}>
          {label}
          {isRequired && <S.RequiredStar>*</S.RequiredStar>}
        </S.Label>
      )}

      <S.Input
        type="text"
        placeholder={placeholder || label}
        autoComplete={autoComplete ? 'on' : 'off'}
        id={`input-text--${name}`}
        data-testid={`input-text--${name}`}
        aria-required={isRequired}
        aria-invalid={isErrorState}
        width={width}
        height={height}
        padding={padding}
        {...{ name, spellCheck, isErrorState }}
        {...register(name)}
        {...rest}
      />

      {isErrorState && errorBox && (
        <S.ErrorTextContainer
          aria-label="error-message"
          errorTextWidth={errorTextWidth}
        >
          <S.ErrorIcon />
          <span>{error}</span>
        </S.ErrorTextContainer>
      )}
    </S.ComponentContainer>
  );
};

export default Input;
