import { styled, Box } from '@mui/system';

const TableContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isEditing',
})(({ isEditing = false }) => ({
  minHeight: '36.5rem',
  '& .basicGeneralTable .MuiTable-root': {
    tableLayout: 'auto',
  },
  '& .basicGeneralTable .MuiTableRow-root': {
    verticalAlign: isEditing ? 'initial' : 'middle',
  },
  '& .basicGeneralTable .basicGeneralTable_headerRow': {
    verticalAlign: 'middle',
  },
}));

export { TableContainer };
