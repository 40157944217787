import { Typography, styled, Box, RadioGroup, Backdrop } from '@mui/material';

export const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
};

const ConfigOuterContainer = styled(Box)(({ theme }) => ({
  height: '31.813rem',
  width: '64.5rem',
  backgroundColor: theme.palette.background.blackGrey,
}));

const ConfigInnerContainer = styled(Box)(({ theme }) => ({
  height: '31.8rem',
  padding: '1.5rem',
  border: `0.063rem solid ${theme.customColors.wrapperGrey}`,
}));

const NotificationTypeContainer = styled(Box)(() => ({
  height: '25%',
  marginBottom: '2rem',
}));

const StyledMetric = styled(Box)(() => ({
  minHeight: '25%',
  marginBottom: '2rem',
}));

const StyledDisplay = styled(Box)(() => ({
  height: '25%',
  marginBottom: '2rem',
}));

const StyledDisplayRow = styled(Box)(() => ({
  width: '78%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StyledTriggerNameTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  marginTop: '0.3rem',
  color: theme.palette.text.lightUnitGrey,
}));

const InputContainer = styled(Box)(() => ({
  marginTop: '0.3rem',
  width: '22.321rem',
}));

const SubHeadingTypography = styled(Typography)({
  ...typographyStyles,
  fontSize: '1rem',
  fontWeight: 700,
});

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  '& .MuiRadio-root.Mui-checked': {
    color: theme.palette.background.saveGreen,
    paddingTop: '0.5rem',
  },
  '& .MuiRadio-root.Mui-disabled': {
    color: theme.palette.text.radioButtonGrey,
    paddingBottom: '0.5rem',
  },
  '& .MuiRadio-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiSvgIcon-root': {
    width: '0.871rem',
    height: '0.871rem',
  },
}));

const RadioBtnTypography = styled(Typography, {
  shouldForwardProp: (props) => props !== 'disabled',
})(({ theme, disabled }) => ({
  fontSize: '0.875rem',
  color: disabled
    ? theme.palette.text.radioButtonGrey
    : theme.palette.text.primary,
}));

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const BackdropContainer = styled(Box)(() => ({
  display: 'flex',
  maxHeight: '100%',
  flex: 1,
  flexDirection: 'column',
}));

export {
  ConfigOuterContainer,
  ConfigInnerContainer,
  SubHeadingTypography,
  NotificationTypeContainer,
  StyledMetric,
  StyledDisplay,
  StyledDisplayRow,
  StyledTriggerNameTypography,
  InputContainer,
  FlexBox,
  StyledRadioGroup,
  RadioBtnTypography,
  CustomBackdrop,
  BackdropContainer,
};
