import { ThemeProvider } from '@mui/material';
import { globalstate$, theme, splitRootFactory } from '@smf/ui-util-app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';
import ConfigContainer from './containers/ConfigContainer';
import { ToastProvider } from './context/toastContext';

export default function Root() {
  const queryClient = new QueryClient();
  return (
    <SplitFactory factory={splitRootFactory}>
      <SplitClient
        splitKey={globalstate$._value?.userData?.email?.toLowerCase()}
      >
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ToastProvider>
              <ConfigContainer />
            </ToastProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </SplitClient>
    </SplitFactory>
  );
}
