import PropTypes from 'prop-types';
import SMFSelect from '../SMFSelect';
import { HiddenMenuItem } from './style';
/*
 ** @param rest - pass onOpen and open to make it a controlled component
 */

function PseduoSelect({
  value,
  children,
  label,
  handleOnClose,
  error = false,
  disabled,
  placeholder = '',
  ...rest
}) {
  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  return (
    <>
      <SMFSelect
        data-testid="pseudoSelect"
        value={value}
        MenuProps={MenuProps}
        onClose={handleOnClose}
        disabled={disabled}
        error={error}
        placeholderText={placeholder}
        {...rest}
      >
        <HiddenMenuItem value={value}>{label}</HiddenMenuItem>
        {children}
      </SMFSelect>
    </>
  );
}

PseduoSelect.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  label: PropTypes.string,
  handleOnClose: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  open: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default PseduoSelect;
