const MESSAGE_STRINGS = {
  NOTIFICATIONS: 'Notifications',
  ADDITIONAL_NOTIFICATION_SETTINGS: 'Additional Notification Settings',
  ADD_REMOVE: 'Add / Remove',
  ADD_NOTIFICATIONS: 'Add Notifications',
  ADD_CUSTOM_NOTIFICATION: 'Add Custom Notification',
  ENABLE_DISABLE: 'Enable / Disable',
  CUSTOM_NOTIFICATION: 'Custom Notification',
  LOADING: 'Loading...',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  UPDATE: 'Update',
  EDIT: 'Edit',
  EMPTY_ACTIVE_NOTIFICATIONS: 'Activated Notifications will display here.',
  REQUIRED_FIELD: 'Required Field',
  NOTIFICATION_TIMING: {
    BANNER_DURATION:
      'Duration for Banner Notifications to persist on the screen',
    SNOOZE_DURATION: 'Snooze duration for Alert notifications',
    ALL_NOTIFICATION_DURATION:
      'Duration for all notifications to persist in the Notification Center',
    DURATION: 'Duration',
    SECONDS: 'Seconds',
    HOURS: 'Hours',
    NOTIFICATION_TIMING_SETTINGS: 'Notification Timing',
    SAVE: 'Save',
  },
  NOTIFICATION_TRIGGER_SETTINGS: 'Notification Trigger Settings',
  NO_DATA_PRESENT: 'No Data Present',
  NO_UNSAVED_CHANGES: 'No Unsaved Changes',
  FUNCTIONAL_AREA: 'Functional Area',
  NOTIFICATION_TRIGGER: 'Notification Trigger',
  NOTIFICATION_CENTER_ONLY: 'Notification Center Only',
  ALERTS_AND_NOTIFICATION_CENTER: 'Alerts and Notification Center',
  BANNERS_AND_NOTIFICATION_CENTER: 'Banners and Notification Center',
  ROLES_ASSIGNED: 'Roles Assigned',
  NOTIFICATION_TYPE: 'Notification Type',
  NOTIFICATION_CATEGORY: 'Notification Category',
  NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP_MESSAGE: {
    HEADING: 'Choose the type of custom notification you would like to create.',
    DATA_CONDITION: 'Data Condition: ',
    DATA_CONDITION_DESC:
      ' Set notifications based on specific data values/ranges across the application.',
    MODULE: 'Module: ',
    MODULE_DESC: 'Set module-specific alerts.',
  },
  DATA_CONDITION: 'Data Condition',
  MODULE: 'Module',
  ALERT: 'Alert',
  TRIGGER: 'Trigger',
  METRIC: 'Metric',
  DISPLAY_DETAILS: 'Display Details',
  RECURRENCE: 'Recurrence',
  ADDITIONAL_SETTINGS:
    // eslint-disable-next-line quotes
    "Addit'l Settings",
  MUTE: 'Mute',
  TOAST_SUCCESS_MESSAGE: 'Your changes have been successfully saved!',
  TOAST_SUCCESS_MESSAGE_MODAL:
    'Your Notification list has been successfully updated!',
  TOAST_SUCCESS_MESSAGE_ADDITIONAL_SETTINGS_MODAL:
    'Your notification trigger additional settings have been successfully updated!',
  TOAST_ERROR_MESSAGE: 'Something went wrong. Please try again later!',
  SELECT: 'Select',
  SELECT_ROLES: 'Select Roles',
  ROLES: 'Roles',
  MULTIPLE: 'Multiple',
  ALL: 'All',
  APPLY: 'Apply',
  RESET: 'Reset',
  OPERATIONAL_AREA: 'Operational Area',
  FILTER: 'Filter',
  SOC: {
    CRITICALITY_THRESHOLD: 'Criticality Threshold',
    RISK_CATEGORY_THRESHOLD: 'Risk Category Threshold',
    ASSET: {
      NAME: 'Asset',
      CRITICALITY_DESCRIPTION:
        'Set criticality level that will trigger notifications for all assets with the selected criticality and higher.',
      CRITICALITY_SCALE_DESCRIPTION:
        'Notifications will be triggered for all assets with criticality of ',
      RISK_CATEGORY_DESCRIPTION:
        'Set risk category level that will trigger notifications for all assets with the selected risk category and higher.',
      RISK_CATEGORY_SCALE_DESCRIPTION:
        'Notifications will be triggered for all assets with a risk category of ',
    },
    CELL: {
      NAME: 'Cell',
      CRITICALITY_DESCRIPTION:
        'Set criticality level that will trigger notifications for all cells with the selected criticality and higher.',
      CRITICALITY_SCALE_DESCRIPTION:
        'Notifications will be triggered for all cells with criticality of ',
      RISK_CATEGORY_DESCRIPTION:
        'Set risk category level that will trigger notifications for all cells with the selected risk category and higher.',
      RISK_CATEGORY_SCALE_DESCRIPTION:
        'Notifications will be triggered for all cells with a risk category of ',
    },
    PARAMETER: {
      NAME: 'Parameter',
      RISK_CATEGORY_DESCRIPTION:
        'Set risk category level that will trigger notifications for all parameters with the selected risk category and higher.',
      RISK_CATEGORY_SCALE_DESCRIPTION:
        'Notifications will be triggered for all parameters with a risk category of ',
    },
    AND_ABOVE: 'and above',
    RISK_CATEGORY: {
      INSIGNIFICANT: 'Insignificant',
      MINOR: 'Minor',
      MODERATE: 'Moderate',
      MAJOR: 'Major',
      CATASTROPHIC: 'Catastrophic',
    },
    CRITICALITY: {
      1: 'Manual workaround available',
      2: 'Short term shutdown with potential workaround',
      3: 'Short term shutdown requiring maintenance',
      4: 'Medium term shutdown',
      5: 'Medium term shutdown with difficult repair',
      6: 'Long term shutdown',
      7: 'Long term shutdown with difficult repair',
      8: 'Long term shutdown that immediately stops production',
    },
    RISK_CATEGORIES_STRING: 'risk categories',
    RISK_CATEGORY_STRING: 'risk category',
    RISK_CATEGORY_TOOLTIP_ALL_ENABLED:
      'The settings for this notification have been enabled for all risk categories.',
    RISK_CATEGORY_TOOLTIP_PART_1:
      'The settings for this notification have been disabled for the',
    RISK_CATEGORY_TOOLTIP_PART_2:
      'to minimize the risk of system overload due to excessive notification generation.',
  },
  HOURS: 'Hours',
  MINUTES: 'Minutes',
  SECONDS: 'Seconds',
  DURATION: 'Duration',
  HYPHEN: '-',
  TIME_UNIT_ARRAY: ['Hours', 'Minutes', 'Seconds'],
  OEE_ADDITIONAL_SETTINGS: {
    TEMP_MUTE_NOTIFICATION_MSG:
      'Temporarily Mute Notifications at the Start of the Configured Time Range',
    TEMP_MUTE_HOUR_ERROR: 'Value must be 3 hours or less',
    TEMP_MUTE_MINUTES_ERROR: 'Value must be 180 minutes or less',
    TEMP_MUTE_SECONDS_ERROR: 'Value must be 10,800 seconds or less',
  },
  FH_ADDITIONAL_SETTINGS_DESC:
    'Temporarily Mute Notifications at the Start of the Configured Time Range',
  FH_ADDITIONAL_SETTINGS_ERRORS: {
    HOURS: 'Value must be 3 hours or less',
    MINUTES: 'Value must be 180 minutes or less',
    SECONDS: 'Value must be 10800 seconds or less',
  },
  MISSING_PLANT_LEVEL_ROLE:
    'Prior to adding notifications, Plant Level roles must be created.',
  SEND: 'Send',
  DEMO_NOTIFICATION: 'Demo Notification',
  ERROR_CODE_DEMO: 'Error Code Demo',
  ERROR_CODE: 'Error Code',
  ERROR_CODE_PLACEHOLDER: 'Enter Error Code',
  DISPLAY_IN: 'Display in',
  DEMO_NOTIFICATION_TOOLTIP_MESSAGE:
    'Trigger mock notifications within intelligentOps TM',
  NOTIFICATION_TIMEFRAME: 'Notification Timeframe',
  PART_ID: 'Part-ID',
  TOAST_SUCCESS_MESSAGE_EC_MOCK_NOTIFICATION:
    'Notification has been successfully created!',
  YES: 'Yes',
  NO: 'No',
  CONFIRM: 'Confirm',
  DURATION_ERROR_MESSAGE: 'The maximum duration limit is 120 minutes.',
  DEMO_CONFIRMATION_MESSAGE_PART_A: 'A notification for error',
  DEMO_CONFIRMATION_MESSAGE_HIERARCHY: 'Notification for Equipment hierarchy',
  QUALITY_DEFECT_DEMO_CONFIRMATION_MESSAGE_PART_A:
    'A notification for quality defect code',
  QUALITY_DEFECT_DEMO_CONFIRMATION_MESSAGE_PART_ID: 'for Part-ID',
  DEMO_CONFIRMATION_MESSAGE_PART_B: 'code will be pushed in',
  QUALITY_DEFECT_MESSAGE_PART_B: 'will be pushed in',
  CONTINUE_MESSAGE: 'Do you want to continue?',
  DISABLE_DEMO_MESSAGE:
    'To send a mock notification, ensure that configuration for both Equipment Constraints and Notification Timing is complete, and at least 1 Notification Trigger for Equipment Constraints has been added.',
  QUALITY_DEFECT_DEMO: 'Quality Defect Demo',
  QUALITY_DEFECT_CODE: 'Quality Defect Code',
  QUALITY_DEFECT_CODE_PLACEHOLDER: 'Enter Quality Defect Code',
  QUALITY_DEFECT_PART_PLACEHOLDER: 'Enter Part-ID',
  SELECT_EQUIPMENT: 'Select Equipment',
  EQUIPMENT_HIERARCHY: 'Equipment Hierarchy',
  CUSTOM_NOTIFICATION_TRIGGER_TOOLTIP:
    'Identify the data trigger type to generate a notification.',
  CUSTOM_NOTIFICATION_TRIGGER_NAME: 'Notification Trigger Name',
  CUSTOM_NOTIFICATION_TRIGGER_NAME_TOOLTIP:
    'This is the system generated name for this trigger. It will be visible in Notification Configuration',
  CUSTOM_NOTIFICATION_DISPLAYED_MESSAGE: 'Displayed Message',
  CUSTOM_NOTIFICATION_DISPLAYED_MESSAGE_TOOLTIP:
    'This is the display message that a user will see in the application when notified. The suggested message is editable',
  TOAST_CUSTOM_NOTIFICATION_SAVE_SUCCESS_MESSAGE:
    'Your custom notification has been successfully saved',
  CUSTOM_NOTIFICATION_EDIT_ICON_TOOLTIP: 'Edit Custom Notification',
  CUSTOM_NOTIFICATION_DELETE_ICON_TOOLTIP: 'Delete Notification',
  DELETE_CUSTOM_NOTIFICATION_DIALOG_TITLE: 'Confirm!',
  DELETE_CUSTOM_NOTIFICATION_DIALOG_CONTENT: {
    ALERT_MESSAGE:
      'Deleting this row will delete all previously entered data for this notification.',
    CONFIRMATION_QUESTION: 'Do you want to delete this row?',
  },
};

export default MESSAGE_STRINGS;
