export const parseNotificationTimingToBackendFormat = (body) => {
  return {
    ...body,
    durationBanner: +body.durationBanner,
    durationCenter: +body.durationCenter,
    snoozeDuration: +body.snoozeDuration,
    // Required placeholder requested by BE
    factoryId: 'aa',
    //
  };
};
