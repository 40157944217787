import { TextField as MuiTextField, styled } from '@mui/material';

export const CustomTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== 'textAlign',
})(({ theme, textAlign, applyLayoutBackgroundColor, error }) => ({
  '& label.Mui-focused': {
    color: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    color: theme.palette.background.shuttleGray,
    WebkitTextFillColor: theme.palette.background.shuttleGray,
    cursor: 'not-allowed',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0.4rem 0 0.4rem 0.7rem',
    fontSize: '0.875rem',
    fontWeight: 400,
    textAlign,
    color: theme.palette.text.primary,
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  '& .MuiTypography-colorTextSecondary': {
    color: theme.palette.text.primary,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: theme.palette.text.errorText,
    fontSize: '0.75rem',
    marginLeft: '0.2rem',
    minWidth: '100%',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: applyLayoutBackgroundColor
      ? theme.palette.background.layoutBackgroundColor
      : theme.palette.background.blackGrey,
    height: '2rem',
    paddingRight: 0,
    '& fieldset': {
      borderWidth: '0.0625rem',
      borderColor: theme.palette.border.lightWhiteGrey,
    },
    '&:hover fieldset': {
      borderWidth: '0.0625rem',
      borderColor: theme.palette.border.primary,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '0.0625rem',
      borderColor: error
        ? theme.palette.text.errorText
        : theme.palette.selected.greenSelect,
    },
    '&.Mui-disabled fieldset': {
      borderWidth: '0.0625rem',
      borderColor: theme.palette.border.lightWhiteGrey,
    },
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.text.primary,
    marginLeft: '0.2rem',
  },
  '& .MuiInputAdornment-root .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
}));
