import { DisabledTableCell } from './style.js';
import { Box, Typography } from '@mui/material';

const DataCell = ({ ...cellProps }) => {
  const { isEnabled } = cellProps.row.original;
  const { value } = cellProps;
  return (
    <Box>
      {isEnabled ? (
        <Typography>{value}</Typography>
      ) : (
        <DisabledTableCell>{value}</DisabledTableCell>
      )}
    </Box>
  );
};

export default DataCell;
