import { TableContainer, TableHead, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer, {
  shouldForwardProp: (prop) => prop !== 'hideBorder',
})(({ theme, hideBorder }) => ({
  border: `.063rem solid ${theme.customColors.wrapperGrey}`,
  borderLeftWidth: hideBorder ? 0 : '',
  borderRightWidth: hideBorder ? 0 : '',
  borderRadius: '0.25rem',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .basicGeneralTable_headerRow .MuiTableCell-head': {
    color: theme.palette.text.lightUnitGrey,
    backgroundColor: theme.customColors.blackishGrey,
    height: '3.5rem',
    gap: '0.5rem',
    borderTop: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: '0',
  borderColor: theme.palette.border.darkGrey,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  height: '2.5rem',
  padding: '0.25rem 0.5rem',
  borderTop: `.063rem solid ${theme.customColors.wrapperGrey}`,
  borderBottom: 0,
  color: theme.palette.text.primary,
  lineHeight: '1.25rem',
  '&.MuiTableCell-root': {
    '.MuiTypography-root': {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  },
}));

const StyledTableBodyRow = styled(TableRow)(({ theme }) => ({
  background: theme.palette.background.blackGrey,
  '&.general-table-missing-row': {
    border: '.06rem solid transparent',
  },
}));

export {
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableBodyRow,
};
