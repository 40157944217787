import { Tooltip, styled, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, isCustomTooltip, isFromAddRemoveToggleCell }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: '0.75rem',
      color: theme.palette.text.lightYellowishGray,
      backgroundColor: theme.palette.background.eerieBlack,
      boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
      border: `1px solid ${theme.customColors.metalGrey}`,
      borderRadius: '0.25rem',
      marginBottom: '0rem',
      marginLeft: isFromAddRemoveToggleCell ? '-5.4rem' : '',
      padding: isCustomTooltip ? '0rem' : '0.625rem 0.75rem',
      minWidth: !isFromAddRemoveToggleCell ? '15.938rem' : '',
      lineHeight: '1.021rem',
      fontWeight: '400',
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&::before': {
        backgroundColor: theme.palette.background.eerieBlack,
      },
    },
  };
});

export { StyledTooltip };
