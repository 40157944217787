import PropTypes from 'prop-types';
import InfoIcon from '../../assets/img/infoIcon.svg';
import {
  DropdownLabelAndTooltipWrapper,
  DropdownLabelTypography,
  ToolTipIconBox,
} from './style';
import CustomTooltip from '../CustomTooltip';
import NotificationTypeCustomToolTip from '../NotificationTypeCustomToolTip';
import { TEST_IDS } from '../../constants';

const DropdownLabel = ({
  label = '',
  isTooltip = false,
  tooltipMessage = '',
  isNotificationTypeTooltip = false,
}) => {
  return (
    <DropdownLabelAndTooltipWrapper>
      <DropdownLabelTypography>{label}</DropdownLabelTypography>
      {isTooltip && (
        <CustomTooltip
          title={
            !isNotificationTypeTooltip ? (
              tooltipMessage
            ) : (
              <NotificationTypeCustomToolTip />
            )
          }
          placement="top-start"
          data-testid={TEST_IDS.TOOLTIP_ICON}
          isCustomTooltip={isNotificationTypeTooltip}
        >
          <ToolTipIconBox>
            <InfoIcon alt="info-icon" height="1rem" width="1rem" />
          </ToolTipIconBox>
        </CustomTooltip>
      )}
    </DropdownLabelAndTooltipWrapper>
  );
};

DropdownLabel.propTypes = {
  label: PropTypes.string,
  isTooltip: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  isNotificationTypeTooltip: PropTypes.bool,
};

export default DropdownLabel;
