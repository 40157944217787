import { useState, useEffect } from 'react';
import MESSAGE_STRINGS from '../../../constants/en-us';
import SMFSelect from '../../../components/SMFSelect';
import SMFSwitch from '../../../components/SMFSwitch';
import { MenuItem } from '@mui/material';
import {
  useTriggerSettingsContext,
  updateRecurrence,
} from '../../../context/triggerSettingsContext';
import { DEFAULT_RECURRENCE_VALUE } from '../../../constants';
import {
  Container,
  DurationLabel,
  ValueSelectBox,
  RecurrenceValueBox,
  ValueLabel,
  UnitLabel,
  EmptyValueLabel,
} from './style.js';

const Recurrence = ({
  triggerId,
  isRecurrence = false,
  selectedRecurrenceValue = '',
  isEditing,
  isToggleEnabled,
}) => {
  const { triggerSettingsDispatch } = useTriggerSettingsContext();
  const [isChecked, setIsChecked] = useState(false); // ? Recurrence toggle switch state
  const [recurrenceValue, setRecurrenceValue] = useState(''); // ? Recurrence minute value state

  const dropdownOptions = Array.from({ length: 11 }, (v, i) => (i + 1) * 5);
  const isEnabled = isToggleEnabled && isChecked;

  useEffect(() => {
    setRecurrenceValue(selectedRecurrenceValue);
  }, [selectedRecurrenceValue]);

  useEffect(() => {
    setIsChecked(isRecurrence);
  }, [isRecurrence]);

  const handleSelectChange = (event) => {
    setRecurrenceValue(event.target.value);
    updateRecurrence(
      triggerSettingsDispatch,
      triggerId,
      isChecked,
      event.target.value,
      MESSAGE_STRINGS.MINUTES
    );
  };

  const handleToggleChange = (event) => {
    const checkedValue = event.target.checked;
    setIsChecked(checkedValue);
    // Set default recurrence value
    if (!selectedRecurrenceValue) {
      setRecurrenceValue(checkedValue ? DEFAULT_RECURRENCE_VALUE : '');
      updateRecurrence(
        triggerSettingsDispatch,
        triggerId,
        checkedValue,
        checkedValue ? DEFAULT_RECURRENCE_VALUE : '',
        MESSAGE_STRINGS.MINUTES
      );
    } else {
      updateRecurrence(
        triggerSettingsDispatch,
        triggerId,
        checkedValue,
        recurrenceValue,
        MESSAGE_STRINGS.MINUTES
      );
    }
  };

  const getSelectValue = () => {
    let selectValue = '';
    if (selectedRecurrenceValue) {
      selectValue = isEnabled ? recurrenceValue : MESSAGE_STRINGS.HYPHEN;
    } else {
      selectValue = MESSAGE_STRINGS.HYPHEN;
    }
    return selectValue;
  };

  return (
    <>
      <Container>
        <DurationLabel>{MESSAGE_STRINGS.DURATION}</DurationLabel>
        <SMFSwitch
          checked={isEnabled}
          onChange={handleToggleChange}
          disabled={!(isEditing && isToggleEnabled)}
          inputProps={{ 'data-testid': 'recurrence-switch' }}
        />
        {isEditing ? (
          <Container>
            {isToggleEnabled ? (
              <ValueSelectBox>
                <SMFSelect
                  value={getSelectValue()}
                  data-testid="recurrence-value-select"
                  placeholderText={MESSAGE_STRINGS.HYPHEN}
                  disabled={!isEnabled}
                  onChange={handleSelectChange}
                  width="4.8rem"
                >
                  {dropdownOptions.map((item) => {
                    return (
                      <MenuItem
                        value={item}
                        key={`option--${item}`}
                        data-testid={`option--${item}`}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </SMFSelect>
              </ValueSelectBox>
            ) : (
              <EmptyValueLabel>{MESSAGE_STRINGS.HYPHEN}</EmptyValueLabel>
            )}
            <UnitLabel>{MESSAGE_STRINGS.MINUTES}</UnitLabel>
          </Container>
        ) : (
          <RecurrenceValueBox>
            <ValueLabel isEmpty={!isEnabled} data-testid="recurrence-value">
              {isEnabled ? recurrenceValue : MESSAGE_STRINGS.HYPHEN}
            </ValueLabel>
            <UnitLabel isEmpty={!isEnabled}>
              {isEnabled ? MESSAGE_STRINGS.MINUTES : MESSAGE_STRINGS.HYPHEN}
            </UnitLabel>
          </RecurrenceValueBox>
        )}
      </Container>
    </>
  );
};

export default Recurrence;
