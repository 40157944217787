import { alpha, styled, Tooltip, tooltipClasses } from '@mui/material';

const CustomizedTooltip = styled(
  ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'textSize' && prop !== 'maxWidth' && prop !== 'isBorderEnabled',
  }
)(
  ({
    theme,
    maxWidth: toolTipMaxWidth,
    textSize,
    background,
    isBorderEnabled,
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: background ?? theme.palette.background.eerieBlack,
      maxWidth: toolTipMaxWidth,
      fontSize: textSize,
      lineHeight: '1rem',
      fontWeight: 400,
      boxShadow: `0 0.25rem 0.25rem ${alpha(theme.customColors.black, 0.25)}`,
      borderRadius: '0.25em',
      padding: '.75rem',
      color: theme.palette.text.lightYellowishGray,
      border: isBorderEnabled
        ? `.0625rem solid ${theme.palette.background.metalGrey}`
        : 'none',
    },
    [`& .${tooltipClasses.arrow}`]: {
      fontSize: 30,
      color: background ?? theme.palette.background.eerieBlack,
    },
  })
);

export { CustomizedTooltip };
