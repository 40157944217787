import {
  Typography,
  Box,
  Button,
  Divider,
  TextField,
  styled,
} from '@mui/material';

const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
}));

const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isMainTitle',
})(({ theme, isMainTitle = false }) => {
  let fontSize = '0.875rem';
  let lineHeight = '1.192rem';
  if (isMainTitle) {
    fontSize = '1rem';
    lineHeight = '1.25rem';
  }
  return {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: fontSize,
    lineHeight: lineHeight,
    color: theme.palette.text.primary,
  };
});

const ResetButton = styled(Button)(({ theme }) => ({
  color: theme.customColors.white,
  backgroundColor: 'transparent',
  width: '107px',
  height: '32px',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.border.primary}`,
  textTransform: 'none',
  '&.MuiButton-root:hover': {
    color: theme.customColors.white,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.border.primary}`,
  },
}));

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showSearchBar',
})(({ theme, showSearchBar = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: showSearchBar ? '25rem' : '20rem',
  width: '17rem',
  border: `1px solid ${theme.customColors.popoverBorder}`,
  borderRadius: '0.25rem',
  background: theme.palette.background.layoutBackgroundColor,
  boxShadow: `0px 0.25rem 0.5rem ${theme.palette.border.popoverGrey}`,
  padding: '1.125rem 1rem 1rem',
}));

const ButtonLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isWhite',
})(({ theme, isWhite = false }) => {
  let color = theme.palette.text.secondary;
  if (isWhite) {
    color = theme.palette.text.primary;
  }
  return {
    fontFamily: 'Open Sans SemiBold',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    color: color,
  };
});

const ApplyButton = styled(Button)(({ theme }) => ({
  color: theme.customColors.black,
  backgroundColor: theme.customColors.saveGreen,
  width: '107px',
  height: '32px',
  borderRadius: '20px',
  textTransform: 'none',
  fontFamily: 'Open Sans SemiBold',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '20px',
  '&.MuiButton-root:hover': {
    color: theme.customColors.white,
    border: `1px solid ${theme.customColors.saveGreen}`,
    borderRadius: '20px',
  },
}));

const CheckboxLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '19px',
  color: theme.palette.text.primary,
}));

const BadgeLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: '700',
  color: theme.palette.background.configLighterDark,
}));

const StyledDivider = styled(Divider, {
  shouldForwardProp: (prop) => prop !== 'isTopDivider',
})(({ theme, isTopDivider = false }) => {
  let margin = '18.5px 0px 16px';
  if (isTopDivider) {
    margin = '0.94rem 0px 0.813rem';
  }
  return {
    margin: margin,
    borderBottom: `1px solid ${theme.palette.border.darkGrey}`,
  };
});

const SearchBar = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ theme, error }) => ({
  '.MuiInputBase-input': {
    height: '1.69rem',
    padding: '0.25rem 0px 0.25rem 0.625rem',
  },
  '.MuiFilledInput-root, .MuiFilledInput-root.Mui-focused': {
    backgroundColor: 'transparent',
  },
  '.MuiInputBase-root': {
    borderStyle: 'solid',
    borderWidth: '0.063rem',
    borderColor: error
      ? theme.palette.border.lavaRed
      : theme.palette.border.lightWhiteGrey,
    borderRadius: '0.25rem',
    paddingLeft: 0,
    paddingRight: '0.5rem',

    '&:hover': {
      borderColor: error
        ? theme.palette.border.lavaRed
        : theme.palette.border.primary,
      backgroundColor: 'transparent',
    },

    '&.Mui-focused': {
      borderColor: error
        ? theme.palette.border.lavaRed
        : theme.customColors.saveGreen,
    },
  },
}));

const UncheckedBoxIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  backgroundColor: 'transparent',
  border: `.063em solid ${theme.palette.background.default}`,
  '$root.Mui-focusVisible &': {
    outline: 'none',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
  },
}));

const CheckBoxContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const BoldText = styled('span')(() => ({
  fontWeight: 700,
}));

export {
  ButtonsContainer,
  Title,
  ResetButton,
  Container,
  ButtonLabel,
  ApplyButton,
  CheckboxLabel,
  BadgeLabel,
  StyledDivider,
  SearchBar,
  UncheckedBoxIcon,
  CheckBoxContainer,
  BoldText,
};
