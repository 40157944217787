import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(() => ({
  padding: '0.625rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  border: '0.0625rem solid rgba(83, 86, 90, 0.3)',
  borderTop: 'none',
  alignItems: 'baseline',
}));

const ConfigContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
}));

const Description = styled(Typography)(() => ({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
}));

const ControlsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const InputContainer = styled(Box)(() => ({
  marginRight: '0.688rem',
  width: '3.1rem',
}));

const DurationLabel = styled(Typography)(() => ({
  marginRight: '1.313rem',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
}));

const SelectContainer = styled(Box)(() => ({
  width: '7.25rem',
}));

const ErrorContainer = styled(Box)(() => ({
  minHeight: '3.5rem',
  marginRight: 'auto',
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '9rem',
}));

const FlexContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export {
  Container,
  ConfigContainer,
  Description,
  DurationLabel,
  ControlsContainer,
  InputContainer,
  SelectContainer,
  ErrorContainer,
  FlexContainer,
};
