import React from 'react';
import PropTypes from 'prop-types';

import { CustomTreeItem } from '../RenderTreeData/style';
import RadioInput from '../RadioInput';
import { renderTreeNodes } from '../RenderTreeData';

const CustomTreeItemWithIcon = React.forwardRef(function StyledTreeItem(
  props,
  ref
) {
  const { labelText, node, handleHierarchy, ...other } = props;

  return (
    <CustomTreeItem
      label={
        <RadioInput
          label={labelText}
          value={node.entityHierarchy}
          callback={() => handleHierarchy(node)}
          {...other}
        />
      }
      {...other}
      ref={ref}
    >
      {node?.entityChildren &&
        renderTreeNodes(node?.entityChildren, handleHierarchy)}
    </CustomTreeItem>
  );
});

CustomTreeItemWithIcon.propTypes = {
  labelText: PropTypes.string,
  node: PropTypes.shape({
    entityId: PropTypes.number,
    entityNumber: PropTypes.string,
    entityType: PropTypes.string,
    parentEntityId: PropTypes.number,
    entityName: PropTypes.string,
    entityHierarchy: PropTypes.string,
    hierarchyLevel: PropTypes.number,
    entityChildren: PropTypes.instanceOf(Array),
    entityAutoId: PropTypes.number,
    fileStatus: PropTypes.string,
  }),
  handleHierarchy: PropTypes.func,
};
export default CustomTreeItemWithIcon;
