import { React, useState, useRef, useMemo, useEffect } from 'react';
import { GeneralDialog } from '../../components/GeneralDialog';
import ModalBody from '../../components/GeneralDialog/GeneralDialogBody';
import PropTypes from 'prop-types';
import MESSAGE_STRINGS from '../../constants/en-us';
import TriggersListTable from '../AddRemoveNotificationModal/TriggersListTable';
import { useTriggerSettingsContext } from '../../context/triggerSettingsContext';
import { cloneDeep } from 'lodash';
import { useToastContext } from '../../context/toastContext';
import {
  REDUCER_DISPATCH_ACTIONS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { getAvailableFilterIds } from '../TriggerSettings/helper';
import { getFilteredAllTriggersList } from './helper';
import Actions from '../../components/ModalActions';
import { DeleteCustomNotificationModal } from './DeleteCustomNotificationModal';

const AddRemoveNotificationsModal = ({
  open,
  handleCustomNotificationModalOpen,
  handleCustomNotificationEditIconClick,
  refetchAllTriggers,
  refetchSavedTriggers,
  customNotificationFeature,
}) => {
  const {
    triggerSettingsState: {
      isTriggersDataLoading,
      editedTriggersList: allTriggersData,
      availableModalFunctionalAreaFilters: availableFunctionalAreaFilters,
      selectedModalFunctionalAreaFilters: selectedFunctionalAreaFilters,
      availableIsActiveFilters,
      selectedIsActiveFilters,
    },
    triggerSettingsDispatch,
  } = useTriggerSettingsContext();
  const { toastDispatch } = useToastContext();

  // Functional Area filters
  const selectedFAFilters = useMemo(() => {
    return selectedFunctionalAreaFilters?.length > 0
      ? selectedFunctionalAreaFilters
      : getAvailableFilterIds(availableFunctionalAreaFilters);
  }, [selectedFunctionalAreaFilters, availableFunctionalAreaFilters]);

  // Is Active filters
  const selectedIAFilters = useMemo(() => {
    const filters =
      selectedIsActiveFilters?.length > 0
        ? selectedIsActiveFilters
        : getAvailableFilterIds(availableIsActiveFilters);
    const updatedFilters = filters.map((id) => Boolean(id));
    return updatedFilters;
  }, [selectedIsActiveFilters, availableIsActiveFilters]);

  let triggersToBeUpdated = useRef(cloneDeep(allTriggersData));

  const getTriggersToBeUpdated = (triggerId, isChecked) => {
    const index = triggersToBeUpdated.current.findIndex(
      (trigger) => trigger.triggerId === triggerId
    );

    triggersToBeUpdated.current[index].isActive = isChecked;
    triggersToBeUpdated.current[index].isEdited = true;

    //isNew flag is also used to check if trigger needs to be reset on deactivate
    triggersToBeUpdated.current[index].isNew = true;
  };

  const handleUpdate = () => {
    triggerSettingsDispatch({
      type: REDUCER_DISPATCH_ACTIONS.UPDATE_NOTIFICATIONS,
      payload: { triggersToBeUpdated: triggersToBeUpdated.current },
    });
    toastDispatch({
      type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
      payload: {
        message: MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE_MODAL,
      },
    });
  };

  const handleCancel = () => {
    triggerSettingsDispatch({
      type: REDUCER_DISPATCH_ACTIONS.RESET_ADD_REMOVE_MODAL,
    });
  };

  useEffect(() => {
    triggersToBeUpdated.current = cloneDeep(allTriggersData);
  }, [allTriggersData]);

  const [
    showDeleteCustomNotificationModal,
    setShowDeleteCustomNotificationModal,
  ] = useState(false);
  const [triggerIdToBeDeleted, setTriggerIdToBeDeleted] = useState(null);

  const handleCustomNotificationDeleteIconClick = (triggerId) => {
    setTriggerIdToBeDeleted(triggerId);
    setShowDeleteCustomNotificationModal(true);
  };

  return (
    <GeneralDialog
      open={open}
      fullWidth={false}
      sx={{
        '& .dialog-title': { fontWeight: '700' },
      }}
      isNotificationsModal={true}
    >
      <ModalBody
        isFlex
        dialogTitle={MESSAGE_STRINGS.ADD_NOTIFICATIONS}
        dialogContent={
          <>
            <TriggersListTable
              isDataLoading={isTriggersDataLoading}
              triggersData={getFilteredAllTriggersList(
                triggersToBeUpdated.current,
                selectedFAFilters,
                selectedIAFilters,
                customNotificationFeature
              )}
              triggerSettingsDispatch={triggerSettingsDispatch}
              getTriggersToBeUpdated={getTriggersToBeUpdated}
              handleCustomNotificationEditIconClick={
                handleCustomNotificationEditIconClick
              }
              handleCustomNotificationDeleteIconClick={
                handleCustomNotificationDeleteIconClick
              }
            />
            {showDeleteCustomNotificationModal && (
              <DeleteCustomNotificationModal
                triggerId={triggerIdToBeDeleted}
                open={showDeleteCustomNotificationModal}
                closeModal={() => setShowDeleteCustomNotificationModal(false)}
                refetchAllTriggers={refetchAllTriggers}
                refetchSavedTriggers={refetchSavedTriggers}
              />
            )}
          </>
        }
        minWidth="60.5rem"
        maxWidth="66.5rem"
        actions={
          <Actions
            confirmText={MESSAGE_STRINGS.UPDATE}
            cancelText={MESSAGE_STRINGS.CANCEL}
            handleSave={handleUpdate}
            handleClose={handleCancel}
            isCustomNotification={true}
            handleCustomNotificationModalOpen={
              handleCustomNotificationModalOpen
            }
            customNotificationFeature={customNotificationFeature}
          />
        }
      ></ModalBody>
    </GeneralDialog>
  );
};

AddRemoveNotificationsModal.propTypes = {
  open: PropTypes.bool,
  handleCustomNotificationModalOpen: PropTypes.func,
  handleCustomNotificationEditIconClick: PropTypes.func,
  refetchAllTriggers: PropTypes.func,
  refetchSavedTriggers: PropTypes.func,
  customNotificationFeature: PropTypes.string,
};

export default AddRemoveNotificationsModal;
