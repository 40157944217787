import React from 'react';
import { Box, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const CustomBox = styled(Box)(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
}));

const LoadingIndicator = ({ size, ...other }) => (
  <CustomBox>
    <CircularProgress color="inherit" size={size} {...other} />
  </CustomBox>
);

LoadingIndicator.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
LoadingIndicator.defaultProps = {
  size: 40,
};
export default LoadingIndicator;
