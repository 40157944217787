import { useMemo, useState, useEffect } from 'react';
import {
  displaySelectedRoles,
  getSelectedRoleIds,
  formatSelectedRoleIds,
  getRolesTooltipText,
} from '../helper';
import {
  updateRolesAssigned,
  useTriggerSettingsContext,
} from '../../../context/triggerSettingsContext';
import MultiSelectDropdownFilter from '../../../components/MultiSelectDropdownFilter';
import SMFTooltip from '../../../components/SMFTooltip';
import PseduoSelect from '../../../components/PseudoSelect';
import MESSAGE_STRINGS from '../../../constants/en-us';
import { FILTER_NAMES } from '../../../constants/index';
import { theme as muiTheme } from '@smf/ui-util-app';
import { RoleTypography } from './style';

const RolesAssigned = ({
  triggerId,
  rolesAssignedError = false,
  selectedRoles,
  availableRoles,
  isEditing,
}) => {
  const [selectedRoleValues, setSelectedRoleValues] = useState([]);
  const { triggerSettingsDispatch } = useTriggerSettingsContext();
  const [open, setOpen] = useState(false);

  const dropdownOptions = useMemo(() => {
    if (!availableRoles || availableRoles.length === 0) return [];
    let mergedList = [
      {
        id: 'all',
        value: 'all',
        label: MESSAGE_STRINGS.ALL,
        isSelected: false,
      },
    ];
    availableRoles?.forEach((item) => {
      const isSelected = selectedRoles?.find(
        (role) => role.roleId === item.roleId
      );
      mergedList.push({
        id: item.roleId,
        value: item.roleId,
        label: item.roleName,
        isSelected,
      });
    });
    return mergedList;
  }, [availableRoles, selectedRoles]);

  useEffect(() => {
    if (dropdownOptions && dropdownOptions.length) {
      const selectedRoles = dropdownOptions.filter((role) => role.isSelected);
      setSelectedRoleValues(getSelectedRoleIds(selectedRoles));
    }
  }, [dropdownOptions]);

  const handleChange = (selectedRoleIds, action) => {
    setOpen(false);
    if (action === 'apply') {
      setSelectedRoleValues(selectedRoleIds);
      updateRolesAssigned(
        triggerSettingsDispatch,
        triggerId,
        formatSelectedRoleIds(selectedRoleIds)
      );
    }
  };

  return (
    <>
      {isEditing ? (
        <PseduoSelect
          value={displaySelectedRoles(dropdownOptions, isEditing)}
          label={displaySelectedRoles(dropdownOptions, isEditing)}
          error={rolesAssignedError}
          placeholder={MESSAGE_STRINGS.SELECT}
          open={open}
          onOpen={() => setOpen(true)}
          handleOnClose={() => {
            setOpen(false);
          }}
          width="12.5rem"
        >
          <MultiSelectDropdownFilter
            popOverTitle={MESSAGE_STRINGS.SELECT}
            filterProps={{
              filtersList: dropdownOptions,
              filtersName: FILTER_NAMES.ROLES_ASSIGNED,
            }}
            itemDropDownValues={selectedRoleValues}
            onChangeFunc={handleChange}
            showSearchBar={true}
            showFilterName={true}
            filtersByName={MESSAGE_STRINGS.ROLES_ASSIGNED}
          />
        </PseduoSelect>
      ) : (
        <SMFTooltip
          message={getRolesTooltipText(
            selectedRoleValues,
            dropdownOptions,
            isEditing
          )}
          placement="top-start"
          background={muiTheme.palette.background.dullDark}
          isBorderEnabled={true}
        >
          <RoleTypography data-testid="role-names">
            {displaySelectedRoles(dropdownOptions, isEditing)}
          </RoleTypography>
        </SMFTooltip>
      )}
    </>
  );
};

export default RolesAssigned;
