import { Typography } from '@mui/material';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import EmptyActiveTriggers from '../EmptyActiveTriggers';
import RolesAssigned from '../RolesAssigned';
import NotificationType from '../NotificationType';
import SMFTable from '../../../components/SMFTable';
import FunctionalArea from '../FunctionalArea';
import { useTriggerSettingsContext } from '../../../context/triggerSettingsContext';
import Mute from '../Mute';
import AdditionalSettingsButton from '../AdditionalSettings/AdditionalSettingsButton';
import AdditionalSettingsModal from '../AdditionalSettings/AdditionalSetingsModal';
import Recurrence from '../Recurrence';
import {
  getFilteredActiveListSortedByEdit,
  getAvailableFilterIds,
} from '../helper';
import MESSAGE_STRINGS from '../../../constants/en-us';
import { FEATURE_FLAG_TREATMENTS } from '../../../constants';
import { TableContainer } from './style';

const ActiveTriggers = ({
  addTriggersHandler,
  isEditing,
  isSaving,
  availableRoles,
  isRolesDataLoading,
  customNotificationFeature = FEATURE_FLAG_TREATMENTS.ON,
}) => {
  const {
    triggerSettingsState: {
      isTriggersDataLoading,
      editedTriggersList,
      selectedOperationalAreaFilters,
      availableOperationalAreaFilters,
      showAdditionalSettingsModal,
      additionalSettingsRowData,
    },
  } = useTriggerSettingsContext();

  // Set selected filters to available filters if selected filters array is empty to display all/unfiltered data.
  const selectedOAFilters = useMemo(() => {
    return selectedOperationalAreaFilters?.length > 0
      ? selectedOperationalAreaFilters
      : getAvailableFilterIds(availableOperationalAreaFilters);
  }, [selectedOperationalAreaFilters, availableOperationalAreaFilters]);

  const columns = useMemo(
    () => [
      {
        Header: <Typography>{MESSAGE_STRINGS.FUNCTIONAL_AREA}</Typography>,
        accessor: 'functionalAreaName',
        width: '17%',
        Cell: (cellProps) => <FunctionalArea {...cellProps} />,
      },
      {
        Header: <Typography>{MESSAGE_STRINGS.NOTIFICATION_TRIGGER}</Typography>,
        accessor: 'triggerName',
        width: '26%',
      },
      {
        Header: <Typography>{MESSAGE_STRINGS.ROLES_ASSIGNED}</Typography>,
        accessor: 'roles',
        Cell: ({ ...cellProps }) => {
          const {
            value,
            row: { original },
          } = cellProps;
          return (
            <RolesAssigned
              triggerId={original.triggerId}
              selectedRoles={value || []}
              availableRoles={availableRoles || []}
              isEditing={isEditing}
              rolesAssignedError={original.rolesAssignedError}
              data-testid="roles-assigned"
            />
          );
        },
        width: '16%',
      },
      {
        Header: <Typography>{MESSAGE_STRINGS.NOTIFICATION_TYPE}</Typography>,
        accessor: 'notificationType',
        Cell: ({ ...cellProps }) => {
          const {
            value,
            row: { original },
          } = cellProps;
          return (
            <NotificationType
              triggerId={original.triggerId}
              selectedType={value}
              isEditing={isEditing}
              notificationTypeError={original.notificationTypeError}
              data-testid="notification-type"
            />
          );
        },
        width: '12%',
      },
      {
        Header: <Typography>{MESSAGE_STRINGS.RECURRENCE}</Typography>,
        accessor: 'recurrence',
        Cell: ({ ...cellProps }) => {
          const {
            value,
            row: { original },
          } = cellProps;
          return (
            <Recurrence
              triggerId={original.triggerId}
              isRecurrence={value}
              selectedRecurrenceValue={original.recurrenceValue}
              isEditing={isEditing}
              isToggleEnabled={
                (original.notificationType &&
                  original.notificationType !==
                    MESSAGE_STRINGS.NOTIFICATION_CENTER_ONLY) ||
                false
              }
              data-testid="recurrence"
            />
          );
        },
        width: '15%',
      },
      {
        Header: <Typography>{MESSAGE_STRINGS.ADDITIONAL_SETTINGS}</Typography>,
        accessor: 'addlSettingsActive',
        Cell: ({ ...cellProps }) => {
          const {
            value,
            row: { original },
          } = cellProps;
          return (
            <AdditionalSettingsButton
              isAddlSettingsActive={value}
              trigger={original}
              isEditing={isEditing}
            />
          );
        },
        width: '9%',
      },
      {
        Header: <Typography>{MESSAGE_STRINGS.MUTE}</Typography>,
        accessor: 'isMute',
        Cell: ({ ...cellProps }) => {
          const {
            value,
            row: { original },
          } = cellProps;
          return (
            <Mute
              value={value}
              triggerId={original.triggerId}
              isEditing={isEditing}
            />
          );
        },
        width: '5%',
      },
    ],
    [availableRoles, isEditing]
  );

  const EmptyComponentWrapper = () => {
    return <EmptyActiveTriggers addTriggersHandler={addTriggersHandler} />;
  };

  return (
    <TableContainer isEditing={isEditing}>
      <SMFTable
        data={getFilteredActiveListSortedByEdit(
          editedTriggersList,
          selectedOAFilters,
          customNotificationFeature
        )}
        columns={columns}
        isLoading={isTriggersDataLoading || isRolesDataLoading || isSaving}
        EmptyComponent={EmptyComponentWrapper}
        hideBorder={true}
      />

      {showAdditionalSettingsModal && (
        <AdditionalSettingsModal
          open={showAdditionalSettingsModal}
          data={additionalSettingsRowData}
        />
      )}
    </TableContainer>
  );
};
ActiveTriggers.propTypes = {
  addTriggersHandler: PropTypes.func,
  isEditing: PropTypes.bool,
  isSaving: PropTypes.bool,
  availableRoles: PropTypes.array,
  isRolesDataLoading: PropTypes.bool,
  customNotificationFeature: PropTypes.string,
};

export default ActiveTriggers;
