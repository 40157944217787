import { Box, ButtonBase, styled } from '@mui/material';

const AddCustomNotificationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});
const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledButtonBase = styled(ButtonBase)({
  backgroundColor: '#202020',
  padding: '0.313rem',
  borderRadius: '0.125rem',
  gap: '0.5rem',
});

export { FlexBox, StyledButtonBase, AddCustomNotificationWrapper };
