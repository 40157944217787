import { Autocomplete, Paper, alpha, styled } from '@mui/material';
export const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
};
const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.layoutBackgroundColor,
  '.Mui-focused': {
    border: `0.0625rem solid ${theme.palette.border.saveGreen}`,
  },
  '& .MuiOutlinedInput-root': {
    height: '2rem',
    alignContent: 'center',
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '.MuiAutocomplete-endAdornment': {
    right: '1rem !important',
  },
  ':active': {
    border: `1px solid ${theme.palette.border.lightWhiteGrey}`,
  },
  '& .Mui-disabled': {
    border: `1px solid ${alpha(theme.palette.border.lightWhiteGrey, 0.2)}`,
    input: {
      border: 'none',
      color: `${theme.palette.text.darkGrey}`,
      '-webkit-text-fill-color': 'unset',
    },
    button: {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    ...typographyStyles,
    fontSize: '0.875rem',
    lineHeight: '1.192rem',
    padding: '0rem',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    // eslint-disable-next-line quotes
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: `${theme.palette.selected.darkGrayishBlue}`,
      '&.Mui-focused': {
        backgroundColor: `${theme.palette.selected.darkGrayishBlue}`,
      },
    },
  },
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
    backgroundColor: `${theme.palette.selected.darkGrayishBlue}`,
  },
  fontSize: '0.875rem',
  lineHeight: '1.192rem',
  fontWeight: 400,
  backgroundColor: theme.palette.background.paper,
}));

export { StyledAutocomplete, StyledPaper };
