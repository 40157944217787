import { ContainerBox, NewRowIndicatorBox } from './style.js';
import { Typography } from '@mui/material';
import NewRowIndicator from '../../../assets/img/newRowIndicator.svg';

const FunctionalArea = ({ row, value }) => {
  const { isNew } = row.original;
  return (
    <ContainerBox>
      {isNew && (
        <NewRowIndicatorBox data-testid="blue-dot">
          <NewRowIndicator />
        </NewRowIndicatorBox>
      )}
      <Typography>{value}</Typography>
    </ContainerBox>
  );
};

export default FunctionalArea;
