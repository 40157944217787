import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { FEATURE_FLAGS } from '@smf/ui-util-app';
import AddRemoveNotificationsModal from '../AddRemoveNotificationModal';
import MESSAGE_STRINGS from '../../constants/en-us';
import SMFButton from '../../components/GeneralButton';
import SMFAccordion from '../../components/SMFAccordion';
import SMFTooltip from '../../components/SMFTooltip';
import {
  ActionsBox,
  ButtonsContainer,
  FilterContainer,
  FilterName,
  SaveButtonContainer,
  InfoIcon,
  NotificationConfigAccordionTitle,
} from './style.js';
import ActiveTriggers from './ActiveTriggers';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { validateTriggersEmptyFields, formatNotificationsList } from './helper';
import { useTriggers } from './useTriggers';
import {
  loadTriggerSettings,
  validateSave,
  useTriggerSettingsContext,
} from '../../context/triggerSettingsContext';
import { useToastContext } from '../../context/toastContext';
import {
  REDUCER_DISPATCH_ACTIONS,
  QUERY_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
  FILTER_NAMES,
} from '../../constants';
import {
  saveTriggersForPlant,
  getAllRolesForPlant,
} from '../../utils/apiHelper';
import FilterPopover from '../../components/FilterPopover';
import MultiSelectDropdownFilter from '../../components/MultiSelectDropdownFilter';
import { Typography } from '@mui/material';
import { CustomNotificationModal } from '../AddRemoveNotificationModal/CustomNotificationModal/index.jsx';

const TriggerSettings = ({ setActiveTriggersData }) => {
  const { rxjsState } = useRxjsState();
  const { plantId } = rxjsState;
  const { triggerSettingsState, triggerSettingsDispatch } =
    useTriggerSettingsContext();
  const {
    showAddRemoveNotificationsModal,
    isEditingActiveTriggers,
    editedTriggersList,
    emptyRoleFields,
    emptyTypeFields,
    availableOperationalAreaFilters,
    selectedOperationalAreaFilters,
  } = triggerSettingsState;

  const { toastDispatch } = useToastContext();
  const {
    data,
    operationalAreaData,
    isLoading,
    isRefetching,
    refetchSavedTriggers,
    refetchAllTriggers,
  } = useTriggers(plantId, setActiveTriggersData);

  const [OAAnchorEl, setOAAnchorEl] = useState(null);
  const isOAFilterOpen = Boolean(OAAnchorEl);
  const OAId = isOAFilterOpen ? 'OA-popover' : undefined;

  const [availableRoles, setAvailableRoles] = useState([]);

  const [isExpandable, setIsExpandable] = useState(false);
  const [isEditingCustomNotification, setIsEditingCustomNotification] =
    useState(false);
  const [
    clickedCustomNotificationTriggerId,
    setClickedCustomNotificationTriggerId,
  ] = useState(null);

  const { isLoading: isRolesDataLoading } = useQuery(
    [QUERY_CONSTANTS.NOTIFICATION_ROLES, plantId],
    () => getAllRolesForPlant(plantId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!plantId,
      onSuccess: (response) => {
        setAvailableRoles(response);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const [showCustomNotificationModal, setShowCustomNotificationModal] =
    useState(false);

  useEffect(() => {
    if (isLoading || isRefetching) return;
    loadTriggerSettings(
      triggerSettingsDispatch,
      data || [],
      operationalAreaData || []
    );
  }, [isLoading, isRefetching]);

  const openModal = () => {
    triggerSettingsDispatch({
      type: REDUCER_DISPATCH_ACTIONS.SHOW_ADD_REMOVE_MODAL,
      payload: { value: true },
    });
  };

  const { mutate: triggersMutate, isLoading: isSaving } = useMutation(
    [QUERY_CONSTANTS.SAVE_NOTIFICATIONS],
    async (notifications) => {
      if (!notifications || !notifications.length) {
        return { message: MESSAGE_STRINGS.NO_UNSAVED_CHANGES };
      }
      return saveTriggersForPlant(plantId, notifications);
    },
    {
      onSuccess: () => {
        refetchSavedTriggers();
        triggerSettingsDispatch({
          type: REDUCER_DISPATCH_ACTIONS.RESET_ON_SAVE,
          payload: { value: true },
        });
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE,
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_ERROR_MESSAGE,
          },
        });
      },
      enabled: !!plantId,
    }
  );

  const handleSave = async () => {
    const { emptyRoleFields, emptyTypeFields, validatedTriggersList } =
      validateTriggersEmptyFields(editedTriggersList);

    validateSave(
      triggerSettingsDispatch,
      emptyRoleFields,
      emptyTypeFields,
      validatedTriggersList
    );
    if (emptyRoleFields.size === 0 && emptyTypeFields.size === 0) {
      triggersMutate(formatNotificationsList(validatedTriggersList));
    }
  };

  const operationalAreaFilterHandler = (selectedFilterIds) => {
    setOAAnchorEl(null); // ? close filter popover
    triggerSettingsDispatch({
      type: REDUCER_DISPATCH_ACTIONS.SET_SELECTED_OPERATIONAL_AREA_FILTERS,
      payload: { selectedFilterIds },
    });
  };

  const handleAccordionExpansion = () => {
    if (availableRoles.length > 0) {
      setIsExpandable(!isExpandable);
    }
  };

  const handleCustomNotificationModalOpen = () => {
    setShowCustomNotificationModal(true);
    triggerSettingsDispatch({
      type: REDUCER_DISPATCH_ACTIONS.SHOW_ADD_REMOVE_MODAL,
      payload: { value: false },
    });
  };
  const handleCustomNotificationModalClose = () => {
    setShowCustomNotificationModal(false);
    triggerSettingsDispatch({
      type: REDUCER_DISPATCH_ACTIONS.SHOW_ADD_REMOVE_MODAL,
      payload: { value: true },
    });
    resetCustomNotificationEditingMode();
  };

  const resetCustomNotificationEditingMode = () => {
    setIsEditingCustomNotification(false);
    setClickedCustomNotificationTriggerId(null);
  };

  const handleCustomNotificationEditIconClick = (triggerId) => {
    setClickedCustomNotificationTriggerId(triggerId);
    setIsEditingCustomNotification(true);
    handleCustomNotificationModalOpen();
  };

  const isFilterDisabled =
    isLoading ||
    isRefetching ||
    isEditingActiveTriggers ||
    availableOperationalAreaFilters?.length === 0;

  const renderAddRemoveNotification = (treatments) => {
    const { treatment } = treatments || {};
    return (
      <>
        {showAddRemoveNotificationsModal && plantId && (
          <AddRemoveNotificationsModal
            open={showAddRemoveNotificationsModal}
            refetchAllTriggers={refetchAllTriggers}
            refetchSavedTriggers={refetchSavedTriggers}
            handleCustomNotificationModalOpen={
              handleCustomNotificationModalOpen
            }
            handleCustomNotificationEditIconClick={
              handleCustomNotificationEditIconClick
            }
            customNotificationFeature={treatment}
          />
        )}
      </>
    );
  };
  return (
    <SMFAccordion
      expanded={isExpandable}
      onChange={handleAccordionExpansion}
      disabled={!availableRoles.length}
      data-testid="trigger-settings-accordion"
    >
      <NotificationConfigAccordionTitle data-testid="trigger-settings-accordion-title">
        <Typography>{MESSAGE_STRINGS.NOTIFICATION_TRIGGER_SETTINGS}</Typography>
        <SMFTooltip
          message={MESSAGE_STRINGS.MISSING_PLANT_LEVEL_ROLE}
          placement="top-end"
          data-testid="notification-missing-plant-level-role-tooltip"
          maxWidth="14rem"
        >
          <InfoIcon height={16} width={16} />
        </SMFTooltip>
      </NotificationConfigAccordionTitle>
      <SMFAccordion.AccordionDetails data-testid="trigger-settings-accordion-body">
        <ActionsBox>
          <FilterContainer>
            <FilterName isDisabled={isFilterDisabled}>
              {MESSAGE_STRINGS.OPERATIONAL_AREA}
            </FilterName>
            <FilterPopover
              clickCallback={setOAAnchorEl}
              idRef={OAId}
              openBool={isOAFilterOpen}
              itemAnchorEl={OAAnchorEl}
              isDisabled={isFilterDisabled}
              selectedFilterCount={selectedOperationalAreaFilters?.length || 0}
              filterName={FILTER_NAMES.OPERATIONAL_AREA}
            >
              <MultiSelectDropdownFilter
                popOverTitle={MESSAGE_STRINGS.FILTER}
                filterProps={{
                  filtersList: availableOperationalAreaFilters,
                  filtersName: FILTER_NAMES.OPERATIONAL_AREA,
                }}
                itemDropDownValues={selectedOperationalAreaFilters}
                onChangeFunc={operationalAreaFilterHandler}
                showSearchBar={false}
                showFilterName={false}
              />
            </FilterPopover>
          </FilterContainer>
          <ButtonsContainer>
            <SMFButton
              type="secondary"
              onClick={() => {
                triggerSettingsDispatch({
                  type: REDUCER_DISPATCH_ACTIONS.IS_EDITING_ACTIVE_TRIGGERS,
                  payload: { value: true },
                });
              }}
              disabled={isEditingActiveTriggers}
              text={MESSAGE_STRINGS.EDIT}
              data-testid="edit-notifications-button"
            />
            <SMFButton
              type="secondary"
              onClick={openModal}
              text={MESSAGE_STRINGS.ADD_REMOVE}
              data-testid="add-remove-notifications-button"
            />
            <SplitTreatments names={[FEATURE_FLAGS.CUSTOM_NOTIFICATION]}>
              {({ treatments, isReady }) => {
                return isReady ? (
                  renderAddRemoveNotification(
                    treatments[FEATURE_FLAGS.CUSTOM_NOTIFICATION]
                  )
                ) : (
                  <>{MESSAGE_STRINGS.LOADING}</>
                );
              }}
            </SplitTreatments>
            {showCustomNotificationModal && plantId && (
              <CustomNotificationModal
                open={showCustomNotificationModal}
                closeModal={handleCustomNotificationModalClose}
                refetchAllTriggers={refetchAllTriggers}
                refetchSavedTriggers={refetchSavedTriggers}
                isEditing={isEditingCustomNotification}
                clickedCustomNotificationTriggerId={
                  clickedCustomNotificationTriggerId
                }
              />
            )}
          </ButtonsContainer>
        </ActionsBox>
        <SplitTreatments names={[FEATURE_FLAGS.CUSTOM_NOTIFICATION]}>
          {({ treatments, isReady }) => {
            const { treatment } = treatments[FEATURE_FLAGS.CUSTOM_NOTIFICATION];
            return isReady ? (
              <ActiveTriggers
                customNotificationFeature={treatment}
                addTriggersHandler={openModal}
                isEditing={isEditingActiveTriggers}
                isSaving={isSaving}
                plantId={plantId}
                availableRoles={availableRoles}
                isRolesDataLoading={isRolesDataLoading}
              />
            ) : (
              <>{MESSAGE_STRINGS.LOADING}</>
            );
          }}
        </SplitTreatments>

        {isEditingActiveTriggers && (
          <SaveButtonContainer>
            <SMFButton
              type="primary"
              onClick={handleSave}
              disabled={emptyRoleFields.size > 0 || emptyTypeFields.size > 0}
              text={MESSAGE_STRINGS.SAVE}
              data-testid="save-notifications-button"
            />
          </SaveButtonContainer>
        )}
      </SMFAccordion.AccordionDetails>
    </SMFAccordion>
  );
};

export default TriggerSettings;
