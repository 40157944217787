import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const MessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '29.875rem',
  backgroundColor: theme.palette.background.blackGrey,
}));

const MessageTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  color: theme.palette.text.lightWhiteGrey,
  marginBottom: '1.375rem',
}));
export { MessageContainer, MessageTitle };
