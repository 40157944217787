import { Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isNotificationsModal',
})(({ theme, isNotificationsModal }) => ({
  '&.MuiPaper-root': {
    borderColor: theme.palette.background.blackGrey,
    backgroundColor: theme.palette.background.elevationGrey,
    padding: '1rem',
    color: theme.palette.text.primary,
    outline: 'none',
    borderRadius: '0.25rem',
    width: isNotificationsModal ? '66.5rem' : '',
    margin: '0rem',
  },
}));

export { StyledPaper };
