import { MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const NotificationTypeOption = styled(MenuItem)(() => ({
  fontSize: '0.875rem',
}));

export const NotificationTypeItem = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  width: 'inherit',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));
