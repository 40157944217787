import { styled } from '@mui/material';
import Input from '../../components/Input';
import Select from '../../components/SMFSelect';

export const NotificationTimingRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isErrorState',
})(({ isErrorState }) => ({
  fontSize: '0.875rem',
  padding: '0 1.25rem',
  height: isErrorState ? '6rem' : '3rem',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(83, 86, 90, 0.3)',
}));

export const Description = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ConfigurationContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '17rem',
  alignItems: 'flex-start',
}));

export const BaseInput = styled(Input)(() => ({
  '& input': {
    width: '3.125rem',
  },

  '& div': {
    display: 'none',
  },
}));

export const DurationBannerInput = styled(BaseInput)(() => ({
  marginRight: '1.625rem',
}));

export const SnoozeDurationInput = styled(BaseInput)(() => ({
  marginRight: '0.59rem',
}));

export const SnoozeDurationSelect = styled(Select)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const DurationCenterInput = styled(BaseInput)(() => ({
  marginRight: '1.625rem',
}));

export const SaveButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '3.5rem',
  padding: '0.5rem',
}));

export const Duration = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1.625rem',
  marginLeft: 'auto',
}));

export const ErrorContainer = styled('div')(() => ({
  minHeight: '3.5rem',
  marginRight: 'auto',
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
}));

export const FlexContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

export const Placeholder = styled('div')(() => ({
  minHeight: '3rem',
}));

export const OuterFlexContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const InnerFlexContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
}));

export const ColumnFlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LabelConfigContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '0.5rem',
  alignItems: 'baseline',
}));
