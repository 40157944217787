import { Box, Typography, styled } from '@mui/material';

export const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1.021rem',
};

const ToolTipContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '7.25rem',
  width: '18.90rem',
  padding: '0.625rem 0 0.625rem 0.75rem',
  gap: '0.313rem',
});

const TooltipTypography = styled(Typography, {
  shouldForwardProp: (props) => props !== 'isBold',
})(({ isBold }) => ({
  ...typographyStyles,
  fontWeight: isBold ? '700' : '400',
}));

export { ToolTipContainer, TooltipTypography };
