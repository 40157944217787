import MESSAGE_STRINGS from '../../constants/en-us';
import { TriggerSettingsProvider } from '../../context/triggerSettingsContext';
import DemoNotification from '../DemoNotification';
import TimingSettings from '../TimingSettings';
import TriggerSettings from '../TriggerSettings';
import { useState } from 'react';

import { MainContainer, PageTitle, SettingsContainer } from './style.js';

export default function ConfigContainer() {
  const [activeTriggersData, setActiveTriggersData] = useState();
  return (
    <MainContainer>
      <PageTitle variant="h3">{MESSAGE_STRINGS.NOTIFICATIONS}</PageTitle>
      <SettingsContainer>
        <TimingSettings />
        <TriggerSettingsProvider>
          <TriggerSettings setActiveTriggersData={setActiveTriggersData} />
        </TriggerSettingsProvider>
        <DemoNotification activeTriggersData={activeTriggersData} />
      </SettingsContainer>
    </MainContainer>
  );
}
