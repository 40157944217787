import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const CustomizedAutoCompleteInput = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  marginTop: '0.4rem',
  '& .MuiAutocomplete-root': {
    width: '10rem !important',
    height: '2rem !important',
    border: `.0625rem solid ${theme.palette.border.lightWhiteGrey} !important`,
    padding: '0.6875rem 1rem',
    fontSize: '0.875rem',
    fontFamily: 'Open Sans',
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: `${theme.palette.background.blackerRangoonGreen} !important`,
  },
  '& .MuiAutocomplete-inputRoot': {
    padding: '0px !important',
  },
  '& .MuiAutocomplete-input': {
    minWidth: '0rem !important',
    boxSizing: 'border-box !important',
    width: '10rem !important',
    height: '2rem !important',
    border: `.0625rem solid ${theme.palette.border.lightWhiteGrey} !important`,
    padding: '0.6875rem 1rem !important',
    fontSize: '0.875rem',
    fontFamily: 'Open Sans',
    color: `${theme.palette.text.primary} !important`,
    backgroundColor: `${theme.palette.background.blackerRangoonGreen} !important`,

    '&:hover, &:focus, &:active': {
      boxShadow: '0 2px 6px rgba(0,0,0,0.16) !important',
      transition: 'all 0.225s ease-out',
      filter: 'brightness(125%)',
      border: `2px solid ${theme.palette.border.saveGreen} !important`,
    },

    '&::placeholder': {
      color: `${theme.palette.text.nobel} !important`,
    },
  },
  '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-input': {
    border: `.0625rem solid ${theme.palette.border.lightWhiteGrey} !important`,
  },
}));

// eslint-disable-next-line react/display-name
const InputDropdown = ({
  value,
  freeSolo = true,
  onChange,
  onInputChange,
  placeholder,
  inputValue,
  ...rest
}) => {
  return (
    <CustomizedAutoCompleteInput
      id="smf-autocomplete"
      data-testid="smf-autocomplete"
      value={value}
      freeSolo={freeSolo}
      onInputChange={onInputChange}
      onChange={onChange}
      inputValue={inputValue}
      disableClearable={true}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} />
      )}
      PaperComponent={(props) => (
        <Paper
          data-testid="smf-autocomplete-suggestion"
          sx={{
            background: (theme) => theme.palette.background.blackerRangoonGreen,
            color: (theme) => theme.customColors.white,
            '&:focus, &:active': {
              backgroundColor: (theme) =>
                `${theme.palette.background.gunmetalGrey} !important`,
            },
          }}
          {...props}
        />
      )}
      {...rest}
    />
  );
};

export default InputDropdown;
