import { Box, styled } from '@mui/material';

const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const NewRowIndicatorBox = styled(Box)(() => ({
  marginRight: '1rem',
}));

export { ContainerBox, NewRowIndicatorBox };
