import MESSAGE_STRINGS from '../../constants/en-us';
import {
  RISK_THRESHOLD_CONSTANTS,
  SOC_ADDL_SETTINGS_CONSTANTS,
  DEFAULT_RECURRENCE_VALUE,
  FEATURE_FLAG_TREATMENTS,
} from '../../constants';

export function getSelectedRoleIds(selectedRoles) {
  const selectedRoleIds = [];
  for (const role of selectedRoles) {
    selectedRoleIds.push(role.id);
  }
  return selectedRoleIds;
}

export function formatSelectedRoleIds(selectedRoleIds) {
  const formattedList = [];
  for (const id of selectedRoleIds) {
    formattedList.push({ roleId: id });
  }
  return formattedList;
}

export function formatSelectedRoleNames(selectedOptions) {
  const selectedLabels = [];
  for (const option of selectedOptions) {
    selectedLabels.push(option.label);
  }
  return selectedLabels.join('; ');
}

export function displaySelectedRoles(options, isEditing) {
  const selectedOptions = options.filter((option) => option.isSelected);

  if (selectedOptions.length === 0) {
    if (isEditing) {
      return MESSAGE_STRINGS.SELECT;
    }
    return '';
  }
  if (selectedOptions.length === 1) {
    return selectedOptions[0].label;
  }
  if (options.length - 1 === selectedOptions.length) {
    return MESSAGE_STRINGS.ALL;
  }
  return isEditing
    ? MESSAGE_STRINGS.MULTIPLE
    : formatSelectedRoleNames(selectedOptions);
}

export const getRolesTooltipText = (
  selectedRoleIds,
  availableRoles,
  isEditing
) => {
  let text = '';
  if (
    selectedRoleIds.length > 1 &&
    selectedRoleIds.length !== availableRoles.length - 1
  ) {
    text = displaySelectedRoles(availableRoles, isEditing);
  }
  return text;
};

export const getFilteredActiveListSortedByEdit = (
  triggersList,
  selectedOAFilterIds,
  customNotificationFeature
) => {
  return triggersList
    .filter(
      (trigger) =>
        trigger.isActive &&
        trigger.isEnabled &&
        selectedOAFilterIds?.includes(trigger.operationalAreaId) &&
        ((trigger?.isCustom &&
          customNotificationFeature === FEATURE_FLAG_TREATMENTS.ON) ||
          !trigger?.isCustom)
    )
    .sort(
      (a, b) =>
        Number(b.isNew) - Number(a.isNew) ||
        a.functionalAreaName.localeCompare(b.functionalAreaName) ||
        a.triggerName.localeCompare(b.triggerName)
    );
};

export const validateTriggersEmptyFields = (editedTriggersList = []) => {
  const emptyRoleFields = new Set();
  const emptyTypeFields = new Set();
  const validatedTriggersList = [...editedTriggersList];
  for (const trigger of validatedTriggersList) {
    if (trigger.isEdited && trigger.isActive) {
      if (trigger.notificationType && trigger.notificationType.length) {
        trigger.notificationTypeError = false;
        emptyTypeFields.delete(trigger.triggerId);
      } else {
        trigger.notificationTypeError = MESSAGE_STRINGS.REQUIRED_FIELD;
        emptyTypeFields.add(trigger.triggerId);
      }

      if (trigger.roles && trigger.roles.length) {
        trigger.rolesAssignedError = false;
        emptyRoleFields.delete(trigger.triggerId);
      } else {
        trigger.rolesAssignedError = MESSAGE_STRINGS.REQUIRED_FIELD;
        emptyRoleFields.add(trigger.triggerId);
      }
    }
  }
  return {
    emptyRoleFields,
    emptyTypeFields,
    validatedTriggersList,
  };
};

export const getDefaultValueForSOC = (settingsType, notificationTriggerId) => {
  if (
    settingsType === SOC_ADDL_SETTINGS_CONSTANTS.CRITICALITY &&
    notificationTriggerId.indexOf('SOC') !== -1
  ) {
    return '1';
  } else if (
    settingsType === SOC_ADDL_SETTINGS_CONSTANTS.RISK_CATEGORY &&
    notificationTriggerId.indexOf('SOC') !== -1
  ) {
    return notificationTriggerId.indexOf('PARAMETER') !== -1
      ? RISK_THRESHOLD_CONSTANTS.MAJOR
      : RISK_THRESHOLD_CONSTANTS.INSIGNIFICANT;
  } else {
    return null;
  }
};

export const getDefaultRecurrenceValue = (isRecurrence) => {
  return isRecurrence ? DEFAULT_RECURRENCE_VALUE : null;
};

export const formatNotificationsList = (notifications) => {
  // Save triggers that are edited and
  // that are not deactivated without saving mandatory fields
  const editedNotifications = notifications.filter(
    (trigger) => trigger.isEdited && trigger.notificationType && trigger.roles
  );
  return editedNotifications.map((notification) => {
    const formattedNotification = {
      notificationId: notification.notificationId || null,
      triggerId: notification.triggerId,
      triggerName: notification.triggerName,
      operationalAreaId: notification.operationalAreaId,
      notificationType: notification.notificationType || null,
      recurrence: notification.recurrence || false,
      recurrenceValue:
        notification.recurrenceValue ||
        getDefaultRecurrenceValue(notification.recurrence),
      recurrenceUnit: notification.recurrenceUnit || MESSAGE_STRINGS.MINUTES,

      isActive: notification.isActive,
      isMute: notification.isMute || false,
      addlSettingsActive: notification.addlSettingsActive,
      assetCriticalityThreshold:
        notification.assetCriticalityThreshold ||
        getDefaultValueForSOC(
          SOC_ADDL_SETTINGS_CONSTANTS.CRITICALITY,
          notification.notificationTriggerId
        ),
      assetRiskCategoryThreshold:
        notification.assetRiskCategoryThreshold ||
        getDefaultValueForSOC(
          SOC_ADDL_SETTINGS_CONSTANTS.RISK_CATEGORY,
          notification.notificationTriggerId
        ),

      tempMuteValue: !!notification.tempMuteValue
        ? notification.tempMuteValue
        : null,
      tempMuteUoM: !!notification.tempMuteUoM ? notification.tempMuteUoM : null,

      roles: notification.roles ? [...notification.roles] : null,
      functionalAreaName: notification.functionalAreaName,
      functionalAreaId: notification.functionalAreaId,
    };
    return { ...formattedNotification };
  });
};

export const getAvailableFilterIds = (availableFilters = []) => {
  const filterIds = [];
  availableFilters.forEach((filterObj) => {
    if (filterObj.id !== 'all') {
      filterIds.push(filterObj.id);
    }
  });
  return filterIds;
};

export const restrictInputInNumericField = (e) => {
  const restrictedInput = ['.', '+', '-', 'e', 'E'];
  restrictedInput.forEach((input) => {
    if (e.key === input) {
      e.preventDefault();
    }
  });
};
