import { styled, Typography, ButtonBase, Popover, Box } from '@mui/material';

const PopoverContainer = styled(Box)(() => ({
  marginBottom: '0.313rem',
}));

const SelectedValueLargeBadge = styled('span')(({ theme }) => ({
  width: '1.5rem',
  height: '1.5rem',
  background: theme.customColors.highlightBlue,
  borderRadius: '50%',
  marginTop: '-1.25rem',
  marginLeft: '-0.5rem',
  textAlign: 'center',
  paddingTop: '3px',
}));

const BadgeLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: '700',
  color: theme.palette.background.configLighterDark,
}));

const SelectedValueBadge = styled('span')(({ theme }) => ({
  width: '1.2rem',
  height: '1.2rem',
  background: theme.customColors.highlightBlue,
  borderRadius: '50%',
  marginTop: '-1.25rem',
  marginLeft: '-0.5rem',
  textAlign: 'center',
  paddingTop: '1.5px',
}));

const IconButtonBase = styled(ButtonBase)(({ theme }) => ({
  marginLeft: '0.5rem',
  '&.Mui-disabled': {
    '& path': {
      fill: theme.customColors.darkGrey,
    },
  },
}));

const StyledPopover = styled(Popover)(() => ({
  zIndex: '1500',
}));

export {
  PopoverContainer,
  SelectedValueLargeBadge,
  BadgeLabel,
  SelectedValueBadge,
  IconButtonBase,
  StyledPopover,
};
