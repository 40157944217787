export const DataTestIds = {
  DEMO_NOTIFICATION: 'demo-notification',
  DEMO_NOTIFICATION_HEADER: 'demo-notification-header',
  ERROR_CODE_CONFIG: 'error-code-config-container',
  SEND_BUTTON_CONTAINER: 'send-button-container',
  SEND_BUTTON: 'demo-notification-send-button',
  ERROR_CODE_DEMO_CONTAINER: 'error-code-demo-container',
  DISPLAY_IN_LABEL: 'display-in-label',
  DISPLAY_IN_INPUT: 'display-in-input',
  DELAY_IN_DROPDOWN: 'delay-in-dropdown',
  QUALITY_DISPLAY_IN_LABEL: 'quality_display-in-label',
  QUALITY_DISPLAY_IN_INPUT: 'quality_display-in-input',
  QUALITY_DELAY_IN_DROPDOWN: 'quality_delay-in-dropdown',
  DELAY_CONFIG_CONTAINER: 'delay-config-container',
  ERROR_CODE_INPUT: 'error-code-input',
  DEMO_NOTIFICATION_LABEL: 'demo-notification-label',
  ERROR_CODE_LABEL: 'error-code-label',
  ERROR_CODE_DEMO_LABEL: 'error-code-demo',
  NOTIFICATION_TIMEFRAME_LABEL: 'notification-timeframe-label',
  NOTIFICATION_TIMEFRAME_LABEL_QUALITY: 'notification-timeframe-label-quality',
  PART_ID_LABEL_QUALITY: 'partId-label-quality',
  CONFIRMATION_HEADER: 'confirmation-modal-header',
  CONFIRMATION_MODAL_CONTENT: 'confirmation-modal-content',
  CONFIRMATION_DIALOG: 'confirmation-dialog',
  CONFIRMATION_MODAL_PRIMARY_TEXT: 'confirmation-modal-primary-text',
  CONFIRMATION_MODAL_SECONDARY_TEXT: 'confirmation-modal-seconday-text',
  DURATION_ERROR: 'duration-error-message',
  DELAY_IN_SECONDS: 'delay-in-seconds',
  DELAY_IN_MINUTES: 'delay-in-minutes',
  DISABLE_DEMO_CONTAINER: 'disable-demo-notification-container',
  DISABLE_DEMO_LABEL: 'disable-demo-label',
  QUALITY_DEFECT_CODE_CONFIG: 'quality-defect-code-config-container',
  QUALITY_DEFECT_CODE_DEMO_CONTAINER: 'quality-defect-code-demo-container',
  QUALITY_DEFECT_CODE_INPUT: 'quality-defect-code-input',
  QUALITY_DEFECT_PART_INPUT: 'quality-defect-part-input',
  QUALITY_DEFECT_CODE_LABEL: 'quality-defect-code-label',
  QUALITY_DEFECT_CODE_DEMO_LABEL: 'quality-defect-code-demo',
  ENTITY_HIERARCHY_ACCORDION_HEADER: 'entity-hierarchy-accordion-header',
  ENTITY_HIERARCHY_ACCORDION_SUB_HEADER:
    'entity-hierarchy-accordion-sub-header',
  DEMO_NOTIFICATION_HEADER_QUALITY: 'demo-notification-header-quality',
};
