export const notificationTimingDefaultFormValues = {
  entityId: '',
  durationBanner: '',
  durationBannerUoM: 'Seconds',
  snoozeDuration: '',
  snoozeDurationUoM: 'Minutes',
  durationCenter: '',
  durationCenterUoM: '',
  startTime: '',
  endTime: '',
  endsOn: '',
};
