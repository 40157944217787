import { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { Box, FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import DropdownLabel from '../../../components/DropdownLabel';
import NamedCombobox from '../../../components/NamedCombobox';
import MESSAGE_STRINGS from '../../../constants/en-us';
import {
  StyledRadioGroup,
  NotificationTypeContainer,
  ConfigOuterContainer,
  ConfigInnerContainer,
  SubHeadingTypography,
  RadioBtnTypography,
  StyledMetric,
  FlexBox,
  StyledDisplay,
  BackdropContainer,
  CustomBackdrop,
  StyledDisplayRow,
  StyledTriggerNameTypography,
  InputContainer,
} from './style';
import {
  COMBOBOX_PLACEHOLDER,
  CUSTOM_NOTIFICATION_METRICS,
  CUSTOM_NOTIFICATION_TYPES,
  QUERY_CONSTANTS,
  TEST_IDS,
} from '../../../constants';
import { getCustomNotificationSettings } from '../../../utils/apiHelper';
import { useRxjsState } from '../../../utils/hooks/useRxjsState';
import {
  getDisplayMessageFromTriggerName,
  getNotificationCategoryDetailsToRender,
  isModuleMetricValid,
} from '../../../utils/helpers';
import LoadingIndicator from '../../../components/Loader';
import TextField from '../../../components/TextField';

const CustomNotificationConfig = ({
  toggleSaveDisability,
  metricData,
  handleMetricDataChange,
  displayedMessage,
  handleDisplayedMessageChange,
  selectedNotificationType,
  isDataLoading = false,
  isEditing = false,
}) => {
  const { rxjsState } = useRxjsState();
  const [metricSettings, setMetricSettings] = useState(null);
  const [customNotificationMetaData, setCustomNotificationMetaData] =
    useState(null);

  const handleModuleMetricChange = (value, res, initialMetricSettingsData) => {
    const metaData = res || customNotificationMetaData;
    const initialMetricSettings = initialMetricSettingsData || metricSettings;
    //getModuleByName
    const module = metaData[selectedNotificationType][
      CUSTOM_NOTIFICATION_METRICS.MODULE
    ].find((module) => module.name === value);
    const updatedMetricSettings = getNotificationCategoryDetailsToRender(
      metaData,
      selectedNotificationType,
      module.id
    );
    setMetricSettings({
      ...initialMetricSettings,
      ...updatedMetricSettings,
    });
  };

  const handleMetricSelectionChange = (value, metricName) => {
    let updatedDisplayedMessage = displayedMessage;
    let updatedMetricData = {};
    switch (metricName) {
      case CUSTOM_NOTIFICATION_METRICS.MODULE:
        handleModuleMetricChange(value);
        updatedMetricData = {
          ...metricData,
          [metricName]: value,
          [CUSTOM_NOTIFICATION_METRICS.ALERT]: null,
          [CUSTOM_NOTIFICATION_METRICS.TRIGGER]: null,
        };
        updatedDisplayedMessage = value;
        break;
      case CUSTOM_NOTIFICATION_METRICS.ALERT:
        updatedMetricData = {
          ...metricData,
          [metricName]: value,
          [CUSTOM_NOTIFICATION_METRICS.TRIGGER]: null,
        };
        updatedDisplayedMessage = `${metricData.module} ${value}`;
        break;
      case CUSTOM_NOTIFICATION_METRICS.TRIGGER:
        updatedMetricData = {
          ...metricData,
          [metricName]: value,
        };
        updatedDisplayedMessage = `${metricData.module} ${
          metricData.alert
        } ${getDisplayMessageFromTriggerName(
          value,
          customNotificationMetaData,
          selectedNotificationType,
          metricData.module
        )}`;
        break;
      default:
        updatedMetricData = {
          ...metricData,
        };
    }
    handleMetricDataChange(updatedMetricData);
    handleDisplayedMessageChange(updatedDisplayedMessage);
    toggleSaveDisability(
      !isModuleMetricValid(updatedMetricData, updatedDisplayedMessage)
    );
  };

  const handleDisplayedMessageInputChange = (e) => {
    handleDisplayedMessageChange(e.target.value);
    toggleSaveDisability(!isModuleMetricValid(metricData, e.target.value));
  };

  /* 
    Fetching Custom notification settings
  */
  const { isLoading: isCustomNotificationSettingsLoading } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_CUSTOM_NOTIFICATION_SETTINGS],
    queryFn: () => {
      const queryParams = {
        plantId: rxjsState.plantId,
      };
      return getCustomNotificationSettings(queryParams);
    },
    onSuccess: (res) => {
      setCustomNotificationMetaData(res);
      let initialMetricSettings = {};
      if (selectedNotificationType === CUSTOM_NOTIFICATION_TYPES.MODULE) {
        let moduleOptions = [];
        res?.[selectedNotificationType]?.[
          CUSTOM_NOTIFICATION_METRICS.MODULE
        ]?.forEach((metric) => {
          moduleOptions.push(metric?.name);
        });
        initialMetricSettings[CUSTOM_NOTIFICATION_METRICS.MODULE] =
          moduleOptions;
        if (isEditing) {
          handleModuleMetricChange(
            metricData?.module,
            res,
            initialMetricSettings
          );
        } else {
          setMetricSettings(initialMetricSettings);
        }
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: isEditing
      ? Boolean(rxjsState?.plantId) && Boolean(metricData?.module)
      : Boolean(rxjsState?.plantId),
  });

  const showBackdropLoader =
    isCustomNotificationSettingsLoading || isDataLoading;

  return (
    <ConfigOuterContainer>
      <ConfigInnerContainer>
        <NotificationTypeContainer>
          <SubHeadingTypography>
            {MESSAGE_STRINGS.NOTIFICATION_TYPE}
          </SubHeadingTypography>
          <DropdownLabel
            label={MESSAGE_STRINGS.NOTIFICATION_CATEGORY}
            isTooltip={true}
            isNotificationTypeTooltip={true}
          />

          <StyledRadioGroup
            aria-labelledby="notification-category-radio-button-group"
            name="notification-category-radiobtn-grp"
            defaultValue={MESSAGE_STRINGS.MODULE}
            data-testid={TEST_IDS.NOTIFICATION_CATEGORY_RADIOBTN_GROUP}
          >
            <FormControlLabel
              value={MESSAGE_STRINGS.MODULE}
              control={<Radio />}
              label={
                <RadioBtnTypography>
                  {MESSAGE_STRINGS.MODULE}
                </RadioBtnTypography>
              }
            />
          </StyledRadioGroup>
        </NotificationTypeContainer>
        <StyledMetric>
          <SubHeadingTypography>{MESSAGE_STRINGS.METRIC}</SubHeadingTypography>
          <FlexBox sx={{ columnGap: '1.863rem' }}>
            <NamedCombobox
              label={MESSAGE_STRINGS.MODULE}
              placeholder={COMBOBOX_PLACEHOLDER}
              options={metricSettings?.module}
              value={metricData.module}
              onSelectionChanged={handleMetricSelectionChange}
              metricName={CUSTOM_NOTIFICATION_METRICS.MODULE}
            />
            <NamedCombobox
              label={MESSAGE_STRINGS.ALERT}
              placeholder={COMBOBOX_PLACEHOLDER}
              options={metricSettings?.alert}
              value={metricData.alert}
              onSelectionChanged={handleMetricSelectionChange}
              metricName={CUSTOM_NOTIFICATION_METRICS.ALERT}
              isDisabled={!metricData.module}
            />
            <NamedCombobox
              label={MESSAGE_STRINGS.TRIGGER}
              isTooltip={true}
              tooltipMessage={
                MESSAGE_STRINGS.CUSTOM_NOTIFICATION_TRIGGER_TOOLTIP
              }
              placeholder={COMBOBOX_PLACEHOLDER}
              options={metricSettings?.trigger}
              value={metricData.trigger}
              onSelectionChanged={handleMetricSelectionChange}
              metricName={CUSTOM_NOTIFICATION_METRICS.TRIGGER}
              isDisabled={!metricData.alert}
            />
          </FlexBox>
        </StyledMetric>
        <StyledDisplay>
          <SubHeadingTypography>
            {MESSAGE_STRINGS.DISPLAY_DETAILS}
          </SubHeadingTypography>
          <StyledDisplayRow>
            <Box>
              <DropdownLabel
                label={MESSAGE_STRINGS.CUSTOM_NOTIFICATION_TRIGGER_NAME}
                tooltipMessage={
                  MESSAGE_STRINGS.CUSTOM_NOTIFICATION_TRIGGER_NAME_TOOLTIP
                }
                isTooltip={true}
              />
              <StyledTriggerNameTypography
                data-testid={TEST_IDS.NOTIFICATION_TRIGGER_NAME}
              >
                {metricData.module || metricData.alert || metricData.trigger
                  ? `${metricData.module ?? ''} ${metricData.alert ?? ''} ${
                      metricData.trigger ?? ''
                    }`
                  : '-'}
              </StyledTriggerNameTypography>
            </Box>
            <Box>
              <DropdownLabel
                label={MESSAGE_STRINGS.CUSTOM_NOTIFICATION_DISPLAYED_MESSAGE}
                tooltipMessage={
                  MESSAGE_STRINGS.CUSTOM_NOTIFICATION_DISPLAYED_MESSAGE_TOOLTIP
                }
                isTooltip={true}
              />
              <InputContainer>
                <TextField
                  maxLen={50}
                  applyLayoutBackgroundColor={true}
                  value={displayedMessage}
                  onChange={handleDisplayedMessageInputChange}
                  type="text"
                />
              </InputContainer>
            </Box>
          </StyledDisplayRow>
        </StyledDisplay>
      </ConfigInnerContainer>
      <BackdropContainer data-testid={TEST_IDS}>
        <CustomBackdrop open={showBackdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </ConfigOuterContainer>
  );
};

export default CustomNotificationConfig;

CustomNotificationConfig.propTypes = {
  toggleSaveDisability: PropTypes.func,
  metricData: PropTypes.object,
  handleMetricDataChange: PropTypes.func,
  displayedMessage: PropTypes.string,
  handleDisplayedMessageChange: PropTypes.func,
  selectedNotificationType: PropTypes.string,
  isDataLoading: PropTypes.bool,
  isEditing: PropTypes.bool,
};
