import { Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledModalHeader = styled(Typography)(() => ({
  padding: '0.625rem',
  fontSize: '1.125rem',
  fontWeight: '700',
  lineHeight: '1.5rem',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    borderColor: theme.palette.background.blackGrey,
    backgroundColor: theme.palette.background.elevationGrey,
    color: theme.palette.text.primary,
    outline: 'none',
    borderRadius: '0.25rem',
  },
}));

const StyledModalHeaderAdditionalSettings = styled(Typography)(({ theme }) => ({
  fontSize: '1.25',
  fontWeight: '700',
  lineHeight: '1.6875rem',
  color: theme.customColors.white,
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
}));

const StyledDivider = styled('div')(() => ({
  height: '0.0625rem',
  width: '105.6%',
  background: '#40444A',
  marginTop: '0.625rem',
  marginLeft: '-1.5rem',
}));

export {
  StyledModalHeader,
  StyledModalHeaderAdditionalSettings,
  StyledDivider,
  StyledPaper,
};
