import { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import SMFTable from '../../../components/SMFTable';
import AddRemoveToggleCell from '../../AddRemoveNotificationModal/AddRemoveToggleCell';
import DataCell from '../../AddRemoveNotificationModal/DataCell';
import MESSAGE_STRINGS from '../../../constants/en-us';
import { TableContainer, HeaderBox } from './style.js';
import MultiSelectDropdownFilter from '../../../components/MultiSelectDropdownFilter';
import FilterPopover from '../../../components/FilterPopover';
import {
  FILTER_NAMES,
  REDUCER_DISPATCH_ACTIONS,
} from '../../../constants/index';
import { useTriggerSettingsContext } from '../../../context/triggerSettingsContext';

const TriggersListTable = ({
  isDataLoading,
  triggersData,
  getTriggersToBeUpdated,
  handleCustomNotificationEditIconClick,
  handleCustomNotificationDeleteIconClick,
}) => {
  const {
    triggerSettingsState: {
      availableModalFunctionalAreaFilters: availableFunctionalAreaFilters,
      selectedModalFunctionalAreaFilters: selectedFunctionalAreaFilters,
      availableIsActiveFilters,
      selectedIsActiveFilters,
    },
    triggerSettingsDispatch,
  } = useTriggerSettingsContext();

  // Functional Area Filter popover properties
  const [FAAnchorEl, setFAAnchorEl] = useState(null);
  const isFAFilterOpen = Boolean(FAAnchorEl);
  const FAId = isFAFilterOpen ? 'FA-popover' : undefined;

  // Is Active Filter Popover properties
  const [IAAnchorEl, setIAAnchorEl] = useState(null);
  const isIAFilterOpen = Boolean(IAAnchorEl);
  const IAId = isIAFilterOpen ? 'IA-popover' : undefined;

  const isFilterDisabled =
    isDataLoading || availableFunctionalAreaFilters?.length === 0;

  const filterChangeHandler = (selectedFilterIds, action, filterName) => {
    switch (filterName) {
      case FILTER_NAMES.FUNCTIONAL_AREA:
        setFAAnchorEl(null); // ? close filter popover
        triggerSettingsDispatch({
          type: REDUCER_DISPATCH_ACTIONS.SET_MODAL_SELECTED_FUNCTIONAL_AREA_FILTERS,
          payload: { selectedFilterIds },
        });
        break;
      case FILTER_NAMES.IS_ACTIVE:
        setIAAnchorEl(null);
        triggerSettingsDispatch({
          type: REDUCER_DISPATCH_ACTIONS.SET_SELECTED_IS_ACTIVE_FILTERS,
          payload: { selectedFilterIds },
        });
        break;
    }
  };

  const columns = [
    {
      Header: (
        <HeaderBox>
          <Typography>{MESSAGE_STRINGS.FUNCTIONAL_AREA}</Typography>
          <FilterPopover
            clickCallback={setFAAnchorEl}
            idRef={FAId}
            openBool={isFAFilterOpen}
            itemAnchorEl={FAAnchorEl}
            isDisabled={isFilterDisabled}
            selectedFilterCount={selectedFunctionalAreaFilters?.length || 0}
            filterName={FILTER_NAMES.FUNCTIONAL_AREA}
          >
            <MultiSelectDropdownFilter
              popOverTitle={MESSAGE_STRINGS.FILTER}
              filterProps={{
                filtersList: availableFunctionalAreaFilters,
                filtersName: FILTER_NAMES.FUNCTIONAL_AREA,
              }}
              itemDropDownValues={selectedFunctionalAreaFilters}
              onChangeFunc={filterChangeHandler}
              showSearchBar={false}
              showFilterName={false}
            />
          </FilterPopover>
        </HeaderBox>
      ),
      accessor: 'functionalAreaName',
      Cell: DataCell,
      width: '25%',
    },
    {
      Header: <Typography>{MESSAGE_STRINGS.NOTIFICATION_TRIGGER}</Typography>,
      accessor: 'triggerName',
      Cell: DataCell,
      width: '45%',
    },
    {
      Header: (
        <HeaderBox>
          <Typography>{MESSAGE_STRINGS.ENABLE_DISABLE}</Typography>
          <FilterPopover
            clickCallback={setIAAnchorEl}
            idRef={IAId}
            openBool={isIAFilterOpen}
            itemAnchorEl={IAAnchorEl}
            isDisabled={isFilterDisabled}
            selectedFilterCount={selectedIsActiveFilters?.length || 0}
            filterName={FILTER_NAMES.IS_ACTIVE}
          >
            <MultiSelectDropdownFilter
              popOverTitle={MESSAGE_STRINGS.FILTER}
              filterProps={{
                filtersList: availableIsActiveFilters,
                filtersName: FILTER_NAMES.IS_ACTIVE,
              }}
              itemDropDownValues={selectedIsActiveFilters}
              onChangeFunc={filterChangeHandler}
              showSearchBar={false}
              showFilterName={false}
            />
          </FilterPopover>
        </HeaderBox>
      ),
      accessor: 'isActive',
      Cell: (cellProps) => (
        <AddRemoveToggleCell
          {...cellProps}
          getTriggersToBeUpdated={getTriggersToBeUpdated}
          handleCustomNotificationEditIconClick={
            handleCustomNotificationEditIconClick
          }
          handleCustomNotificationDeleteIconClick={
            handleCustomNotificationDeleteIconClick
          }
        />
      ),
      width: '30%',
    },
  ];

  return (
    <TableContainer>
      <SMFTable
        columns={columns}
        isLoading={isDataLoading}
        data={triggersData}
        hideBorder={false}
      />
    </TableContainer>
  );
};

TriggersListTable.propTypes = {
  isDataLoading: PropTypes.bool,
  triggersData: PropTypes.arrayOf(PropTypes.shape({})),
  getTriggersToBeUpdated: PropTypes.func,
  handleCustomNotificationEditIconClick: PropTypes.func,
  handleCustomNotificationDeleteIconClick: PropTypes.func,
};

export default TriggersListTable;
