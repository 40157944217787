export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};

export const NOTIFICATION_TYPES = {
  NOTIFICATION_CENTER_ONLY: 'Notification Center Only',
  ALERT: 'Alert',
};

export const QUERY_CONSTANTS = {
  ACTIVE_NOTIFICATION_TRIGGERS: 'get-active-triggers',
  GET_NOTIFICATION_TIMING: 'get-notification-timing',
  ALL_NOTIFICATION_TRIGGERS: 'get-all-triggers',
  NOTIFICATION_ROLES: 'notification-roles',
  SAVE_NOTIFICATIONS: 'save-notifications',
  GET_OPERATIONAL_AREAS: 'get-operational-areas',
  GET_SCHEDULE: 'get-schedule',
  GET_CUSTOM_NOTIFICATION_SETTINGS: 'get-custom-notification-settings',
  SAVE_CUSTOM_NOTIFICATION_DETAILS: 'save-custom-notification-details',
  GET_SAVED_CUSTOM_NOTIFICATION_DETAILS:
    'get-saved-custom-notification-details',
  DELETE_CUSTOM_NOTIFICATION: 'delete-custom-notification',
};

export const API_SOC_CONSTANTS = {
  ASSET: 'ASSET',
  CELL: 'CELL',
  PARAMETER: 'PARAMETER',
};

export const SOC_ADDL_SETTINGS_CONSTANTS = {
  CRITICALITY: 'CRITICALITY',
  RISK_CATEGORY: 'RISK_CATEGORY',
};

export const RISK_THRESHOLD_CONSTANTS = {
  INSIGNIFICANT: 'Insignificant',
  MINOR: 'Minor',
  MODERATE: 'Moderate',
  MAJOR: 'Major',
  CATASTROPHIC: 'Catastrophic',
};

export const DISABLED_PARAMETER_RISK_THRESHOLDS = [
  RISK_THRESHOLD_CONSTANTS.INSIGNIFICANT,
  RISK_THRESHOLD_CONSTANTS.MINOR,
  RISK_THRESHOLD_CONSTANTS.MODERATE,
];

export const REDUCER_DISPATCH_ACTIONS = {
  SET_TRIGGERS_LIST: 'SET_TRIGGERS_LIST',
  RESET_ON_SAVE: 'RESET_ON_SAVE',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  RESET_ADD_REMOVE_MODAL: 'RESET_ADD_REMOVE_MODAL',
  SHOW_ADD_REMOVE_MODAL: 'SHOW_ADD_REMOVE_MODAL',
  IS_EDITING_ACTIVE_TRIGGERS: 'IS_EDITING_ACTIVE_TRIGGERS',
  UPDATE_ROLES_ASSIGNED: 'UPDATE_ROLES_ASSIGNED',
  UPDATE_NOTIFICATION_TYPE: 'UPDATE_NOTIFICATION_TYPE',
  UPDATE_RECURRENCE: 'UPDATE_RECURRENCE',
  UPDATE_TEMP_MUTE_VALUE: 'UPDATE_TEMP_MUTE_VALUE',
  VALIDATE_SAVE: 'VALIDATE_SAVE',
  SET_AVAILABLE_OPERATIONAL_AREA_FILTERS:
    'SET_AVAILABLE_OPERATIONAL_AREA_FILTERS',
  SET_SELECTED_OPERATIONAL_AREA_FILTERS:
    'SET_SELECTED_OPERATIONAL_AREA_FILTERS',
  SET_MODAL_AVAILABLE_FUNCTIONAL_AREA_FILTERS:
    'SET_MODAL_AVAILABLE_FUNCTIONAL_AREA_FILTERS',
  SET_MODAL_SELECTED_FUNCTIONAL_AREA_FILTERS:
    'SET_MODAL_SELECTED_FUNCTIONAL_AREA_FILTERS',
  SET_AVAILABLE_IS_ACTIVE_FILTERS: 'SET_AVAILABLE_IS_ACTIVE_FILTERS',
  SET_SELECTED_IS_ACTIVE_FILTERS: 'SET_SELECTED_IS_ACTIVE_FILTERS',
  UPDATE_IS_MUTE: 'UPDATE_IS_MUTE',
  UPDATE_ADDITIONAL_SETTINGS_FOR_TRIGGER:
    'UPDATE_ADDITIONAL_SETTINGS_FOR_TRIGGER',
  TOGGLE_ADDITIONAL_SETTINGS: 'TOGGLE_ADDITIONAL_SETTINGS',
  UPDATE_TEMP_ADDITIONAL_SETTING_ROW_DATA:
    'UPDATE_TEMP_ADDITIONAL_SETTING_ROW_DATA',
};

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const FILTER_NAMES = {
  OPERATIONAL_AREA: 'operational-area',
  ROLES_ASSIGNED: 'roles-assigned',
  FUNCTIONAL_AREA: 'functional-area',
  IS_ACTIVE: 'is-active',
};

export const IS_ACTIVE_FILTERS = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 1,
    label: 'Added',
  },
  {
    id: 0,
    label: 'Removed',
  },
];

export const FILTER_METADATA = {
  OPERATIONAL_AREA: {
    ITEM_KEY: 'operationalAreaId',
    ITEM_NAME: 'operationalArea',
  },
  FUNCTIONAL_AREA: {
    ITEM_KEY: 'functionalAreaId',
    ITEM_NAME: 'functionalAreaName',
  },
};

export const DEFAULT_RECURRENCE_VALUE = 15; //minutes

export const ERROR_CODE_LIST = [
  '1008',
  '1009',
  '1010',
  '1051',
  '1023',
  '1060',
  '1013',
  '1062',
  '1090',
  '2064',
  '1055-2',
];

export const FUNCTIONAL_AREA_ID = {
  FH: 1,
  OEE: 2,
  SOC: 3,
  EC: 4,
};

export const DEMO_NOTIFICATION_CODE_TYPES = {
  ERROR: 'errorCode',
  QUALITY_DEFECT: 'qualityDefectCode',
};

export const HIERARCHY_LEVEL = {
  PLANT: 'Plant',
  AREA: 'Area',
  ZONE: 'Zone',
  LINE: 'Line',
  CELL: 'Cell',
  ASSET: 'Asset',
};

export const QUALITY_DEFECT_CODE_LIST = [
  'Jetting',
  'Flow mark',
  'Burr',
  'Short Shot',
  'Poor surface gloss',
  'Crack/Whitening',
  'Air mark',
  'Sink mark/Void',
  'Silver streak',
  'Gas burns/black streaks',
  'Warping/Twisting',
  'Mold dent/Friction scratch',
  'Improper dimensions',
  'Weld line',
];

export const QUALITY_DEFECT_PART_LIST = [
  '1004308-1',
  '1004308-2',
  '1004308-3',
  '1004308-4',
  '1004308-5',
  '1004308-14',
  '1004308-15',
  '1004308-16',
  '1004308-17',
  '1004308-18',
];

export const DEMO_PART_ID = '1004308-1';

export const TEST_IDS = {
  ADD_CUSTOM_NOTIFICATION_BTN: 'add-custom-notification-button',
  NOTIFICATION_CATEGORY_RADIOBTN_GROUP: 'notification-category-radiobtn-grp',
  NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP: 'notification-category-custom-tooltip',
  NOTIFICATION_CATEGORY_CUSTOM_TOOLTIP_MSG:
    'notification-category-custom-tooltip-message',
  COMBO_BOX: 'combo-box',
  BACKDROP: 'backdrop',
  TOOLTIP_ICON: 'tooltip-icon',
  NOTIFICATION_TRIGGER_NAME: 'notification-trigger-name',
  CUSTOM_NOTIFICATION_EDIT_ICON: 'custom-notification-edit-icon',
  CUSTOM_NOTIFICATION_DELETE_ICON: 'custom-notification-delete-icon',
};

export const COMBOBOX_PLACEHOLDER = 'Select or Enter';

export const CUSTOM_NOTIFICATION_METRICS = {
  MODULE: 'module',
  ALERT: 'alert',
  TRIGGER: 'trigger',
};

export const CUSTOM_NOTIFICATION_TYPES = {
  MODULE: 'module',
  DATA_CONDITION: 'dataCondition',
};

export const FEATURE_FLAG_TREATMENTS = {
  ON: 'on',
  OFF: 'off',
};
