import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';
import { CustomTextField } from './style';

const TextField = forwardRef((props, ref) => {
  const {
    value,
    variant,
    error,
    helperText,
    type,
    onChange,
    suffixText = '',
    maxLen = 5,
    placeholder,
    customInputProps = {},
    fullWidth = true,
    disabledGrey = false,
    textAlign = 'left',
    applyLayoutBackgroundColor = false,
    ...other
  } = props;
  return (
    <CustomTextField
      data-testid="smf_textfield"
      disabled={disabledGrey}
      value={value}
      error={error || false}
      helperText={helperText || ''}
      variant={variant || 'outlined'}
      type={type || 'text'}
      onChange={onChange}
      fullWidth={fullWidth}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{suffixText}</InputAdornment>
        ),
      }}
      className="custom-text-field"
      inputProps={{
        maxLength: maxLen,
        form: {
          autocomplete: 'off',
        },
        ...customInputProps,
      }}
      placeholder={placeholder}
      autoComplete="no"
      ref={ref}
      {...other}
      textAlign={textAlign}
      applyLayoutBackgroundColor={applyLayoutBackgroundColor}
    />
  );
});

TextField.displayName = 'TextField';

TextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  suffixText: PropTypes.string,
  maxLen: PropTypes.number,
  placeholder: PropTypes.string,
  customInputProps: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  disabledGrey: PropTypes.bool,
  textAlign: PropTypes.string,
  applyLayoutBackgroundColor: PropTypes.bool,
};

export default TextField;
