import React from 'react';
import {
  StyledHeading,
  StyledHeadingOEE,
  StyledDurationMsg,
  StyledDuration,
} from './style';
import SOCAdditionalSettings from '../SOCAdditionalSettings';
import FHAdditionalSettings from '../FHAdditionalSettings';

import { Grid } from '@mui/material';
import DurationAdditionalSettings from '../DurationAdditionalSettings';
import MESSAGE_STRINGS from '../../../../../constants/en-us';
import { FUNCTIONAL_AREA_ID } from '../../../../../constants';

const TriggerSpecificAdditionalSettings = ({
  trigger = {},
  additionalTempMuteData = {},
  disableSaveBtn,
}) => {
  // use this method to generate other module specific additional settings
  const generateTriggerSpecificSettings = (trigger) => {
    const { functionalAreaId, notificationTriggerId } = trigger;
    if (functionalAreaId && functionalAreaId === FUNCTIONAL_AREA_ID.SOC) {
      const settingsType = notificationTriggerId.split('-')[1];
      return <SOCAdditionalSettings type={settingsType} {...trigger} />;
    } else if (
      functionalAreaId &&
      functionalAreaId === FUNCTIONAL_AREA_ID.OEE
    ) {
      return (
        <Grid
          data-testid="oee-additional-notification-modal-content"
          container
          sx={{
            display: 'flex',
            direction: 'column',
            width: '53.25rem',
            border: '.0625rem solid #40444A',
            borderRadius: '.25rem',
            margin: '1rem 0rem 0rem 0rem',
          }}
        >
          <Grid
            item
            alignItems="center"
            sx={{
              display: 'flex',
              direction: 'row',
              width: '53.25rem',
              height: '3.5rem',
              padding: '.25rem .5rem',
              background: '#232629',
              borderBottom: '.0625rem solid #40444A',
            }}
          >
            <StyledHeadingOEE>{trigger.functionalAreaName}</StyledHeadingOEE>
          </Grid>
          <Grid
            item
            container
            display="flex"
            direction="row"
            justifyContent="flex-start"
            sx={{
              paddingLeft: '.5rem',
              background: '#202020',
              minHeight: '3rem',
            }}
          >
            <Grid
              item
              alignItems="center"
              sx={{
                display: 'flex',
                direction: 'row',
                width: '31.3125rem',
                maxHeight: '3rem',
              }}
            >
              <StyledDurationMsg>
                {
                  MESSAGE_STRINGS.OEE_ADDITIONAL_SETTINGS
                    .TEMP_MUTE_NOTIFICATION_MSG
                }
              </StyledDurationMsg>
            </Grid>
            <Grid
              item
              alignItems="center"
              sx={{
                display: 'flex',
                direction: 'row',
                marginLeft: '5.0625rem',
                maxHeight: '3rem',
              }}
            >
              <StyledDuration>{MESSAGE_STRINGS.DURATION}</StyledDuration>
            </Grid>
            <Grid
              item
              sx={{
                marginTop: '.5625rem',
                marginBottom: '.4375rem',
                display: 'flex',
                maxWidth: '11.25rem',
                marginLeft: '1.25rem',
              }}
            >
              <DurationAdditionalSettings
                data-testid="duration-component-Select-grid"
                timeRangeValue={additionalTempMuteData.timeRangeValue}
                setTimeRangeValue={additionalTempMuteData.setTimeRangeValue}
                timeRangeUnit={additionalTempMuteData.timeRangeUnit}
                setTimeRangeUnit={additionalTempMuteData.setTimeRangeUnit}
                errorMsg={additionalTempMuteData.errorMsg}
                setErrorMsg={additionalTempMuteData.setErrorMsg}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (functionalAreaId === FUNCTIONAL_AREA_ID.FH) {
      return (
        <FHAdditionalSettings {...trigger} disableSaveBtn={disableSaveBtn} />
      );
    }
  };

  return (
    <>
      {trigger && (
        <>
          {trigger.functionalAreaName === 'Overall Equipment Effectiveness' ? (
            <></>
          ) : (
            <StyledHeading>{trigger.functionalAreaName}</StyledHeading>
          )}
          {generateTriggerSpecificSettings(trigger)}
        </>
      )}
    </>
  );
};

export default TriggerSpecificAdditionalSettings;
