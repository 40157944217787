import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RoleTypography = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '12.5rem', // fixed width needed to truncate long text in auto table layout
}));

export { RoleTypography };
