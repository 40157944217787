import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const DurationLabel = styled(Typography)(() => ({
  marginRight: '1rem',
}));

const ValueSelectBox = styled(Box)(() => ({
  marginLeft: '0.625rem',
  minWidth: '4.2rem',
}));

const RecurrenceValueBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const ValueLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isEmpty',
})(({ theme, isEmpty }) => ({
  marginLeft: '1rem',
  marginRight: '0.5rem',
  color: isEmpty ? theme.customColors.darkGrey : theme.customColors.white,
  width: '1rem',
}));

const UnitLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isEmpty',
})(({ theme, isEmpty }) => ({
  marginLeft: '0.5rem',
  marginRight: '0.5rem',
  color: isEmpty ? theme.customColors.darkGrey : theme.customColors.white,
}));

const EmptyValueLabel = styled(Typography)(({ theme }) => ({
  width: '4.4rem',
  color: theme.customColors.white,
  marginLeft: '1rem',
}));

export {
  Container,
  DurationLabel,
  ValueSelectBox,
  RecurrenceValueBox,
  ValueLabel,
  UnitLabel,
  EmptyValueLabel,
};
