import { useEffect, useState } from 'react';
import { Box, Radio } from '@mui/material';
import {
  SliderContainer,
  StyledSlider,
  UnselectedSlider,
  Description,
  SelectionPoints,
  StyledFormControlLabel,
  DescriptionContainer,
} from './style';
import SMFTooltip from '../SMFTooltip';
import MESSAGE_STRINGS from '../../constants/en-us';
import UncheckedRoundIcon from '../../assets/img/roundedUncheckedCheckbox.svg';
import CheckedRoundIcon from '../../assets/img/roundedCheckedCheckbox.svg';

const ConfigSlider = ({
  markSelectionCallback,
  generateTooltip,
  defaultValue,
  marks = [],
  scaleDescription,
  disabledMarks = [],
}) => {
  const [selectedMark, setSelectedMark] = useState({});

  const disableMark = (value) => {
    return disabledMarks.findIndex((mark) => mark === value) > -1;
  };

  const handleMarkSelection = (value) => {
    const selected = marks.find((mark) => mark.value === value);
    setSelectedMark(selected);
    markSelectionCallback(selected);
  };

  useEffect(() => {
    handleMarkSelection(defaultValue);
  }, [defaultValue, marks]);

  const changeMark = (value) => {
    handleMarkSelection(value);
  };

  return (
    <SliderContainer data-testid="slider-container">
      <StyledSlider />
      <UnselectedSlider
        totalMarks={marks.length}
        selectedMark={selectedMark?.key}
        data-testid="unselected-slider"
      />
      {selectedMark && (
        <SelectionPoints data-testid="selection-points">
          {marks.map(({ key, label, value }) => (
            <SMFTooltip
              message={generateTooltip({
                currentMark: { key, label, value },
                selectedMark,
              })}
              key={`${value}-${key}`}
              placement="top"
            >
              <StyledFormControlLabel
                value={value}
                label={label}
                disabled={disabledMarks.length > 0 && disableMark(value)}
                control={
                  <Box>
                    <Radio
                      value={value}
                      name={`${value}-${key}`}
                      checked={selectedMark?.value === value}
                      icon={<UncheckedRoundIcon />}
                      checkedIcon={<CheckedRoundIcon />}
                      onChange={(e) => {
                        e.preventDefault();
                        changeMark(e.target.value);
                      }}
                      disabled={disabledMarks.length > 0 && disableMark(value)}
                      disableRipple
                      inputProps={{
                        'data-testid': `slider-radio-${key}`,
                      }}
                    />
                  </Box>
                }
                labelPlacement="bottom"
              />
            </SMFTooltip>
          ))}
        </SelectionPoints>
      )}

      <DescriptionContainer>
        {selectedMark && (
          <Description variant="caption">
            {scaleDescription}
            <b>
              {selectedMark.key < marks.length
                ? `${selectedMark.label} ${MESSAGE_STRINGS.SOC.AND_ABOVE}`
                : selectedMark.label}
            </b>
          </Description>
        )}
      </DescriptionContainer>
    </SliderContainer>
  );
};

export default ConfigSlider;
