import { styled } from '@mui/material';
import ErrorIconComponent from '../../assets/img/smallErrorIcon.svg';

export const ErrorTextContainer = styled('div', {
  shouldForwardProp: (props) =>
    props !== 'errorTextWidth' && props !== 'alignItems',
})(({ errorTextWidth, alignItems = 'center' }) => ({
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: alignItems,
  maxWidth: errorTextWidth || 'unset',
}));

export const ErrorIcon = styled(ErrorIconComponent)(() => ({
  marginRight: '0.56rem',
  minWidth: '1rem',
  minHeight: '1rem',
}));
