import { useState, useEffect } from 'react';
import MESSAGE_STRINGS from '../../../constants/en-us';
import SMFSelect from '../../../components/SMFSelect';
import { NOTIFICATION_TYPES as Types } from '../../../constants';
import {
  updateNotificationType,
  useTriggerSettingsContext,
} from '../../../context/triggerSettingsContext';
import { NotificationTypeItem, NotificationTypeOption } from './style.js';
import SMFTooltip from '../../../components/SMFTooltip';

const NotificationType = ({
  selectedType = '',
  notificationTypeError = false,
  triggerId,
  isEditing,
}) => {
  const { triggerSettingsDispatch } = useTriggerSettingsContext();

  const [notificationType, setNotifcationType] = useState('');
  const [TooltipMessage, setTooltipMessage] = useState('');

  useEffect(() => {
    switch (selectedType) {
      case Types.ALERT:
        setNotifcationType(MESSAGE_STRINGS.ALERTS_AND_NOTIFICATION_CENTER);
        setTooltipMessage(MESSAGE_STRINGS.ALERTS_AND_NOTIFICATION_CENTER);
        break;
      case Types.NOTIFICATION_CENTER_ONLY:
        setNotifcationType(MESSAGE_STRINGS.NOTIFICATION_CENTER_ONLY);
        setTooltipMessage('');
        break;
      default:
        setNotifcationType(MESSAGE_STRINGS.SELECT);
    }
  }, [selectedType]);

  const handleChange = ({ target: { value } }) => {
    updateNotificationType(triggerSettingsDispatch, triggerId, value);
  };

  const options = [
    {
      value: Types.ALERT,
      label: MESSAGE_STRINGS.ALERTS_AND_NOTIFICATION_CENTER,
      id: Types.ALERT,
      isDisabled: false,
    },
    {
      value: Types.NOTIFICATION_CENTER_ONLY,
      label: MESSAGE_STRINGS.NOTIFICATION_CENTER_ONLY,
      id: Types.NOTIFICATION_CENTER_ONLY,
      isDisabled: false,
    },
  ];
  return (
    <>
      {isEditing ? (
        <SMFTooltip
          message={TooltipMessage}
          placement="top"
          data-testid="notification-type-tooltip"
        >
          <SMFSelect
            value={selectedType || 'SELECT'}
            error={notificationTypeError}
            onChange={handleChange}
            width="13.3rem"
          >
            {options?.length > 0 &&
              options.map(({ label, value, isDisabled }, id) => {
                return (
                  <NotificationTypeOption
                    disabled={isDisabled}
                    value={value}
                    key={`option--${id}-${label}`}
                  >
                    {label || value}
                  </NotificationTypeOption>
                );
              })}
          </SMFSelect>
        </SMFTooltip>
      ) : (
        <NotificationTypeItem data-testid="notification-type">
          {notificationType}
        </NotificationTypeItem>
      )}
    </>
  );
};

export default NotificationType;
