import { MenuItem } from '@mui/material';
import DownArrow from '../../assets/img/downArrow.svg';
import {
  ARROW_ICON_HEIGHT,
  ARROW_ICON_WIDTH,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_PLACEHOLDER_TEXT,
  DROPDOWN_CONSTANTS,
} from './constants';
import * as S from './style';

const DownArrowIcon = (props) => (
  <DownArrow height={ARROW_ICON_HEIGHT} width={ARROW_ICON_WIDTH} {...props} />
);

const Select = ({
  value = '',
  children,
  label,
  width,
  isRequired,
  onClose,
  onOpen,
  placeholderText = DEFAULT_PLACEHOLDER_TEXT,
  borderRadius = DEFAULT_BORDER_RADIUS,
  disabled = false,
  error = false,
  className = 'input-dropdown-container',
  onChange = () => null,
  ...other
}) => {
  const handleClose = () => {
    onClose && onClose();
  };

  const handleOpen = () => {
    onOpen && onOpen();
  };

  const getValue = (placeholderText) => {
    let value = '';
    switch (placeholderText) {
      case DROPDOWN_CONSTANTS.SELECT:
        value = DROPDOWN_CONSTANTS.SELECT;
        break;
      case DROPDOWN_CONSTANTS.HYPHEN:
        value = DROPDOWN_CONSTANTS.HYPHEN;
        break;
      default:
        value = DROPDOWN_CONSTANTS.SELECT;
        break;
    }
    return value;
  };

  return (
    <S.ComponentContainer width={width} className={className}>
      {label && (
        <S.Label aria-label={label}>
          {label}
          {isRequired && <S.RequiredStar>*</S.RequiredStar>}
        </S.Label>
      )}
      <S.CustomizedSelect
        data-testid="input-dropdown"
        defaultValue={DROPDOWN_CONSTANTS.SELECT}
        input={<S.InputBase borderRadius={borderRadius} />}
        IconComponent={DownArrowIcon}
        isDefaultValue={value === DROPDOWN_CONSTANTS.SELECT}
        MenuProps={S.SelectMenuStyleProps}
        onClose={handleClose}
        onOpen={handleOpen}
        {...{ error, onChange, disabled, value }}
        {...other}
      >
        <MenuItem
          disabled
          value={getValue(placeholderText)}
          sx={{ display: 'none' }}
        >
          {placeholderText}
        </MenuItem>
        {children}
      </S.CustomizedSelect>
      {!!error && (
        <S.ErrorTextContainer aria-label="error-message">
          <S.ErrorIcon />
          <span>{error}</span>
        </S.ErrorTextContainer>
      )}
    </S.ComponentContainer>
  );
};

export default Select;
