import { Box, Typography } from '@mui/material';
import MESSAGE_STRINGS from '../../../constants/en-us';
import React from 'react';

const DefaultEmptyTable = () => (
  <Box
    sx={{
      position: 'relative',
      display: 'flex',
      minHeight: '29rem',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Typography>{MESSAGE_STRINGS.NO_DATA_PRESENT}</Typography>
  </Box>
);

export default DefaultEmptyTable;
