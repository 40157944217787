import * as yup from 'yup';
import { Durations, Errors, UnitsOfMeasurement } from '../constants';

const testRange = (value) => {
  const MIN_DURATION = 4;
  const MAX_DURATION = 48;

  return +value >= MIN_DURATION && +value <= MAX_DURATION;
};

async function snoozeDurationTest(value, context) {
  if (context.parent.snoozeDurationUoM === UnitsOfMeasurement.HOURS) {
    if (value <= Durations.MAX_DURATION_HOUR) return true;
    return this.createError({
      path: 'snoozeDuration',
      message: Errors.MAX_DURATION_ERROR,
    });
  } else if (context.parent.snoozeDurationUoM === UnitsOfMeasurement.MINUTES) {
    if (
      value > Durations.MIN_DURATION_MIN &&
      value <= Durations.MAX_DURATION_MIN
    )
      return true;
    if (value > Durations.MAX_DURATION_MIN)
      return this.createError({
        path: 'snoozeDuration',
        message: Errors.MAX_DURATION_MINS_ERROR,
      });
    return this.createError({
      path: 'snoozeDuration',
      message: Errors.MIN_DURATION_ERROR,
    });
  }
}

export const buildNotificationTimingValidationSchema = yup.object({
  durationBanner: yup
    .string()
    .required(Errors.REQUIRED)
    .test('test-range-banner', Errors.SECONDS_ERROR, testRange),
  durationCenter: yup
    .string()
    .required(Errors.REQUIRED)
    .test('test-range-notification-center', Errors.HOURS_ERROR, testRange),
  snoozeDuration: yup
    .number()
    .positive()
    .moreThan(0)
    .typeError(Errors.REQUIRED)
    .required(Errors.REQUIRED)
    .test({
      name: 'test-snooze',
      test: snoozeDurationTest,
    }),
});
