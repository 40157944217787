import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { Box, InputAdornment, Checkbox } from '@mui/material';
import SearchIcon from '../../assets/img/searchIcon.svg';
import CheckedBoxIcon from '../../assets/img/checkedBox.svg';
import ClearIcon from '../../assets/img/clearIcon.svg';
import MESSAGE_STRINGS from '../../constants/en-us';
/*
 ** This component is used to render checkboxes, search bar and action buttons within a dropdown menu or a filter popover
 */
import {
  ButtonsContainer,
  Title,
  ResetButton,
  Container,
  ButtonLabel,
  ApplyButton,
  CheckboxLabel,
  StyledDivider,
  SearchBar,
  UncheckedBoxIcon,
  CheckBoxContainer,
  BoldText,
} from './style.js';

const MultiSelectDropdownFilter = ({
  filterProps,
  onChangeFunc,
  itemDropDownValues,
  maxChar,
  popOverTitle,
  showSearchBar = true,
  showFilterName = true,
  filtersByName,
}) => {
  const getInitialCheckedValues = (itemDropDownValues, filtersList) => {
    let selectedItems = [];
    if (itemDropDownValues?.length) {
      for (const item of itemDropDownValues) {
        selectedItems.push(item);
      }
      if (itemDropDownValues.length === filtersList.length - 1) {
        selectedItems.push('all');
      }
    }
    return selectedItems;
  };

  const [checked, setChecked] = useState(
    getInitialCheckedValues(itemDropDownValues, filterProps.filtersList)
  ); // ? contains the selected values.
  const [filtersList, setFiltersList] = useState([]); // ? contains the list populating the UI.
  const [searchText, setSearchText] = useState('');
  const [checkedAll, setCheckedAll] = useState(true);

  const filtersListObj = useMemo(
    () => ({
      ...filterProps,
      filtersList: uniqBy(filterProps.filtersList, 'id'),
    }),
    [filterProps]
  );

  const closeHandler = () => {
    setChecked(itemDropDownValues);
    setSearchText('');
  };

  const getFiltersToApply = () => {
    const applyList = [];
    checked.forEach((option) => {
      if (
        filtersList.findIndex((obj) => obj.id === option) !== -1 &&
        option !== 'all'
      ) {
        applyList.push(option);
      }
    });
    return applyList;
  };
  const onReset = () => {
    onChangeFunc([], 'reset', filtersListObj.filtersName);
    closeHandler();
  };

  const onApply = () => {
    onChangeFunc(getFiltersToApply(), 'apply', filtersListObj.filtersName);
    closeHandler();
  };

  const onKeyDown = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (filtersListObj?.filtersList?.length > 0) {
      setFiltersList(filtersListObj.filtersList);
    }
  }, [filtersListObj]);

  useEffect(() => {
    const checkArray = [];
    filtersList.forEach((item) => {
      if (item.id !== 'all') {
        checkArray.push(checked?.includes(item.id));
      }
    });
    setCheckedAll(!checkArray.includes(false));
  }, [checked, filtersList]);

  const handleToggle = (filterObj, eve) => {
    let newChecked = [];
    if (filterObj.id === 'all') {
      if (eve.target.checked) {
        const allFilterslist = filtersListObj.filtersList;
        allFilterslist.forEach((item) => {
          newChecked.push(item.id);
        });
      }
    } else {
      const currentIndex = checked?.indexOf(filterObj.id);
      newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(filterObj.id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
    }
    setChecked(newChecked);
  };

  const StyledCheckbox = (propsCheckbox) => (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<CheckedBoxIcon />}
      icon={<UncheckedBoxIcon />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...propsCheckbox}
    />
  );

  const getBoldLabel = (label, searchString) => {
    const boldString = label?.substring(0, searchString.length);
    const splitString = label?.split(boldString);
    return (
      <>
        <BoldText>{boldString}</BoldText>
        {splitString}
      </>
    );
  };

  return (
    <Box>
      <Container
        showSearchBar={showSearchBar}
        data-testid={`${filterProps.filtersName}-container`}
      >
        <Title isMainTitle>{popOverTitle}</Title>
        {showSearchBar && (
          <Box>
            <SearchBar
              id="searchfield"
              data-testid="searchfield"
              placeholder="Search"
              variant="filled"
              sx={{ width: '100%', marginTop: '1rem' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                ...(searchText.length > 0 && {
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setSearchText('')}
                      sx={{ cursor: 'pointer' }}
                      data-testid="search-clear"
                    >
                      <ClearIcon />
                    </InputAdornment>
                  ),
                }),
                disableUnderline: true,
              }}
              inputProps={{
                maxLength: maxChar || 128,
              }}
              onChange={(event) => setSearchText(event.target.value)}
              value={searchText}
              onKeyDown={onKeyDown}
            />
            <StyledDivider isTopDivider />
          </Box>
        )}
        {showFilterName && (
          <Box>
            <Title>{filtersByName}</Title>
          </Box>
        )}
        <Box
          sx={{ overflowY: 'scroll', marginTop: '0.938rem' }}
          data-testid="checkbox-options"
        >
          {filtersList
            .filter(
              (filterListItem, idx, filterListArr) =>
                filterListArr.findIndex((t) => t.id === filterListItem.id) ===
                idx
            )
            .map((value, idx) => {
              const idxKey = idx + 1;
              return (
                String(value.label)
                  .toLowerCase()
                  .startsWith(searchText && searchText.toLowerCase()) && (
                  <CheckBoxContainer
                    id={`item-${idxKey}`}
                    key={`${value.id}-${idxKey}`}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <StyledCheckbox
                      id={`checkbox-${idxKey}`}
                      data-testid={`checkbox-${idxKey}`}
                      checked={
                        value.id === 'all'
                          ? checkedAll
                          : checked?.indexOf(value.id) !== -1
                      }
                      onClick={(event) => handleToggle(value, event)}
                    />
                    <CheckboxLabel>
                      {searchText
                        ? getBoldLabel(value.label, searchText)
                        : value.label}
                    </CheckboxLabel>
                  </CheckBoxContainer>
                )
              );
            })}
        </Box>

        <Box style={{ marginTop: 'auto' }}>
          <StyledDivider />
          <ButtonsContainer>
            <ResetButton
              id="reset"
              data-testid="filter-reset"
              onClick={() => onReset()}
              variant="outlined"
            >
              <ButtonLabel isWhite>{MESSAGE_STRINGS.RESET}</ButtonLabel>
            </ResetButton>
            <ApplyButton
              id="apply"
              data-testid="filter-apply"
              onClick={() => onApply()}
            >
              {MESSAGE_STRINGS.APPLY}
            </ApplyButton>
          </ButtonsContainer>
        </Box>
      </Container>
    </Box>
  );
};

MultiSelectDropdownFilter.propTypes = {
  filterProps: PropTypes.shape(),
  onChangeFunc: PropTypes.func,
  itemDropDownValues: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  popOverTitle: PropTypes.string,
  showSearchBar: PropTypes.bool,
  showFilterName: PropTypes.bool,
  filtersByName: PropTypes.string,
};

export default MultiSelectDropdownFilter;
