import { FEATURE_FLAG_TREATMENTS } from '../../constants';

export const getFilteredAllTriggersList = (
  triggersList,
  selectedFAFilterIds,
  selectedIAFilters,
  customNotificationFeature
) => {
  const filteredList = triggersList.filter(
    (trigger) =>
      selectedFAFilterIds?.includes(trigger?.functionalAreaId) &&
      selectedIAFilters?.includes(trigger?.isActive && trigger?.isEnabled) &&
      ((trigger?.isCustom &&
        customNotificationFeature === FEATURE_FLAG_TREATMENTS.ON) ||
        !trigger?.isCustom)
  );
  return filteredList;
};
