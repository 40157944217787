import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/x-tree-view/TreeView';
import PropTypes from 'prop-types';
import React from 'react';

import { HIERARCHY_LEVEL } from '../../constants/index';
import CustomTreeItemWithIcon from '../CustomTreeItemWithIcon/index.jsx';
import { CustomTreeItem } from './style.js';

const getTreeItemLabel = (node) =>
  node.entityType === HIERARCHY_LEVEL.PLANT
    ? `Plant: ${node?.entityName}`
    : `${node?.entityNumber} - ${node?.entityName}`;

export const renderTreeNodes = (nodes, handleHierarchy) => {
  return nodes.map((node) =>
    node.entityType !== HIERARCHY_LEVEL.ASSET ? (
      <CustomTreeItem
        key={node?.entityId}
        nodeId={`${node?.entityId}`}
        label={getTreeItemLabel(node)}
        isBold={node.entityType !== HIERARCHY_LEVEL.CELL}
        data-testid="notification-config-tree-item"
      >
        {node?.entityChildren &&
          renderTreeNodes(node?.entityChildren, handleHierarchy)}
      </CustomTreeItem>
    ) : (
      <CustomTreeItemWithIcon
        key={node?.entityId}
        nodeId={`${node?.entityId}`}
        labelText={`${node?.entityNumber} - ${node?.entityName}`}
        node={node}
        handleHierarchy={handleHierarchy}
        data-testid="notification-config-tree-item-with-icon"
      />
    )
  );
};

const RenderTreeData = ({
  data,
  handleHierarchy,
  nodeIds,
  setExpandedNodeIds,
}) => {
  const handleToggle = (event, nodeIds) => {
    setExpandedNodeIds(nodeIds);
  };
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon fontSize="large" />}
      defaultExpandIcon={<ChevronRightIcon fontSize="large" />}
      expanded={nodeIds}
      data-testid="notification-config-tree-container"
      onNodeToggle={handleToggle}
    >
      {renderTreeNodes([data], handleHierarchy)}
    </TreeView>
  );
};

RenderTreeData.propTypes = {
  data: PropTypes.shape({
    entityId: PropTypes.number,
    entityNumber: PropTypes.string,
    entityType: PropTypes.string,
    parentEntityId: PropTypes.number,
    entityName: PropTypes.string,
    entityHierarchy: PropTypes.string,
    hierarchyLevel: PropTypes.number,
    entityChildren: PropTypes.instanceOf(Array),
    entityAutoId: PropTypes.number,
    fileStatus: PropTypes.string,
  }),
  handleHierarchy: PropTypes.func,
  nodeIds: PropTypes.arrayOf(PropTypes.string),
  setExpandedNodeIds: PropTypes.func,
};

export default RenderTreeData;
