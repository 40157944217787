import { MuteButton, Muted, Unmuted } from './style.js';
import { useState, useEffect } from 'react';
import {
  updateIsMute,
  useTriggerSettingsContext,
} from '../../../context/triggerSettingsContext';

const Mute = ({ value, triggerId, isEditing }) => {
  const { triggerSettingsDispatch } = useTriggerSettingsContext();

  const [isMute, setIsMute] = useState(value);

  useEffect(() => {
    setIsMute(value);
  }, [value]);

  const handleMute = () => {
    setIsMute(!isMute);
    updateIsMute(triggerSettingsDispatch, triggerId, !value);
  };

  return (
    <MuteButton
      onClick={handleMute}
      disabled={!isEditing}
      data-testid="mute-button"
    >
      {isMute ? (
        <Muted alt="mutedIcon" data-testid="mute-image" />
      ) : (
        <Unmuted alt="unmutedIcon" data-testid="unmute-image" />
      )}
    </MuteButton>
  );
};

export default Mute;
