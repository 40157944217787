import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import LoadingIndicator from '../../../components/Loader';
import {
  BackdropContainer,
  CustomBackdrop,
} from '../CustomNotificationConfig/style';
import { StyledDialogContentBox, StyledDialogContentTypography } from './style';
import MESSAGE_STRINGS from '../../../constants/en-us';

const DeleteCustomNotificationModalContent = ({
  isCustomNotificationIsBeingDeleted,
}) => {
  return (
    <StyledDialogContentBox>
      <Typography>
        {
          MESSAGE_STRINGS.DELETE_CUSTOM_NOTIFICATION_DIALOG_CONTENT
            .ALERT_MESSAGE
        }
      </Typography>
      <StyledDialogContentTypography>
        {
          MESSAGE_STRINGS.DELETE_CUSTOM_NOTIFICATION_DIALOG_CONTENT
            .CONFIRMATION_QUESTION
        }
      </StyledDialogContentTypography>
      <BackdropContainer>
        <CustomBackdrop open={isCustomNotificationIsBeingDeleted}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </StyledDialogContentBox>
  );
};

export default DeleteCustomNotificationModalContent;

DeleteCustomNotificationModalContent.propTypes = {
  isCustomNotificationIsBeingDeleted: PropTypes.bool,
};
