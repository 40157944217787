import { notification } from '@smf/ui-util-app';

export async function getActiveTriggersForPlant(plantId) {
  const result = await notification.getActiveTriggersForPlant(plantId);
  return result;
}

export async function getNotificationTriggersList(plantId) {
  const result = await notification.getNotificationTriggersList(plantId);
  return result;
}

export async function getAllRolesForPlant(plantId) {
  const result = await notification.getAllRolesForPlant(plantId);
  return result;
}

export async function saveTriggersForPlant(plantId, notifications) {
  const result = await notification.saveTriggersForPlant({
    plantId,
    notifications,
  });
  return result;
}

export async function getOperationalAreas() {
  const result = await notification.getOperationalAreas();
  return result;
}

export async function getNotificationTimings(plantId) {
  const result = await notification.getNotificationTimings(plantId);
  return result;
}

export async function updateNotificationTimings(body) {
  const result = await notification.updateNotificationTimings(body);
  return result;
}

export async function getSchedule(plantId) {
  const result = await notification.getSchedule(plantId);
  return result;
}

export async function mockECNotification(body) {
  const result = await notification.mockECNotification(body);
  return result;
}

export async function getEquipmentHierarchy(queryParams) {
  return await notification.getEquipmentHierarchy(queryParams);
}

export async function getCustomNotificationDetails(queryParams) {
  return await notification.getCustomNotificationDetails(queryParams);
}

export async function getCustomNotificationSettings(queryParams) {
  return await notification.getCustomNotificationSettings(queryParams);
}

export async function saveCustomNotification(body) {
  const result = await notification.saveCustomNotification(body);
  return result;
}

export async function deleteCustomNotification(queryParams) {
  return await notification.deleteCustomNotification(queryParams);
}
