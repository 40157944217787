import {
  Paper,
  TableBody,
  TableCell,
  Table,
  TableRow,
  CircularProgress,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import DefaultEmptyTable from '../DefaultEmptyTable';
import {
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableBodyRow,
} from '../style';

const BasicTableEmptyUI = ({ EmptyComponent }) => {
  return (
    <TableRow key="fakeBody">
      <TableCell colSpan={100} sx={{ padding: 0 }}>
        <EmptyComponent />
      </TableCell>
    </TableRow>
  );
};

BasicTableEmptyUI.propTypes = {
  EmptyComponent: PropTypes.func,
};

BasicTableEmptyUI.defaultProps = {
  EmptyComponent: DefaultEmptyTable,
};

const BasicTableFetchingUI = () => {
  return (
    <TableBody className="basicGeneralTable_body">
      <TableRow key="fakeBody">
        <TableCell colSpan={100} sx={{ padding: 0 }}>
          <Box
            sx={{
              position: 'relative',
              minHeight: '29rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const MissingRows = ({ enableMissingRows = true, noOfMissing = 0 }) => {
  const missingRows = [];

  if (enableMissingRows) {
    const missingRowLength = noOfMissing;
    missingRows.length = missingRowLength < 0 ? 0 : missingRowLength;
    missingRows.fill(1);
  }

  return (
    <>
      {missingRows.map((_row, index) => (
        <StyledTableRow key={`missingrow-${index + 1}`}>
          <StyledTableCell colSpan={100} rowSpan={1}>
            &#8205;
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

MissingRows.propTypes = {
  enableMissingRows: PropTypes.bool,
  noOfMissing: PropTypes.number,
};
MissingRows.defaultProps = {
  enableMissingRows: true,
  noOfMissing: 0,
};
// Just a presentational component
const BasicGeneralTable = ({
  headerGroups = [],
  rows = [],
  showHeader = true,
  showFetchingUI = false,
  showErrorUI = false,
  ErrorComponent = null,
  EmptyComponent,
  state,
  enablePagination,
  hideBorder = false,
}) => (
  <StyledTableContainer
    component={Paper}
    className="basicGeneralTable"
    hideBorder={hideBorder}
    data-testid="basicGeneralTable"
  >
    <Table stickyHeader>
      {showHeader && (
        <StyledTableHead className="basicGeneralTable_header">
          {headerGroups.map((headerGroup) => (
            <StyledTableRow
              {...headerGroup.getHeaderGroupProps()}
              className="basicGeneralTable_headerRow"
            >
              {headerGroup.headers.map((column) => (
                <StyledTableCell
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                    },
                  })}
                >
                  {column.render('Header')}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableHead>
      )}

      {showErrorUI && ErrorComponent && <ErrorComponent />}
      {showFetchingUI ? (
        <BasicTableFetchingUI />
      ) : (
        <TableBody className="basicGeneralTable_body">
          {rows.length > 0 &&
            rows.map((row, i) => (
              <StyledTableBodyRow
                {...row.getRowProps()}
                className="basicGeneralTable_bodyRow"
                data-testid="basicGeneralTable_bodyRow"
              >
                {row.cells.map((cell) =>
                  cell.column.enableRowSpan && i !== 0 ? null : (
                    <StyledTableCell
                      data-testid="basicGeneralTable_bodyCell"
                      {...cell.getCellProps()}
                      rowSpan={`${cell.column.rowSpan ?? 1}`}
                      className="basicGeneralTable_bodyCell"
                      id="basicGeneralTable_bodyCell"
                    >
                      {cell.render('Cell')}
                    </StyledTableCell>
                  )
                )}
              </StyledTableBodyRow>
            ))}

          {rows.length > 0 && (
            <MissingRows
              enableMissingRows={enablePagination && !showFetchingUI}
              noOfMissing={state.pageSize - rows.length}
            />
          )}

          {rows.length === 0 && (
            <BasicTableEmptyUI EmptyComponent={EmptyComponent} />
          )}
        </TableBody>
      )}
    </Table>
  </StyledTableContainer>
);

BasicGeneralTable.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.shape({})),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  showHeader: PropTypes.bool,
  showFetchingUI: PropTypes.bool,
  showErrorUI: PropTypes.bool,
  ErrorComponent: PropTypes.func,
  EmptyComponent: PropTypes.func,
  enablePagination: PropTypes.bool,
  state: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  hideBorder: PropTypes.bool,
};
BasicGeneralTable.defaultProps = {
  headerGroups: [],
  rows: [],
  showHeader: true,
  showFetchingUI: false,
  showErrorUI: false,
  ErrorComponent: null,
  EmptyComponent: DefaultEmptyTable,
  state: null,
  enablePagination: false,
  hideBorder: false,
};

export default BasicGeneralTable;
