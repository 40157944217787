import { useEffect, useState } from 'react';
import TextField from '../../../../../components/TextField';
import SMFSelect from '../../../../../components/SMFSelect';
import { MenuItem } from '@mui/material';
import MESSAGE_STRINGS from '../../../../../constants/en-us';
import {
  updateTempAdditionalSettings,
  useTriggerSettingsContext,
} from '../../../../../context/triggerSettingsContext';
import ErrorMessage from '../../../../../components/ErrorMessage';
import { restrictInputInNumericField } from '../../../helper';

import {
  ConfigContainer,
  Description,
  Container,
  DurationLabel,
  ControlsContainer,
  InputContainer,
  SelectContainer,
  ErrorContainer,
  FlexContainer,
} from './style';

const FHAdditionalSettings = ({
  addlSettingsActive = true,
  tempMuteUoM = '',
  tempMuteValue = '',
  triggerId,
  disableSaveBtn,
}) => {
  const { triggerSettingsDispatch } = useTriggerSettingsContext();
  const [duration, setDuration] = useState('');
  const [unit, setUnit] = useState('');
  const [isDurationError, setIsDurationError] = useState(false);
  const [durationErrorMessage, setDurationErrorMessage] = useState('');

  useEffect(() => {
    disableSaveBtn(isDurationError);
  }, [isDurationError]);

  useEffect(() => {
    setDuration(tempMuteValue);
  }, [tempMuteValue]);

  useEffect(() => {
    setUnit(tempMuteUoM);
  }, [tempMuteUoM]);

  const muteDurationTest = (duration, unit) => {
    const MAX_MINUTES = 180;
    const MAX_SECONDS = 10800;
    const MAX_HOURS = 3;

    if (unit === MESSAGE_STRINGS.HOURS) {
      if (duration <= MAX_HOURS) {
        return { isError: false, message: '' };
      } else {
        return {
          isError: true,
          message: MESSAGE_STRINGS.FH_ADDITIONAL_SETTINGS_ERRORS.HOURS,
        };
      }
    } else if (unit === MESSAGE_STRINGS.MINUTES) {
      if (duration <= MAX_MINUTES) {
        return { isError: false, message: '' };
      } else {
        return {
          isError: true,
          message: MESSAGE_STRINGS.FH_ADDITIONAL_SETTINGS_ERRORS.MINUTES,
        };
      }
    } else if (unit === MESSAGE_STRINGS.SECONDS) {
      if (duration <= MAX_SECONDS) {
        return { isError: false, message: '' };
      } else {
        return {
          isError: true,
          message: MESSAGE_STRINGS.FH_ADDITIONAL_SETTINGS_ERRORS.SECONDS,
        };
      }
    } else {
      return { isError: false, message: '' };
    }
  };

  const handleUnitChange = ({ target: { value } }) => {
    setUnit(value);
    const { isError, message } = muteDurationTest(duration, value);
    setIsDurationError(isError);
    setDurationErrorMessage(message);
    if (!isError) {
      updateTempAdditionalSettings(triggerSettingsDispatch, {
        triggerId,
        addlSettingsActive,
        tempMuteValue: duration,
        tempMuteUoM: value === 'SELECT' ? null : value,
      });
    }
  };
  const handleDurationChange = ({ target: { value } }) => {
    setDuration(value);
    const { isError, message } = muteDurationTest(value, unit);
    setIsDurationError(isError);
    setDurationErrorMessage(message);
    if (!isError) {
      updateTempAdditionalSettings(triggerSettingsDispatch, {
        triggerId,
        addlSettingsActive,
        tempMuteValue: value,
        tempMuteUoM: unit === 'SELECT' ? null : unit,
      });
    }
  };

  return (
    <Container>
      <Description>{MESSAGE_STRINGS.FH_ADDITIONAL_SETTINGS_DESC}</Description>
      <ConfigContainer>
        <DurationLabel>{MESSAGE_STRINGS.DURATION}</DurationLabel>
        <ControlsContainer>
          <FlexContainer>
            <InputContainer>
              <TextField
                data-testid="mute-duration"
                value={duration}
                onChange={handleDurationChange}
                type="number"
                onKeyPress={restrictInputInNumericField}
                error={isDurationError}
              />
            </InputContainer>
            <SelectContainer>
              <SMFSelect
                name="muteDurationUoM"
                value={unit || 'SELECT'}
                onChange={handleUnitChange}
              >
                <MenuItem value={MESSAGE_STRINGS.SECONDS}>
                  {MESSAGE_STRINGS.SECONDS}
                </MenuItem>
                <MenuItem value={MESSAGE_STRINGS.MINUTES}>
                  {MESSAGE_STRINGS.MINUTES}
                </MenuItem>
                <MenuItem value={MESSAGE_STRINGS.HOURS}>
                  {MESSAGE_STRINGS.HOURS}
                </MenuItem>
              </SMFSelect>
            </SelectContainer>
          </FlexContainer>
          {isDurationError && (
            <ErrorContainer data-testid="mute-duration-error">
              <ErrorMessage text={durationErrorMessage} />
            </ErrorContainer>
          )}
        </ControlsContainer>
      </ConfigContainer>
    </Container>
  );
};

export default FHAdditionalSettings;
