import { styled, Typography, Paper, Box } from '@mui/material';
import Input from '../../components/Input';
import InfoIconComponent from '../../assets/img/infoIcon.svg';
import SearchInput from '../../components/InputDropdown';

export const DemoNotificationInputRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isErrorState',
})(({ isErrorState, theme }) => ({
  fontSize: '0.875rem',
  padding: '0 1.25rem',
  height: isErrorState ? '6rem' : '3rem',
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  backgroundColor: theme.palette.background.blackGrey,
}));

export const DemoNotificationHeadingRow = styled('div')(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0 1.25rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  backgroundColor: theme.palette.background.configLighterDark,
}));

export const DemoNotificationSubHeadingRow = styled('div')(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0 1.25rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  backgroundColor: theme.palette.background.darkishBlackBlue,
}));

export const Description = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const DescriptionBold = styled('span')(() => ({
  fontSize: '0.875rem',
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 3rem',
}));

export const StyledBoxWithScroll = styled(Box)(() => ({
  padding: '0 3rem',
  paddingX: '4.25rem',
  display: 'flex',
  alignItems: 'baseline',
  maxHeight: '30rem',
  overflowY: 'auto',
}));

export const ConfigurationContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '23rem',
  alignItems: 'flex-start',
}));

export const BaseInput = styled(Input)(() => ({
  '& input': {
    width: '3.8rem',
  },

  '& div': {
    display: 'none',
  },
}));

export const ErrorCodeInput = styled(SearchInput)(() => ({
  '& input': {
    width: '10rem',
    marginRight: '1.625rem',
    marginTop: '0.4rem',
  },
}));

export const DisplayInInput = styled(BaseInput)(() => ({
  marginRight: '0.59rem',
}));

export const DurationCenterInput = styled(BaseInput)(() => ({
  marginRight: '1.625rem',
}));

export const SendButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '3.5rem',
  padding: '0.5rem',
}));

export const DisplayIn = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1.625rem',
  marginLeft: 'auto',
}));

export const ErrorContainer = styled('div')(() => ({
  minHeight: '3.5rem',
  marginRight: 'auto',
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
}));

export const FlexContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

export const ColumnFlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LabelConfigContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '0.5rem',
  alignItems: 'baseline',
}));

export const ErrorCodeContainer = styled('div')(() => ({
  width: '15rem',
  display: 'flex',
}));

export const InfoIcon = styled(InfoIconComponent)(() => ({
  marginLeft: '0.56rem',
  marginTop: '0.45rem',
}));

export const NotificationTimingContainer = styled('div')(() => ({
  display: 'flex',
  width: '15rem',
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    borderColor: theme.palette.background.blackGrey,
    backgroundColor: theme.palette.background.elevationGrey,
    color: theme.palette.text.primary,
    outline: 'none',
    borderRadius: '0.25rem',
    margin: '0rem',
  },
}));

export const StyledModalHeader = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '700',
  lineHeight: '1.6875rem',
  color: theme.customColors.white,
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
}));

export const StyleModalHeaderContent = styled('span')(() => ({
  verticalAlign: 'super',
  marginLeft: '1.8rem',
}));

export const ModalContent = styled('div')(() => ({
  padding: '0 0 0 4rem',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.875rem',
}));

export const ModalContentSpan = styled('span')(() => ({
  paddingTop: '1.2rem',
}));

export const ModalContentSpanPB = styled('span')(() => ({
  paddingBottom: '1.2rem',
}));

export const StyledSearchInput = styled(SearchInput)(() => ({
  textTransform: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
