import PropTypes from 'prop-types';
import { alpha, Dialog, styled } from '@mui/material';
import GeneralModalPaper from '../GeneralModalPaper';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderColor: theme.palette.background.blackGrey,
  background: alpha(theme.palette.background.paper, 0.8),
}));

const CustomPaper = (props) => {
  return <GeneralModalPaper isNotificationsModal={true} {...props} />;
};

export function GeneralDialog({
  open,
  fullWidth = true,
  maxWidth = 'sm',
  isNotificationsModal = false,
  children,
  ...other
}) {
  return (
    <StyledDialog
      data-testid="general-dialog-component"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperComponent={isNotificationsModal ? CustomPaper : GeneralModalPaper}
      {...other}
    >
      {children}
    </StyledDialog>
  );
}

GeneralDialog.propTypes = {
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  isNotificationsModal: PropTypes.bool,
  children: PropTypes.node,
};
