export const Errors = {
  SECONDS_ERROR: 'Please enter a value between 4 hours and 48 seconds',
  HOURS_ERROR: 'Value must be between 4 hours and 48 hours.',
  NO_UOM_ERROR: 'Please enter a value between 4 hours and 48',
  MAX_DURATION_ERROR: 'The value must be less than 24 hours',
  MIN_DURATION_ERROR: 'The value must be greater than 5 mins',
  MAX_DURATION_MINS_ERROR: 'The value must be less than 1440 mins',
  REQUIRED: 'Required field',
};

export const UnitsOfMeasurement = {
  SECONDS: 'Seconds',
  MINUTES: 'Minutes',
  HOURS: 'Hours',
};

export const Durations = {
  MIN_DURATION_MIN: 5,
  MAX_DURATION_HOUR: 24,
  MAX_DURATION_MIN: 1440,
};
