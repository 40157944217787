import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { Grid, MenuItem } from '@mui/material';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/SMFSelect';
import ErrorMessage from '../../../../../components/ErrorMessage';
import MESSAGE_STRINGS from '../../../../../constants/en-us';

const DurationAdditionalSettings = ({
  onChange = () => {},
  timeRangeValue,
  setTimeRangeValue,
  timeRangeUnit,
  setTimeRangeUnit,
  errorMsg,
  setErrorMsg,
}) => {
  useEffect(() => {
    isTimeValueError(timeRangeValue, timeRangeUnit);
  }, [timeRangeValue, timeRangeUnit]);

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value % 1 == 0 && value >= 0 && value <= 99999 && value.length < 6) {
      setTimeRangeValue(value);
    }
  };
  const handleSelectChange = (e) => {
    const value = e.target.value;

    setTimeRangeUnit(value);
  };
  const isTimeValueError = (timeValue, timeRangeUnitVal) => {
    if (timeRangeUnitVal == MESSAGE_STRINGS.HOURS && parseInt(timeValue) > 3) {
      setErrorMsg(MESSAGE_STRINGS.OEE_ADDITIONAL_SETTINGS.TEMP_MUTE_HOUR_ERROR);
    } else if (
      timeRangeUnitVal == MESSAGE_STRINGS.MINUTES &&
      parseInt(timeValue) > 180
    ) {
      setErrorMsg(
        MESSAGE_STRINGS.OEE_ADDITIONAL_SETTINGS.TEMP_MUTE_MINUTES_ERROR
      );
    } else if (
      timeRangeUnitVal == MESSAGE_STRINGS.SECONDS &&
      parseInt(timeValue) > 10800
    ) {
      setErrorMsg(
        MESSAGE_STRINGS.OEE_ADDITIONAL_SETTINGS.TEMP_MUTE_SECONDS_ERROR
      );
    } else {
      setErrorMsg('');
    }
  };
  return (
    <Grid
      data-testid="additional-duration-component"
      container
      direction="column"
      sx={{}}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        data-testid="duration-component"
      >
        <Grid
          data-testid="duration-component-input-grid"
          item
          sx={{
            marginRight: '.6875rem',
          }}
        >
          <Input
            data-testid="mute-duration"
            name="inp"
            width="3rem"
            height="2rem"
            padding="0.6875rem 0rem 0.6875rem 0.5rem"
            value={timeRangeValue}
            placeholder="-"
            onChange={handleInputChange}
            register={onChange}
            error={errorMsg}
            errorBox={false}
          />
        </Grid>
        <Grid item data-testid="duration-component-Select-grid">
          <Select
            data-testid="mute-duration-unit"
            width="7.25rem"
            defaultValue="Select"
            value={timeRangeUnit || 'Select'}
            border=".0625rem solid #97999B"
            borderRadius="1.25rem"
            placeholderText="Select"
            onChange={handleSelectChange}
          >
            <MenuItem disabled value="Select">
              {'Select'}
            </MenuItem>
            {MESSAGE_STRINGS.TIME_UNIT_ARRAY.map((timeunitkey) => (
              <MenuItem id={timeunitkey} key={timeunitkey} value={timeunitkey}>
                {timeunitkey}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      {!!errorMsg && (
        <Grid
          data-testid="mute-duration-error"
          item
          sx={{
            maxWidth: '10.9375rem',
            paddingBottom: '.9375rem',
            margin: '.625rem 0rem .4375rem 0rem',
          }}
        >
          <ErrorMessage
            text={errorMsg}
            errorTextWidth="9.0625rem"
            alignItems="start"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DurationAdditionalSettings;

DurationAdditionalSettings.propTypes = {
  onChange: PropTypes.func,
  timeRangeValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setTimeRangeValue: PropTypes.func,
  timeRangeUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setTimeRangeUnit: PropTypes.func,
  errorMsg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setErrorMsg: PropTypes.func,
};
