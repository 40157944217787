import { Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';

const SettingsContainer = styled(Box)(() => ({
  padding: '1.5rem 0.625rem 2.5rem',
}));

const GridContainer = styled(Grid)(() => ({
  paddingTop: '0.625rem',
}));

const Instruction = styled(Typography)(() => ({
  fontWeight: '400',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
}));

const StyledHeading = styled(Typography)(() => ({
  fontSize: '0.875rem',
  fontWeight: '700',
  lineHeight: '1.25rem',
}));

export { SettingsContainer, StyledHeading, GridContainer, Instruction };
