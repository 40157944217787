/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import useSMFTable from './useSMFTable';
import BasicGeneralTable from './BasicGeneralTable';
import BasicTableError from './BasicTableError';
import DefaultEmptyTable from './DefaultEmptyTable';

export const SMFTableContext = React.createContext();
export const useSMFTableContext = () => React.useContext(SMFTableContext);

const GeneralTable = ({
  data = [],
  columns = [],
  isLoading,
  isError,
  showHeader = true,
  EmptyComponent,
  enablePagination = false,
  tableInitState = null,
  mappedMethods = {},
  hideBorder = false,
}) => {
  const instance = useSMFTable({
    data,
    columns,
    isPaginated: !!enablePagination,
    initialState: tableInitState,
  });

  return (
    <SMFTableContext.Provider value={mappedMethods}>
      <BasicGeneralTable
        {...instance}
        showHeader={showHeader}
        showFetchingUI={isLoading}
        enablePagination={enablePagination}
        rows={enablePagination ? instance.page : instance.rows}
        ErrorComponent={BasicTableError}
        EmptyComponent={EmptyComponent}
        showErrorUI={isError}
        hideBorder={hideBorder}
      />
    </SMFTableContext.Provider>
  );
};

GeneralTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  showHeader: PropTypes.bool,
  EmptyComponent: PropTypes.func,
  enablePagination: PropTypes.bool,
  tableInitState: PropTypes.shape({
    pageIndex: PropTypes.number,
  }),
  onClickNextPage: PropTypes.func,
  onClickPrevPage: PropTypes.func,
  mappedMethods: PropTypes.shape({}),
  hideBorder: PropTypes.bool,
};
GeneralTable.defaultProps = {
  data: [],
  columns: [],
  isLoading: false,
  isError: false,
  showHeader: true,
  EmptyComponent: DefaultEmptyTable,
  enablePagination: false,
  tableInitState: null,
  onClickNextPage: null,
  onClickPrevPage: null,
  mappedMethods: {},
  hideBorder: false,
};

export default GeneralTable;
