import React from 'react';
import { FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomizedRadio, LabelContainer } from '../RenderTreeData/style';

const RadioInput = ({ value, key, label, callback, isChecked = false }) => {
  return (
    <LabelContainer>
      <FormControlLabel
        value={value}
        key={key}
        control={
          <CustomizedRadio
            data-testid={`hierarchy-radio-button-${value}`}
            size="md"
            color="success"
            variant="outlined"
            defaultChecked={isChecked}
            onChange={(event) => event.target.checked && callback()}
          />
        }
        label={label}
      />
    </LabelContainer>
  );
};

RadioInput.propTypes = {
  value: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.string,
  callback: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default RadioInput;
