import * as S from './style';
import PropTypes from 'prop-types';

const ErrorMessage = ({ text, errorTextWidth, alignItems }) => {
  return (
    <S.ErrorTextContainer
      aria-label="error-message"
      alignItems={alignItems}
      errorTextWidth={errorTextWidth}
    >
      <S.ErrorIcon />
      <span>{text}</span>
    </S.ErrorTextContainer>
  );
};

ErrorMessage.propTypes = {
  errorTextWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string,
  alignItems: PropTypes.string,
};
export default ErrorMessage;
