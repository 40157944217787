/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';

import Accordion from '../../components/SMFAccordion';
import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import { useRxjsState } from '../../utils/hooks/useRxjsState';

import { buildNotificationTimingValidationSchema } from './helpers/buildNotificationTimingValidationSchema';
import { notificationTimingDefaultFormValues } from './helpers/notificationTimingDefaultFormValues';
import MESSAGE_STRINGS from '../../constants/en-us';
import { UnitsOfMeasurement } from './constants';
import Button from '../../components/Button';
import * as S from './style';
import { parseNotificationTimingToBackendFormat } from './helpers/parseNotificationTimingToBackendFormat';
import { DataTestIds } from './test-utils/constants';
import { QUERY_CONSTANTS, TOAST_REDUCER_CONSTANTS } from '../../constants';
import { useToastContext } from '../../context/toastContext';
import { MenuItem } from '@mui/material';
import SMFSelect from '../../components/SMFSelect';

import {
  getNotificationTimings,
  updateNotificationTimings,
  getSchedule,
} from '../../utils/apiHelper';

const TimingSettings = () => {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();

  const { isLoading: isScheduleDataLoading, refetch: refetchScheduleTiming } =
    useQuery(
      [QUERY_CONSTANTS.GET_SCHEDULE, rxjsState.plantId],
      () =>
        getSchedule({
          factoryID: rxjsState?.plantId,
        }),
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (response) => {
          const { endTime, startTime, endsOn } =
            response?.data?.productionDay || {};
          setValue('endTime', endTime, {
            shouldDirty: false,
            shouldTouch: false,
          });
          setValue('startTime', startTime, {
            shouldDirty: false,
            shouldTouch: false,
          });
          setValue('endsOn', endsOn, {
            shouldDirty: false,
            shouldTouch: false,
          });
        },
      }
    );

  const {
    refetch: refetchNotificationTimings,
    isLoading: notificationTimingDataLoading,
  } = useQuery(
    [QUERY_CONSTANTS.GET_NOTIFICATION_TIMING],
    async () => {
      const result = await getNotificationTimings(rxjsState?.plantId);

      return result;
    },

    {
      refetchOnWindowFocus: false,
      onSuccess: (notificationTimings) => {
        setValue('durationBanner', notificationTimings?.durationBanner, {
          shouldDirty: false,
          shouldTouch: false,
        });
        setValue('durationBannerUoM', notificationTimings?.durationBannerUoM, {
          shouldDirty: false,
          shouldTouch: false,
        });
        setValue('snoozeDuration', notificationTimings?.snoozeDuration, {
          shouldDirty: false,
          shouldTouch: false,
        });
        setValue('snoozeDurationUoM', notificationTimings?.snoozeDurationUoM, {
          shouldDirty: false,
          shouldTouch: false,
        });
        setValue('durationCenter', notificationTimings?.durationCenter, {
          shouldDirty: false,
          shouldTouch: false,
        });
        setValue('durationCenterUoM', notificationTimings?.durationCenterUoM, {
          shouldDirty: false,
          shouldTouch: false,
        });
        setValue('entityId', notificationTimings?.entityId, {
          shouldDirty: false,
          shouldTouch: false,
        });
      },
      enabled: !!rxjsState?.plantId,
    }
  );

  const {
    mutate: updateNotificationTimingsAPI,
    isLoading: updateNotificationTimingsLoading,
  } = useMutation(
    ['update-notification-timing'],
    async (payload) => {
      const result = await updateNotificationTimings(payload);
      return result;
    },
    {
      onSuccess: () => {
        refetchNotificationTimings();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE,
          },
        });
      },
    }
  );

  const isLoading =
    notificationTimingDataLoading ||
    updateNotificationTimingsLoading ||
    isScheduleDataLoading;

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: notificationTimingDefaultFormValues,
    resolver: yupResolver(buildNotificationTimingValidationSchema),
    mode: 'all',
  });

  const {
    BANNER_DURATION,
    SNOOZE_DURATION,
    ALL_NOTIFICATION_DURATION,
    DURATION,
    SECONDS,
    HOURS,
    NOTIFICATION_TIMING_SETTINGS,
    SAVE,
  } = MESSAGE_STRINGS.NOTIFICATION_TIMING;

  const onSubmit = (submitValues) => {
    updateNotificationTimingsAPI(
      parseNotificationTimingToBackendFormat(submitValues)
    );
  };

  const bannerDurationError = errors?.durationBanner?.message;
  const snoozeDurationError = errors?.snoozeDuration?.message;
  const durationCenterError = errors?.durationCenter?.message;

  return (
    <Accordion
      onChange={(expanded) => {
        if (expanded) {
          refetchScheduleTiming();
        }
      }}
    >
      <AccordionSummary>{NOTIFICATION_TIMING_SETTINGS}</AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Loader sx={{ minHeight: '5rem' }} />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid={DataTestIds.FORM}
          >
            {/* Uncomment the style to activate */}
            <S.NotificationTimingRow
              style={{ display: 'none' }}
              isErrorState={bannerDurationError}
              data-testid={DataTestIds.BANNER_DURATION_CONTAINER}
            >
              <S.OuterFlexContainer>
                <S.InnerFlexContainer>
                  <S.Description>{BANNER_DURATION}</S.Description>
                  <S.Duration>{DURATION}</S.Duration>
                </S.InnerFlexContainer>

                {bannerDurationError && <S.Placeholder />}
              </S.OuterFlexContainer>

              <S.ConfigurationContainer>
                <S.FlexContainer>
                  <S.DurationBannerInput
                    register={register}
                    name="durationBanner"
                    error={bannerDurationError}
                  />
                  {SECONDS}
                </S.FlexContainer>

                {bannerDurationError && (
                  <S.ErrorContainer>
                    <ErrorMessage text={bannerDurationError} />
                  </S.ErrorContainer>
                )}
              </S.ConfigurationContainer>
            </S.NotificationTimingRow>

            <S.NotificationTimingRow
              isErrorState={snoozeDurationError}
              data-testid={DataTestIds.SNOOZE_DURATION_CONTAINER}
            >
              <S.OuterFlexContainer>
                <S.InnerFlexContainer>
                  <S.Description>{SNOOZE_DURATION}</S.Description>
                </S.InnerFlexContainer>

                {snoozeDurationError && <S.Placeholder />}
              </S.OuterFlexContainer>

              <S.ConfigurationContainer>
                <S.LabelConfigContainer>
                  <S.Duration>{DURATION}</S.Duration>
                  <S.ColumnFlexContainer>
                    <S.FlexContainer>
                      <S.SnoozeDurationInput
                        register={register}
                        name="snoozeDuration"
                        value={watch('snoozeDuration')}
                        type={'number'}
                        onKeyPress={(e) => {
                          const restrictedInput = ['.', '+', '-', 'e', 'E'];
                          restrictedInput.forEach((input) => {
                            if (
                              e.key === input ||
                              (e.target.value < 1 && e.key === '0')
                            ) {
                              e.preventDefault();
                            }
                          });
                        }}
                      />
                      <SMFSelect
                        name="snoozeDurationUoM"
                        value={watch('snoozeDurationUoM')}
                        onChange={async ({ target: { value } }) => {
                          setValue('snoozeDurationUoM', value, {
                            shouldValidate: true,
                          });
                          await trigger('snoozeDuration');
                        }}
                      >
                        <MenuItem value={UnitsOfMeasurement.MINUTES}>
                          {UnitsOfMeasurement.MINUTES}
                        </MenuItem>
                        <MenuItem value={UnitsOfMeasurement.HOURS}>
                          {UnitsOfMeasurement.HOURS}
                        </MenuItem>
                      </SMFSelect>
                    </S.FlexContainer>

                    {snoozeDurationError && (
                      <S.ErrorContainer>
                        <ErrorMessage text={snoozeDurationError} />
                      </S.ErrorContainer>
                    )}
                  </S.ColumnFlexContainer>
                </S.LabelConfigContainer>
              </S.ConfigurationContainer>
            </S.NotificationTimingRow>

            <S.NotificationTimingRow
              isErrorState={durationCenterError}
              data-testid={DataTestIds.DURATION_CENTER_CONTAINER}
            >
              <S.OuterFlexContainer>
                <S.InnerFlexContainer>
                  <S.Description>{ALL_NOTIFICATION_DURATION}</S.Description>
                </S.InnerFlexContainer>

                {durationCenterError && <S.Placeholder />}
              </S.OuterFlexContainer>

              <S.ConfigurationContainer>
                <S.LabelConfigContainer>
                  <S.Duration>{DURATION}</S.Duration>
                  <S.ColumnFlexContainer>
                    <S.FlexContainer>
                      <S.DurationCenterInput
                        register={register}
                        name="durationCenter"
                        error={durationCenterError}
                        type={'number'}
                        onKeyPress={(e) => {
                          const restrictedInput = ['.', '+', '-', 'e', 'E'];
                          restrictedInput.forEach((input) => {
                            if (
                              e.key === input ||
                              (e.target.value < 1 && e.key === '0') ||
                              e.target.value.length > 1
                            ) {
                              e.preventDefault();
                            }
                          });
                        }}
                      />
                      {HOURS}
                    </S.FlexContainer>

                    {durationCenterError && (
                      <S.ErrorContainer>
                        <ErrorMessage text={durationCenterError} />
                      </S.ErrorContainer>
                    )}
                  </S.ColumnFlexContainer>
                </S.LabelConfigContainer>
              </S.ConfigurationContainer>
            </S.NotificationTimingRow>

            <S.SaveButtonContainer
              data-testid={DataTestIds.SAVE_BUTTON_CONTAINER}
            >
              <Button
                type="submit"
                disabled={Object.keys(errors).length > 0}
                data-testid={DataTestIds.SAVE_BUTTON}
              >
                {SAVE}
              </Button>
            </S.SaveButtonContainer>
          </form>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TimingSettings;
