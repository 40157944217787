import { useState } from 'react';
import PropTypes from 'prop-types';
import SMFSwitch from '../../../components/SMFSwitch';
import CustomTooltip from '../../../components/CustomTooltip';
import { AddRemoveToggleCellBox, StyledBox, ToolTipIconBox } from './style.js';
import EditIcon from '../../../assets/img/editIcon.svg';
import EditIconDisabled from '../../../assets/img/editIconDisabled.svg';
import DeleteIcon from '../../../assets/img/deleteIcon.svg';
import DeleteIconDisabled from '../../../assets/img/deleteIconDisabled.svg';
import { Box } from '@mui/material';
import { TEST_IDS } from '../../../constants/index.js';
import MESSAGE_STRINGS from '../../../constants/en-us/index.js';

const AddRemoveToggleCell = ({
  row,
  getTriggersToBeUpdated,
  handleCustomNotificationEditIconClick,
  handleCustomNotificationDeleteIconClick,
}) => {
  const { isEnabled, isActive, isCustom, triggerId } = row.original;
  const [checked, setChecked] = useState(isActive && isEnabled);
  return (
    <AddRemoveToggleCellBox>
      <>
        <SMFSwitch
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
            getTriggersToBeUpdated(triggerId, event.target.checked);
          }}
          disabled={!isEnabled}
          inputProps={{ 'data-testid': 'add-remove-switch' }}
        />
        {isCustom && isEnabled && (
          <StyledBox>
            <CustomTooltip
              title={MESSAGE_STRINGS.CUSTOM_NOTIFICATION_EDIT_ICON_TOOLTIP}
              placement="top-start"
              isFromAddRemoveToggleCell={true}
            >
              <ToolTipIconBox>
                <EditIcon
                  height="1.5rem"
                  width="1.5rem"
                  onClick={() =>
                    handleCustomNotificationEditIconClick(triggerId)
                  }
                  data-testid={TEST_IDS.CUSTOM_NOTIFICATION_EDIT_ICON}
                />
              </ToolTipIconBox>
            </CustomTooltip>
            <Box width="1rem" />
            <CustomTooltip
              title={MESSAGE_STRINGS.CUSTOM_NOTIFICATION_DELETE_ICON_TOOLTIP}
              placement="top-start"
              isFromAddRemoveToggleCell={true}
            >
              <ToolTipIconBox>
                <DeleteIcon
                  height="1.5rem"
                  width="1.5rem"
                  data-testid={TEST_IDS.CUSTOM_NOTIFICATION_DELETE_ICON}
                  onClick={() =>
                    handleCustomNotificationDeleteIconClick(triggerId)
                  }
                />
              </ToolTipIconBox>
            </CustomTooltip>
          </StyledBox>
        )}
        {isCustom && !isEnabled && (
          <StyledBox>
            <EditIconDisabled height="1.5rem" width="1.5rem" />
            <Box width="1rem" />
            <DeleteIconDisabled height="1.5rem" width="1.5rem" />
          </StyledBox>
        )}
      </>
    </AddRemoveToggleCellBox>
  );
};

AddRemoveToggleCell.propTypes = {
  getTriggersToBeUpdated: PropTypes.func,
  handleCustomNotificationEditIconClick: PropTypes.func,
  handleCustomNotificationDeleteIconClick: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.shape({
      isEnabled: PropTypes.bool,
      isActive: PropTypes.bool,
      isCustom: PropTypes.bool,
      triggerId: PropTypes.string,
    }),
  }),
};

export default AddRemoveToggleCell;
