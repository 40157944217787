import { Typography, Box, styled } from '@mui/material';

export const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.875rem',
  fontWeight: '400',
};

const DropdownLabelAndTooltipWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const DropdownLabelWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSmall',
})(({ isSmall }) => ({
  width: isSmall ? '10.93rem' : '17rem',
  marginTop: '0.78rem',
}));

const DropdownLabelTypography = styled(Typography)({
  ...typographyStyles,
  lineHeight: '1.192rem',
  marginRight: '0.5rem',
  marginTop: '1.156rem',
  marginBottom: '0.531rem',
});

const ToolTipIconBox = styled(Box)({
  marginTop: '1rem',
});

export {
  DropdownLabelAndTooltipWrapper,
  DropdownLabelTypography,
  ToolTipIconBox,
  DropdownLabelWrapper,
};
