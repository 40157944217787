import PropTypes from 'prop-types';
import AssetFilterIcon from '../../assets/img/assetFilterIcon.svg';
import {
  PopoverContainer,
  SelectedValueLargeBadge,
  BadgeLabel,
  SelectedValueBadge,
  IconButtonBase,
  StyledPopover,
} from './style.js';
/*
 ** This component is used to render filter icon and a filter popover
 ** Pass children as props to display within the popover
 */

const FilterPopover = ({
  idRef,
  openBool,
  itemAnchorEl,
  isDisabled,
  clickCallback,
  children,
  filterName,
  selectedFilterCount,
}) => {
  return (
    <PopoverContainer>
      <IconButtonBase
        id={idRef}
        aria-describedby={idRef}
        onClick={(event) => clickCallback(event.currentTarget)}
        disabled={isDisabled}
        disableRipple
        data-testid={`${filterName}-filter-button`}
      >
        <AssetFilterIcon />
        {selectedFilterCount > 0 &&
          (selectedFilterCount > 99 ? (
            <SelectedValueLargeBadge>
              <BadgeLabel data-testid="filter-count-label">
                {selectedFilterCount}
              </BadgeLabel>
            </SelectedValueLargeBadge>
          ) : (
            <SelectedValueBadge>
              <BadgeLabel data-testid="filter-count-label">
                {selectedFilterCount}
              </BadgeLabel>
            </SelectedValueBadge>
          ))}
      </IconButtonBase>
      <StyledPopover
        id={idRef}
        open={openBool}
        anchorEl={itemAnchorEl}
        onClose={() => clickCallback(null)}
        sx={{ zIndex: '1500' }}
        data-testid="filter-popover"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {children}
      </StyledPopover>
    </PopoverContainer>
  );
};

FilterPopover.propTypes = {
  idRef: PropTypes.string,
  openBool: PropTypes.bool,
  itemAnchorEl: PropTypes.instanceOf(Element),
  isDisabled: PropTypes.bool,
  clickCallback: PropTypes.func,
  children: PropTypes.node,
  filterName: PropTypes.string,
  selectedFilterCount: PropTypes.number,
};

export default FilterPopover;
