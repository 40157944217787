import { DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { styled } from '@mui/system';

const StyledDialogContent = styled(DialogContent)(() => ({
  '&.MuiDialogContent-root': {
    padding: '0.5rem 0',
  },
}));

const StyledDialogTitle = styled(DialogTitle, {
  shouldForwardProp: (prop) => prop !== 'isLarge',
})(({ isLarge }) => ({
  '&.MuiDialogTitle-root': {
    padding: '1rem 0 1.09rem 0.188rem',
    fontSize: isLarge ? '1.25rem' : '',
  },
}));

const StyledDialogActions = styled(DialogActions)(({ isFlex }) => ({
  '&.MuiDialogActions-root': {
    padding: '0.5rem 0 0 0',
    display: isFlex ? 'flex' : '',
    justifyContent: isFlex ? 'space-between' : '',
  },
}));

export { StyledDialogContent, StyledDialogTitle, StyledDialogActions };
