import { Box, styled } from '@mui/material';

const AddRemoveToggleCellBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginRight: '1rem',
  marginLeft: '2rem',
}));

const StyledBox = styled(Box)({
  display: 'flex',
});

const ToolTipIconBox = styled(Box)({
  marginTop: '0.3rem',
});

export { AddRemoveToggleCellBox, StyledBox, ToolTipIconBox };
