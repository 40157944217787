import { useState } from 'react';
import PropTypes from 'prop-types';
import { GeneralDialog } from '../../../components/GeneralDialog';
import ModalBody from '../../../components/GeneralDialog/GeneralDialogBody';
import MESSAGE_STRINGS from '../../../constants/en-us';
import Actions from '../../../components/ModalActions';
import CustomNotificationConfig from '../CustomNotificationConfig';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRxjsState } from '../../../utils/hooks/useRxjsState';
import {
  CUSTOM_NOTIFICATION_TYPES,
  QUERY_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
} from '../../../constants';
import {
  getCustomNotificationDetails,
  saveCustomNotification,
} from '../../../utils/apiHelper';
import { useToastContext } from '../../../context/toastContext';

export const CustomNotificationModal = ({
  open,
  closeModal,
  refetchAllTriggers,
  refetchSavedTriggers,
  isEditing = false,
  clickedCustomNotificationTriggerId,
}) => {
  const { rxjsState } = useRxjsState();
  const { toastDispatch } = useToastContext();
  const [selectedNotificationType, setSelectedNotificationType] = useState(
    CUSTOM_NOTIFICATION_TYPES.MODULE
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [metricData, setMetricData] = useState({
    module: null,
    alert: null,
    trigger: null,
  });
  const [displayedMessage, setDisplayedMessage] = useState('-');

  const toggleSaveDisability = (isDisabled) => {
    setIsSaveDisabled(isDisabled);
  };

  const handleCustomNotificationDetailsSave = () => {
    if (selectedNotificationType === CUSTOM_NOTIFICATION_TYPES.MODULE) {
      const reqBody = {
        factoryId: rxjsState.plantId?.toString(),
        triggerId: isEditing ? clickedCustomNotificationTriggerId : null,
        notificationCategory: selectedNotificationType,
        metrics: {
          entityHierarchy: rxjsState.plantId?.toString(),
          module: metricData.module,
          alert: metricData.alert,
          trigger: metricData.trigger,
        },
        displayDetails: {
          notificationTriggerName: `${metricData.module} ${metricData.alert} ${metricData.trigger}`,
          displayMessage: displayedMessage,
        },
      };
      saveCustomNotificationDetails(reqBody);
    }
  };

  /* 
    Fetching saved custom notification data
  */
  const { isLoading: isSavedCustomNotificationDataFetching } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_SAVED_CUSTOM_NOTIFICATION_DETAILS],
    queryFn: () => {
      const queryParams = {
        plantId: rxjsState.plantId,
        triggerId: clickedCustomNotificationTriggerId,
      };
      return getCustomNotificationDetails(queryParams);
    },
    onSuccess: (res) => {
      setSelectedNotificationType(res?.notificationCategory?.id);
      setMetricData(res?.metrics?.module);
      setDisplayedMessage(res?.metrics?.displayDetails?.displayMessage);
    },
    enabled: Boolean(rxjsState?.plantId) && isEditing,
  });

  /* 
    Save custom notification details
  */

  const {
    mutate: saveCustomNotificationDetails,
    isLoading: isCustomNotificationDetailsSaving,
  } = useMutation(
    [QUERY_CONSTANTS.SAVE_CUSTOM_NOTIFICATION_DETAILS],
    async (reqBody) => {
      return saveCustomNotification(reqBody);
    },
    {
      onSuccess: () => {
        refetchAllTriggers();
        refetchSavedTriggers();
        closeModal();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message:
              MESSAGE_STRINGS.TOAST_CUSTOM_NOTIFICATION_SAVE_SUCCESS_MESSAGE,
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_ERROR_MESSAGE,
          },
        });
      },
    }
  );

  return (
    <GeneralDialog
      open={open}
      fullWidth={false}
      sx={{
        '& .dialog-title': { fontWeight: '700' },
      }}
      isNotificationsModal={true}
    >
      <ModalBody
        dialogTitle={MESSAGE_STRINGS.ADD_CUSTOM_NOTIFICATION}
        dialogContent={
          <CustomNotificationConfig
            toggleSaveDisability={toggleSaveDisability}
            metricData={metricData}
            handleMetricDataChange={setMetricData}
            displayedMessage={displayedMessage}
            handleDisplayedMessageChange={setDisplayedMessage}
            isDataLoading={
              isCustomNotificationDetailsSaving ||
              (isEditing && isSavedCustomNotificationDataFetching)
            }
            selectedNotificationType={selectedNotificationType}
            isEditing={isEditing}
          />
        }
        minWidth="60.5rem"
        maxWidth="66.5rem"
        actions={
          <Actions
            confirmText={MESSAGE_STRINGS.SAVE}
            cancelText={MESSAGE_STRINGS.CANCEL}
            handleSave={handleCustomNotificationDetailsSave}
            handleClose={closeModal}
            isSaveDisabled={isSaveDisabled}
          />
        }
      ></ModalBody>
    </GeneralDialog>
  );
};
CustomNotificationModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  refetchAllTriggers: PropTypes.func,
  refetchSavedTriggers: PropTypes.func,
  isEditing: PropTypes.bool,
  clickedCustomNotificationTriggerId: PropTypes.number,
};
