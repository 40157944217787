import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledHeadingOEE = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.1875rem',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  color: theme.customColors.lightUnitGrey,
}));

const StyledHeading = styled(Typography)(() => ({
  padding: '0.625rem',
  border: '0.0625rem solid rgba(83, 86, 90, 0.3)',
  fontSize: '1rem',
  fontWeight: '600',
  lineHeight: '1.5rem',
}));

const StyledDurationMsg = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.1875rem',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  color: theme.customColors.white,
}));

const StyledDuration = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.25rem',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  color: theme.customColors.white,
}));

export { StyledHeading, StyledHeadingOEE, StyledDurationMsg, StyledDuration };
