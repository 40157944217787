import SMFButton from '../GeneralButton';
import { Typography, Box } from '@mui/material';
import {
  AddCustomNotificationWrapper,
  FlexBox,
  StyledButtonBase,
} from './style';
import PropTypes from 'prop-types';
import AddIcon from '../../assets/img/addIcon.svg';
import MESSAGE_STRINGS from '../../constants/en-us';
import { FEATURE_FLAG_TREATMENTS, TEST_IDS } from '../../constants';

const Actions = ({
  confirmText,
  cancelText,
  handleClose,
  handleSave,
  isSaveDisabled = false,
  isCustomNotification = false,
  handleCustomNotificationModalOpen,
  customNotificationFeature,
}) => {
  return (
    <>
      <AddCustomNotificationWrapper>
        {isCustomNotification &&
          customNotificationFeature === FEATURE_FLAG_TREATMENTS.ON && (
            <FlexBox>
              <StyledButtonBase onClick={handleCustomNotificationModalOpen}>
                <AddIcon
                  height="1.5rem"
                  width="1.5rem"
                  data-testid={TEST_IDS.ADD_CUSTOM_NOTIFICATION_BTN}
                />
                <Typography>{MESSAGE_STRINGS.CUSTOM_NOTIFICATION}</Typography>
              </StyledButtonBase>
            </FlexBox>
          )}
      </AddCustomNotificationWrapper>
      <Box>
        <SMFButton
          type="secondary"
          onClick={handleClose}
          text={cancelText}
          data-testid="secondary-modal-button"
        />
        <SMFButton
          type="primary"
          onClick={handleSave}
          text={confirmText}
          data-testid="primary-modal-button"
          disabled={isSaveDisabled}
        />
      </Box>
    </>
  );
};

Actions.propTypes = {
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  isSaveDisabled: PropTypes.bool,
  isCustomNotification: PropTypes.bool,
  handleCustomNotificationModalOpen: PropTypes.func,
  customNotificationFeature: PropTypes.string,
};

export default Actions;
