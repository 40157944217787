import PropTypes from 'prop-types';
import { StyledTooltip } from './style.js';

const CustomTooltip = ({ children, ...props }) => {
  return (
    <StyledTooltip {...props}>
      <span>{children}</span>
    </StyledTooltip>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.node,
};

export default CustomTooltip;
