import { useEffect, useRef } from 'react';
import { useTable, useFilters, usePagination } from 'react-table';

export default function useSMFTable({
  columns = [],
  data = [],
  isPaginated = false,
  initialState = { pageIndex: 0 },
  manualFilters = false,
}) {
  // https://react-table.tanstack.com/docs/faq#how-do-i-stop-my-table-state-from-automatically-resetting-when-my-data-changes
  const skipPageResetRef = useRef();

  const paginationPropsForTable = {
    autoResetPage: !skipPageResetRef.current,
    autoResetExpanded: !skipPageResetRef.current,
    autoResetGroupBy: !skipPageResetRef.current,
    autoResetSelectedRows: !skipPageResetRef.current,
    autoResetSortBy: !skipPageResetRef.current,
    autoResetFilters: !skipPageResetRef.current,
    autoResetRowState: !skipPageResetRef.current,
  };

  function doSkipPageReset() {
    skipPageResetRef.current = true;
  }

  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false;
  });

  const instance = useTable(
    {
      columns,
      data,
      initialState: { ...initialState },
      doSkipPageReset,
      manualFilters,
      ...paginationPropsForTable,
    },
    useFilters,
    usePagination
  );
  instance[isPaginated ? 'page' : 'rows'].map((row) =>
    instance.prepareRow(row)
  );
  return instance;
}
