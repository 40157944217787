import { Button } from '@mui/material';
import { styled } from '@mui/system';
import Mute from '../../../assets/img/muted.svg';
import Unmute from '../../../assets/img/unmuted.svg';

const MuteButton = styled(Button)(() => ({
  padding: 0,
  minWidth: '0',
}));

const Muted = styled(Mute)(() => ({
  height: '2em',
  width: '2em',
}));

const Unmuted = styled(Unmute)(() => ({
  height: '2em',
  width: '2em',
}));

export { MuteButton, Muted, Unmuted };
