import PropTypes from 'prop-types';
import ComboBox from '../ComboBox';
import { COMBOBOX_PLACEHOLDER } from '../../constants';
import DropdownLabel from '../DropdownLabel';
import { DropdownLabelWrapper } from '../DropdownLabel/style';

const NamedCombobox = ({
  label = '',
  tooltipMessage = '',
  placeholder = COMBOBOX_PLACEHOLDER,
  options = [],
  isDisabled = false,
  onSelectionChanged = () => {},
  value = '',
  metricName = '',
  isSmall = true,
  isTooltip = false,
}) => {
  return (
    <DropdownLabelWrapper isSmall={isSmall}>
      <DropdownLabel
        label={label}
        tooltipMessage={tooltipMessage}
        isTooltip={isTooltip}
      />
      <ComboBox
        placeholder={placeholder}
        options={options}
        isDisabled={isDisabled}
        onChange={onSelectionChanged}
        value={value}
        metricName={metricName}
      />
    </DropdownLabelWrapper>
  );
};

NamedCombobox.propTypes = {
  label: PropTypes.string,
  tooltipMessage: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  onSelectionChanged: PropTypes.func,
  value: PropTypes.string,
  metricName: PropTypes.string,
  isSmall: PropTypes.bool,
  isTooltip: PropTypes.bool,
};

export default NamedCombobox;
