import { styled } from '@mui/system';
import { Box } from '@mui/material';

const TableContainer = styled('div')(({ theme }) => ({
  '& .basicGeneralTable': {
    height: '31.5rem',
    backgroundColor: theme.palette.background.blackGrey,
  },
  '& .basicGeneralTable_headerRow .MuiTableCell-head:last-child': {
    textAlign: 'center',
  },
  '& .basicGeneralTable_bodyRow:last-of-type .MuiTableCell-body': {
    borderBottom: `0.063rem solid ${theme.customColors.wrapperGrey}`,
  },
  '& .basicGeneralTable_headerRow .MuiTableCell-head': {
    '& .MuiButtonBase-root': {
      '& .MuiTypography-root': {
        fontSize: '0.75rem',
        lineHeight: '1.5',
      },
    },
  },
}));

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export { TableContainer, HeaderBox };
