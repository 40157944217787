import { createContext, useContext, useReducer, useMemo } from 'react';
import {
  IS_ACTIVE_FILTERS,
  REDUCER_DISPATCH_ACTIONS,
  FILTER_METADATA,
  FILTER_NAMES,
} from '../constants';
import _ from 'lodash';
import MESSAGE_STRINGS from '../constants/en-us';

export const TriggerSettingsContext = createContext();

export const useTriggerSettingsContext = () =>
  useContext(TriggerSettingsContext);

const initialState = {
  isTriggersDataLoading: true,
  originalTriggersList: [],
  editedTriggersList: [],
  showAddRemoveNotificationsModal: false,
  isEditingActiveTriggers: false,
  emptyRoleFields: new Set(),
  emptyTypeFields: new Set(),
  allOperationalAreas: [], // operational area list from api
  availableOperationalAreaFilters: [], //operational area filters available for filtering
  selectedOperationalAreaFilters: [], //selected operational area filters
  // Add / Remove Modal filters
  availableModalFunctionalAreaFilters: [],
  selectedModalFunctionalAreaFilters: [],
  availableIsActiveFilters: [],
  selectedIsActiveFilters: [],
  showAdditionalSettingsModal: false,
  additionalSettingsRowData: null,
};

const createUpdatedTriggerList = (
  originalItems = [],
  itemIdToEdit,
  updatedKeys
) => {
  const updatedRowIndex = originalItems.findIndex(
    (item) => item.triggerId === itemIdToEdit
  );

  const updatedActiveNotifications = [...originalItems];
  updatedActiveNotifications[updatedRowIndex] = {
    ...updatedActiveNotifications[updatedRowIndex],
    ...updatedKeys,
    isEdited: true,
  };
  return updatedActiveNotifications;
};

const getAvailableFilters = (
  filterName,
  triggersList,
  itemKey,
  itemName,
  filterData = [] // ? used when filters need to be derived from API data
) => {
  let filterArray = [];
  const allFilter = {
    id: 'all',
    label: MESSAGE_STRINGS.ALL,
  };
  switch (filterName) {
    case FILTER_NAMES.OPERATIONAL_AREA:
      triggersList &&
        triggersList.map((trigger) => {
          const filterItem =
            filterData &&
            trigger.isActive &&
            trigger.isEnabled &&
            filterData.find((item) => item[itemKey] === trigger[itemKey]);
          filterItem &&
            filterArray.push({
              id: filterItem[itemKey],
              label: filterItem[itemName],
            });
        });
      break;
    case FILTER_NAMES.FUNCTIONAL_AREA:
      triggersList &&
        triggersList.map((trigger) => {
          filterArray.push({
            id: trigger[itemKey],
            label: trigger[itemName],
          });
        });
      break;
  }
  let uniqueFilters = _.uniqBy(filterArray, 'id');
  // Sort filters alphabetically with 'All' option always at the top
  uniqueFilters.sort((a, b) => a.label.localeCompare(b.label));
  uniqueFilters.unshift(allFilter);
  // To avoid showing 'all' option when there are no other available filters
  // Set filters to empty array if it contains only one option i.e 'all'
  if (uniqueFilters.length === 1 && uniqueFilters[0].id === 'all') {
    uniqueFilters = [];
  }
  return uniqueFilters;
};

export const triggerSettingsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case REDUCER_DISPATCH_ACTIONS.SET_TRIGGERS_LIST: {
      return {
        ...state,
        isTriggersDataLoading: false,
        originalTriggersList: _.cloneDeep(payload.triggersList),
        editedTriggersList: [...payload.triggersList],
      };
    }
    case REDUCER_DISPATCH_ACTIONS.RESET_ON_SAVE: {
      const { editedTriggersList } = state;
      const resetList = editedTriggersList.map((trigger) => {
        return {
          ...trigger,
          isEdited: false,
          isNew: false,
        };
      });
      return {
        ...state,
        isEditingActiveTriggers: false,
        editedTriggersList: [...resetList],
      };
    }
    case REDUCER_DISPATCH_ACTIONS.SHOW_ADD_REMOVE_MODAL: {
      return {
        ...state,
        showAddRemoveNotificationsModal: action.payload.value,
      };
    }
    case REDUCER_DISPATCH_ACTIONS.IS_EDITING_ACTIVE_TRIGGERS: {
      return {
        ...state,
        isEditingActiveTriggers: action.payload.value,
      };
    }
    case REDUCER_DISPATCH_ACTIONS.UPDATE_NOTIFICATIONS: {
      const { triggersToBeUpdated } = action.payload;
      const { emptyRoleFields, emptyTypeFields, originalTriggersList } = state;
      const newEmptyRoleFields = new Set(emptyRoleFields);
      const newEmptyTypeFields = new Set(emptyTypeFields);

      // revert edit changes when trigger is deactivated
      const validatedTriggers = triggersToBeUpdated.map((trigger) => {
        if (trigger.isNew && !trigger.isActive) {
          newEmptyRoleFields.delete(trigger.triggerId);
          newEmptyTypeFields.delete(trigger.triggerId);

          const originalTrigger = originalTriggersList.find(
            (original) => original.triggerId === trigger.triggerId
          );
          const revertedRoles = originalTrigger.roles
            ? originalTrigger.roles.map((role) => {
                return { ...role };
              })
            : [];
          const revertedType = originalTrigger.notificationType || '';
          const revertedRecurrence = originalTrigger.recurrence || false;
          const revertedRecurrenceValue = originalTrigger.recurrenceValue || '';
          const revertedTempMuteValue = originalTrigger.tempMuteValue;
          const revertedTempMuteUoM = originalTrigger.tempMuteUoM;

          return {
            ...trigger,
            roles: revertedRoles,
            notificationType: revertedType,
            notificationTypeError: false,
            rolesAssignedError: false,
            recurrence: revertedRecurrence,
            recurrenceValue: revertedRecurrenceValue,
            tempMuteUoM: revertedTempMuteUoM,
            tempMuteValue: revertedTempMuteValue,
          };
        }
        return {
          ...trigger,
        };
      });
      return {
        ...state,
        showAddRemoveNotificationsModal: false,
        editedTriggersList: [...validatedTriggers],
        emptyRoleFields: newEmptyRoleFields,
        emptyTypeFields: newEmptyTypeFields,
        isEditingActiveTriggers: true,
        selectedOperationalAreaFilters: [],
        availableOperationalAreaFilters: getAvailableFilters(
          FILTER_NAMES.OPERATIONAL_AREA,
          [...validatedTriggers],
          FILTER_METADATA.OPERATIONAL_AREA.ITEM_KEY,
          FILTER_METADATA.OPERATIONAL_AREA.ITEM_NAME,
          state.allOperationalAreas
        ),
        selectedModalFunctionalAreaFilters: [],
        selectedIsActiveFilters: [],
      };
    }
    case REDUCER_DISPATCH_ACTIONS.RESET_ADD_REMOVE_MODAL: {
      return {
        ...state,
        showAddRemoveNotificationsModal: false,
        editedTriggersList: [...state.editedTriggersList],
        selectedModalFunctionalAreaFilters: [],
        selectedIsActiveFilters: [],
      };
    }
    case REDUCER_DISPATCH_ACTIONS.UPDATE_ROLES_ASSIGNED: {
      const { id, rolesAssigned } = payload;
      const { editedTriggersList, emptyRoleFields } = state;

      const updatedActiveNotifications = createUpdatedTriggerList(
        editedTriggersList,
        id,
        {
          roles: rolesAssigned.map((role) => {
            return { ...role };
          }),
          rolesAssignedError: false,
        }
      );
      const newEmptyRoleFields = new Set(emptyRoleFields);
      newEmptyRoleFields.delete(id);
      return {
        ...state,
        emptyRoleFields: newEmptyRoleFields,
        editedTriggersList: [...updatedActiveNotifications],
      };
    }
    case REDUCER_DISPATCH_ACTIONS.UPDATE_NOTIFICATION_TYPE: {
      const { id, notificationType } = payload;
      const { editedTriggersList, emptyTypeFields } = state;

      const updatedActiveNotifications = createUpdatedTriggerList(
        editedTriggersList,
        id,
        {
          notificationType,
          notificationTypeError: false,
        }
      );
      const newEmptyTypeFields = new Set(emptyTypeFields);
      newEmptyTypeFields.delete(id);
      return {
        ...state,
        emptyTypeFields: newEmptyTypeFields,
        editedTriggersList: [...updatedActiveNotifications],
      };
    }

    case REDUCER_DISPATCH_ACTIONS.UPDATE_RECURRENCE: {
      const { id, recurrence, recurrenceValue, recurrenceUnit } = payload;
      const { editedTriggersList } = state;
      const updatedActiveNotifications = createUpdatedTriggerList(
        editedTriggersList,
        id,
        { recurrence, recurrenceValue, recurrenceUnit }
      );
      return {
        ...state,
        editedTriggersList: [...updatedActiveNotifications],
      };
    }
    // additional settings oee
    case REDUCER_DISPATCH_ACTIONS.UPDATE_TEMP_MUTE_VALUE: {
      const { id, tempMuteValue, tempMuteUoM } = payload;
      const { editedTriggersList, additionalSettingsRowData } = state;
      const updatedActiveNotifications = createUpdatedTriggerList(
        editedTriggersList,
        id,
        { ...additionalSettingsRowData, tempMuteValue, tempMuteUoM }
      );
      return {
        ...state,
        editedTriggersList: [...updatedActiveNotifications],
      };
    }

    case REDUCER_DISPATCH_ACTIONS.UPDATE_IS_MUTE: {
      const { id, isMute } = payload;
      const { editedTriggersList } = state;

      const updatedActiveNotifications = createUpdatedTriggerList(
        editedTriggersList,
        id,
        {
          isMute,
        }
      );
      return {
        ...state,
        editedTriggersList: [...updatedActiveNotifications],
      };
    }
    case REDUCER_DISPATCH_ACTIONS.VALIDATE_SAVE: {
      const { emptyRoleFields, emptyTypeFields, validatedTriggersList } =
        payload;

      return {
        ...state,
        emptyRoleFields,
        emptyTypeFields,
        editedTriggersList: [...validatedTriggersList],
      };
    }
    case REDUCER_DISPATCH_ACTIONS.SET_AVAILABLE_OPERATIONAL_AREA_FILTERS: {
      const { operationalAreaData } = payload;
      return {
        ...state,
        allOperationalAreas: operationalAreaData,
        availableOperationalAreaFilters: getAvailableFilters(
          FILTER_NAMES.OPERATIONAL_AREA,
          state.editedTriggersList,
          FILTER_METADATA.OPERATIONAL_AREA.ITEM_KEY,
          FILTER_METADATA.OPERATIONAL_AREA.ITEM_NAME,
          operationalAreaData
        ),
      };
    }
    case REDUCER_DISPATCH_ACTIONS.SET_SELECTED_OPERATIONAL_AREA_FILTERS: {
      const { selectedFilterIds } = payload;
      return {
        ...state,
        selectedOperationalAreaFilters: selectedFilterIds,
      };
    }
    case REDUCER_DISPATCH_ACTIONS.SET_MODAL_AVAILABLE_FUNCTIONAL_AREA_FILTERS: {
      return {
        ...state,
        availableModalFunctionalAreaFilters: getAvailableFilters(
          FILTER_NAMES.FUNCTIONAL_AREA,
          state.editedTriggersList,
          FILTER_METADATA.FUNCTIONAL_AREA.ITEM_KEY,
          FILTER_METADATA.FUNCTIONAL_AREA.ITEM_NAME
        ),
      };
    }
    case REDUCER_DISPATCH_ACTIONS.SET_MODAL_SELECTED_FUNCTIONAL_AREA_FILTERS: {
      const { selectedFilterIds } = payload;
      return {
        ...state,
        selectedModalFunctionalAreaFilters: selectedFilterIds,
      };
    }
    case REDUCER_DISPATCH_ACTIONS.SET_AVAILABLE_IS_ACTIVE_FILTERS: {
      return {
        ...state,
        availableIsActiveFilters: IS_ACTIVE_FILTERS,
      };
    }
    case REDUCER_DISPATCH_ACTIONS.SET_SELECTED_IS_ACTIVE_FILTERS: {
      const { selectedFilterIds } = payload;
      return {
        ...state,
        selectedIsActiveFilters: selectedFilterIds,
      };
    }
    case REDUCER_DISPATCH_ACTIONS.TOGGLE_ADDITIONAL_SETTINGS: {
      const { additionalSettingsData, openModal } = payload;
      return {
        ...state,
        additionalSettingsRowData: { ...additionalSettingsData },
        showAdditionalSettingsModal: openModal,
      };
    }
    case REDUCER_DISPATCH_ACTIONS.UPDATE_TEMP_ADDITIONAL_SETTING_ROW_DATA: {
      const { additionalSettingsRowData } = state;
      const { updatedAdditionalSettings } = payload;

      const updated =
        additionalSettingsRowData?.triggerId ===
        updatedAdditionalSettings.triggerId
          ? { ...additionalSettingsRowData, ...updatedAdditionalSettings }
          : { ...additionalSettingsRowData };
      return {
        ...state,
        additionalSettingsRowData: { ...updated },
      };
    }
    //additional settings oee
    case REDUCER_DISPATCH_ACTIONS.UPDATE_ADDITIONAL_SETTINGS_FOR_TRIGGER: {
      const { id } = payload;
      const { editedTriggersList, additionalSettingsRowData } = state;
      const updatedActiveNotifications = createUpdatedTriggerList(
        editedTriggersList,
        id,
        {
          ...additionalSettingsRowData,
        }
      );
      return {
        ...state,
        editedTriggersList: [...updatedActiveNotifications],
      };
    }
    default:
      return state;
  }
};

export const loadTriggerSettings = (
  dispatch,
  triggersList,
  operationalAreaData
) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.SET_TRIGGERS_LIST,
    payload: { triggersList },
  });
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.SET_AVAILABLE_OPERATIONAL_AREA_FILTERS,
    payload: { operationalAreaData },
  });
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.SET_MODAL_AVAILABLE_FUNCTIONAL_AREA_FILTERS,
  });
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.SET_AVAILABLE_IS_ACTIVE_FILTERS,
  });
};

export const updateRolesAssigned = (dispatch, id, rolesAssigned) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.UPDATE_ROLES_ASSIGNED,
    payload: { id, rolesAssigned },
  });
};

export const updateNotificationType = (dispatch, id, notificationType) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.UPDATE_NOTIFICATION_TYPE,
    payload: { id, notificationType },
  });
};

export const updateRecurrence = (
  dispatch,
  id,
  recurrence,
  recurrenceValue,
  recurrenceUnit
) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.UPDATE_RECURRENCE,
    payload: { id, recurrence, recurrenceValue, recurrenceUnit },
  });
};

export const updateIsMute = (dispatch, id, isMute) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.UPDATE_IS_MUTE,
    payload: { id, isMute },
  });
};

export const updateTempMuteAdditionalSettingsForTrigger = (
  dispatch,
  id,
  tempMuteValue,
  tempMuteUoM
) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.UPDATE_TEMP_MUTE_VALUE,
    payload: { id, tempMuteValue, tempMuteUoM },
  });
};

export const updateAdditionalSettingsForTrigger = (dispatch, id) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.UPDATE_ADDITIONAL_SETTINGS_FOR_TRIGGER,
    payload: { id },
  });
};

export const updateTempAdditionalSettings = (
  dispatch,
  updatedAdditionalSettings
) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.UPDATE_TEMP_ADDITIONAL_SETTING_ROW_DATA,
    payload: { updatedAdditionalSettings },
  });
};

export const toggleAdditionalSettingsModal = (
  dispatch,
  additionalSettingsData,
  openModal
) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.TOGGLE_ADDITIONAL_SETTINGS,
    payload: { additionalSettingsData, openModal },
  });
};

export const validateSave = (
  dispatch,
  emptyRoleFields,
  emptyTypeFields,
  validatedTriggersList
) => {
  dispatch({
    type: REDUCER_DISPATCH_ACTIONS.VALIDATE_SAVE,
    payload: { emptyRoleFields, emptyTypeFields, validatedTriggersList },
  });
};

export const TriggerSettingsProvider = ({ children }) => {
  const [triggerSettingsState, triggerSettingsDispatch] = useReducer(
    triggerSettingsReducer,
    initialState
  );

  const triggerSettingsData = useMemo(
    () => ({ triggerSettingsState, triggerSettingsDispatch }),
    [triggerSettingsState]
  );

  return (
    <TriggerSettingsContext.Provider value={triggerSettingsData}>
      {children}
    </TriggerSettingsContext.Provider>
  );
};
