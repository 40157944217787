import { styled } from '@mui/material';
import ErrorIconComponent from '../../assets/img/smallErrorIcon.svg';

export const ErrorIcon = styled(ErrorIconComponent)(() => ({
  marginRight: '0.56rem',
}));

export const ComponentContainer = styled('div')(() => ({
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
}));

export const Label = styled('label')(() => ({
  marginBottom: '0.5rem',
  fontSize: '0.875rem',
  textTransform: 'capitalize',
}));

export const RequiredStar = styled('span')(({ theme }) => {
  const { text } = theme?.palette || {};

  return {
    color: text.errorText,
    marginLeft: '0.25rem',
  };
});

export const Input = styled('input', {
  shouldForwardProp: (prop) => prop !== 'isErrorState',
})(({ theme, isErrorState, width, height, padding }) => {
  const { background, border, text } = theme?.palette || {};
  const boxShadow = '0 2px 6px rgba(0,0,0,0.16)';
  const transitionAnimation = 'all 0.225s ease-out';
  const filter = 'brightness(125%)';

  return {
    width: width || '12.625rem',
    height: height || '2rem',
    border: `1px solid ${
      isErrorState ? border.lavaRed : border.lightWhiteGrey
    }`,
    fontSize: '0.875rem',
    fontFamily: 'Open Sans',
    padding: padding ? padding : '0.6875rem 1rem',
    borderRadius: '2px',
    outlineWidth: 0,
    color: text.primary,
    backgroundColor: background.blackerRangoonGreen,
    transition: transitionAnimation,
    MozTransition: transitionAnimation,
    WebkitTransition: transitionAnimation,

    '&:hover, &:focus, &:active': {
      boxShadow: boxShadow,
      transition: transitionAnimation,
      filter: filter,
    },

    '&:hover': {
      border: `1px solid ${isErrorState ? border.lavaRed : border.saveGreen}`,
    },

    '&::placeholder': {
      color: text.nobel,
    },
  };
});

export const ErrorTextContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'errorTextWidth',
})(({ errorTextWidth }) => ({
  fontSize: '1rem',
  marginTop: '0.4375rem',
  display: 'flex',
  alignItems: 'center',
  maxWidth: errorTextWidth || 'unset',
}));
